import { metricNotes, performanceMatrixInterpretationText } from "../assets/organisationDashboard";

export const getPerformanceMatrixHoverText = (
  language,
  finMetricKey,
  situation,
  trend
) => {
  const languageFilteredObj = metricNotes[language];

  const keyFilteredArray = languageFilteredObj[finMetricKey];

  const filteredArr = keyFilteredArray?.filter((row) => {
    return row.trend === trend && row.situation === situation;
  });

  if (filteredArr && filteredArr[0]?.["interpretation"]) {
    const textArray = [
      {
        heading: `${situation} situation and ${trend} trend of ${finMetricKey} Indicate/Implies/Suggests`,
        text: filteredArr[0]["interpretation"],
        ...filteredArr?.[0]
      }
    ];
    return textArray;
  }

  return [
    {
      heading: finMetricKey,
      text: "",
      ...filteredArr?.[0]
    },
  ];
};

export const getResourceMatRecomText = (
  language,
  finMetricKey,
  situation,
  trend
) => {
  const languageFilteredObj = performanceMatrixInterpretationText[language];
  const keyFilteredArray = languageFilteredObj[finMetricKey];

  const filteredArr = keyFilteredArray.filter((row) => {
    return row.trend === trend && row.situation === situation;
  });

  return {
    heading: finMetricKey,
    data: filteredArr[0]["Indicate/Implies/Suggests"],
  };
};
