import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Box, Flex } from "@chakra-ui/react";

import Navbar from "../../components/Header/Navbar";
import { getRegisteredClientsData } from "../../actions/user/agencyActions";
import Footer from "../../components/Footer";
import ClientSidebar from "../../components/ClientSidebar/ClientLeftSidebar";
import Cookies from "../../components/Cookies/Cookies";

const ClientLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const isAgencyHomePage = location.pathname === "/agency";

  const { user } = useSelector((state) => state.userReducer);

  return (
    <Box>
      <Navbar />
      <Flex height={"calc(100vh - 118px)"}>
        <Box
          bg="#E6E6E6"
          mt="50px"
          display={{ base: "none", lg: "block" }}
          width={"20%"}
        >
          <ClientSidebar />
        </Box>
        <Box width={"80%"}>
          <Outlet />
        </Box>
      </Flex>
      <Footer />
      <Cookies />
    </Box>
  );
};

export default ClientLayout;
