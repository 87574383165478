import React from "react";
import Chart from "react-apexcharts";

const StackedAreaChart = ({ data, dimension }) => {
  const series = [];

  const keys = Object.keys(data?.[0]?.value || {});
  const years = data.map((item) => item?.year);

  keys?.forEach?.((key) => {
    const newData = data.map((item) => item?.value?.[key]);
    series.push({
      name: key,
      data: newData,
    });
  });

  const options = {
    chart: {
      type: "area",
      stacked: true,
      toolbar: {
        show: false,
      },
      background: "#fff",
    },
    xaxis: {
      categories: years,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return (value / 10000000).toFixed(1) + " Cr";
        },
      },
    },
    fill: {
      opacity: 0.7,
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <Chart
      options={options}
      series={series}
      type="area"
      height={dimension?.height || 380}
      width={dimension?.width || "100%"}
    />
  );
};

export default StackedAreaChart;
