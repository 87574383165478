import { mode } from "@chakra-ui/theme-tools";

export const bgColorDark = "#1a1a1a";
export const bgColorLight = "white";

/* const colors = {
  primary: {
    main: "#44337A",
    50: "#FAF5FF",
    100: "#E9D8FD",
    200: "#D6BCFA",
    300: "#B794F4",
    400: "#9F7AEA",
    500: "#805AD5",
    600: "#6B46C1",
    700: "#553C9A",
    800: "#44337A",
    900: "#322659",
  },
}; */

const colors = {
  primary: {
    main: "red",
    900: "#efefef",
    800: "#dedede",
    700: "#cdcdcd",
    600: "#bcbcbc",
    500: "#8F8F8F",
    400: "#0a0a0a",
    300: "#909090",
    200: "#898989",
    100: "#787878",
    50: "#676767",
    

  },
};

const customStyle = {

  fonts: {
    body: "Poppins, sans-serif",
    heading: "Poppins, sans-serif",
  },
  
  initialColorMode: "light",
  useSystemColorMode: false,

  styles: {
    global: (props) => ({
      body: {
        // sets a custom bg color for dark mode only
        bg: mode(
          // light mode value retrieved from theme
          props.theme.semanticTokens.colors["chakra-body-bg"]._light,
          // your custom value for dark mode
          bgColorDark
        )(props),
      },
    }),
  },

  colors,

  components: {
    Button: {
      defaultProps: {
        colorScheme: "primary",
      },
    },
  },
};

export default customStyle;
