import { Box, Text, Flex } from "@chakra-ui/react";
import {
  TYPES_OF_COMPETATIVE_METRICS,
  TYPES_OF_METRICS,
} from "../../constants/metrics";
import downarrowmenu from "../../assets/images/arrow-down.png";
import uparrowmenu from "../../assets/images/arrow-up.png";
import sales from "../../assets/images/sales.png";
import distribution from "../../assets/images/distribution.png";
import hr from "../../assets/images/hr.png";
import production from "../../assets/images/production.png";
import operation from "../../assets/images/operation.png";
import financing from "../../assets/images/financing.png";
import { useState } from "react";

export const CompetativeTabs = ({
  setCurrentCompetativeMetric,
  currentCompetativeMetric,
  metricColors,
  setMetricColors,
  navigation,
}) => {
  const imageObject = {
    Sales: sales,
    Distribution: distribution,
    HR: hr,
    Production: production,
    Operations: operation,
    Finance: financing,
  };

  const riskSideMenu = [
    "growthAndReturns",
    "efficiencyAndLeverage",
    "marginAndCost",
  ];
  const [currentMetric, setCurrentMetric] = useState(
    TYPES_OF_METRICS[riskSideMenu[0]]?.value?.[0]?.label
  );
  const METRIC_BG_COLORS = ["#E4F0DD", "#FFF1E1", "#FDE7E7"];
  const METRIC_COLORS = ["#2D6C08", "#BE4400", "#B31B1B"];
  const applyColorsToMetrics = (dataList) => {
    const coloredList = dataList.map((data) => {
      return data.value.map((data1) => {
        return data1.value.map((_, index) => {
          const colorIndex = index % METRIC_BG_COLORS.length;
          return {
            bgColor: METRIC_BG_COLORS[colorIndex],
            textColor: METRIC_COLORS[colorIndex],
          };
        });
      });
    });
    return coloredList;
  };

  const coloredDataList = applyColorsToMetrics(
    TYPES_OF_COMPETATIVE_METRICS.filter(
      (data) => data.label === currentCompetativeMetric
    )
  );
  
  return (
    <Box id={"segment_risks_type"} className="leftNavOptions">
      {TYPES_OF_COMPETATIVE_METRICS?.map((data) => (
        <Box
          m={"0px"}
          w={"100%"}
          style={{
            border: "1.3px solid var(--sylvr-text-color-selected)",
            backgroundColor: "#F2F7F7",
            borderRadius: "4px",
            padding: "8px",
            marginBottom: "15px",
          }}
        >
          <Box>
            <Flex
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                paddingBottom: "5px",
              }}
              onClick={() => {
                setCurrentCompetativeMetric(data?.label);
              }}
            >
              <Box
                style={{
                  fontFamily: "Inter",
                  fontSize: "14px",
                  fontWeight: 600,
                  textAlign: "left",
                }}
              >
                {data?.label}
              </Box>
              <Box className="menu-icon">
                <img
                  src={
                    currentCompetativeMetric === data?.label
                      ? uparrowmenu
                      : downarrowmenu
                  }
                  alt="menu-arrow"
                  className="metric-arrow"
                />
              </Box>
            </Flex>
          </Box>
          {currentCompetativeMetric === data?.label &&
            data?.value?.map((value, indexV1) => (
              <Box
                m={"0px"}
                w={"100%"}
                style={{
                  backgroundColor: "var(--sylvr-text-color-selected)",
                  borderRadius: "4px",
                  marginBottom: "10px",
                }}
                key={`${value?.label}-${indexV1}`}
              >
                <Flex justifyContent={"flex-start"} alignItems={"center"}>
                  <Box style={{ marginLeft: "9px" }}>
                    <img src={imageObject[value?.label]} />
                  </Box>
                  <Box className="sylvr-subtitle-menu">{value?.label}</Box>
                </Flex>
                {value?.value?.map((row, index) => (
                  <>
                    <Box
                      className={
                        index === value?.value?.length - 1
                          ? "sylvr-subtitle-menu-card metric-bottomBorderRadius"
                          : "sylvr-subtitle-menu-card"
                      }
                    >
                      <Box
                        className="sylvr-subtitle-label"
                        backgroundColor={
                          currentMetric === row?.value
                            ? metricColors?.[row?.value]?.textColor ||
                              coloredDataList?.[indexV1]?.[index]?.textColor
                            : metricColors?.[row?.value]?.bgColor ||
                              coloredDataList?.[indexV1]?.[index]?.bgColor
                        }
                        color={
                          currentMetric === row?.value
                            ? "#000"
                            : metricColors?.[row?.value]?.textColor ||
                              coloredDataList?.[indexV1]?.[index]?.textColor
                        }
                        cursor="pointer"
                        onClick={() => {
                          setCurrentMetric(row?.value);
                        }}
                      >
                        {row?.label}
                      </Box>
                    </Box>
                    {index !== value?.value?.length - 1 && (
                      <Box
                        style={{
                          backgroundColor: "white",
                        }}
                      >
                        <Box
                          style={{
                            border: "1.5px solid #E6E6E6",
                            margin: "0px 5px",
                          }}
                        ></Box>
                      </Box>
                    )}
                  </>
                ))}
              </Box>
            ))}
        </Box>
      ))}
      {/* {false &&
        riskSideMenu?.map?.((key, index) => (
          <Box m={"0px"} w={"100%"}>
            <div
              onClick={() => {
                setCurrentType(key);
                setCurrentMetric(TYPES_OF_METRICS?.[key]?.value[0]?.label);
              }}
              className={
                currentType === key
                  ? "riskSubTabs menu-label-arrow menu-label-arrow-selected"
                  : "riskSubTabs menu-label-arrow"
              }
            >
              <div>{TYPES_OF_METRICS?.[key]?.label}</div>
              <div className="menu-icon">
                <img
                  src={currentType === key ? uparrowmenu : downarrowmenu}
                  alt="menu-arrow"
                  className="menu-arrow"
                />
              </div>
            </div>
            {currentType === key && (
              <Box pt={5} borderTop="3px solid #E6E6E6">
                {TYPES_OF_METRICS &&
                  TYPES_OF_METRICS?.[key]?.value?.map?.((val, index) => {
                    return (
                      <Text
                        width="auto"
                        backgroundColor={
                          currentMetric === val?.label
                            ? metricColors?.[val?.label]?.textColor ||
                              coloredDataList?.[index]?.textColor
                            : metricColors?.[val?.label]?.bgColor ||
                              coloredDataList?.[index]?.bgColor
                        }
                        color={
                          currentMetric === val?.label
                            ? "#fff"
                            : metricColors?.[val?.label]?.textColor ||
                              coloredDataList?.[index]?.textColor
                        }
                        fontFamily={"Inter"}
                        fontWeight="400"
                        fontSize="14px"
                        p={1}
                        textTransform="uppercase"
                        borderRadius={4}
                        boxShadow="0px 0px 25px 0px rgba(0, 0, 0, 0.04), 0px 0px 6px 0px rgba(0, 0, 0, 0.06)"
                        cursor="pointer"
                        onClick={() => {
                          setCurrentMetric(val.label);
                        }}
                      >
                        {val?.value} {currentType === "marginAndCost" && "%"}
                      </Text>
                    );
                  })}
              </Box>
            )}
          </Box>
        ))} */}
    </Box>
  );
};
