import { createContext, useEffect, useState } from "react";

const TourContext = createContext({});

export const TourProvider = ({ children }) => {
  const [is_tour, setIsTour] = useState(true);

  useEffect(()=>{
    let istour = localStorage.getItem("is_tour");
    console.log("====TourProvider=", istour);
    if (is_tour === undefined || istour === undefined || istour === null) {
      setIsTour(true);
      console.log("====TourProvider=true", istour);
    } else if (istour === "false") {
      setIsTour(false);
    }
  })
  return (
    <TourContext.Provider value={{ is_tour, setIsTour }}>
      {children}
    </TourContext.Provider>
  );
};

export default TourContext;
