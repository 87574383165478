import { EmailIcon, UnlockIcon, ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginOrganisation } from "../../../actions/user/organisationActions";

const OrgLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pswdShow, setPswdShow] = useState(false);

  const [data, setData] = useState({
    email: "",
    pswd: "",
  });

  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };
  const submitHandler = (e) => {
    dispatch(loginOrganisation({ ...data }, navigate));
  };

  return (
    <>
      <Box className="body-container" overflowY={"auto"}>
        <Stack
          className="auth-page"
          justifyContent={"space-evenly"}
          alignItems={"center"}
          minH={"720px"}
          h={"full"}
        >
          <Stack
            w={"fit-content"}
            p={[3, 6, 8]}
            spacing={6}
            borderRadius={"9px"}
            alignItems={"center"}
          >
            <Heading textAlign={"center"} margin={"0 0 30px 0"}>
              Organisation Login
            </Heading>

            <FormControl width={["xs", "sm", "md"]} margin={"auto"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<EmailIcon />}
                />
                <Input
                  name="email"
                  onChange={changeHandler}
                  value={data.email}
                  placeholder="Email"
                  focusBorderColor={"primary.400"}
                  color={"primary.600"}
                />
              </InputGroup>
            </FormControl>

            <FormControl width={["xs", "sm", "md"]} margin={"auto"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<UnlockIcon />}
                />
                <Input
                  type={pswdShow ? "text" : "password"}
                  name="pswd"
                  onChange={changeHandler}
                  value={data.pswd}
                  placeholder="Password"
                  focusBorderColor={"primary.400"}
                  color={"primary.600"}
                />
                <InputRightElement>
                  <Button
                    variant={"ghost"}
                    onClick={() => setPswdShow(!pswdShow)}
                  >
                    {pswdShow ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Stack spacing={0}>
              <Flex justifyContent={"flex-end"}>
                <Link to={"/organisation/password/forgot"}>
                  <Button variant={"link"}>Forgot Password?</Button>
                </Link>
              </Flex>

              <Flex justifyContent={"space-evenly"} p={"18px 0 40px 0"}>
                <Button variant={"solid"} onClick={submitHandler}>
                  Submit
                </Button>
              </Flex>

              <Flex justifyContent={"flex-end"} marginBottom={"10px"}>
                <Text>
                  New Organisation?{" "}
                  <Link to={"/organisation/register"}>
                    <Button variant={"link"}>Register</Button>
                  </Link>
                </Text>
              </Flex>

              <Text textAlign={"end"}>---- OR ----</Text>

              <Flex justifyContent={"flex-end"}>
                <Link to={"/login"}>
                  <Button variant={"ghost"}>Login as Agency</Button>
                </Link>
              </Flex>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default OrgLogin;
