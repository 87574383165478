import { Box, Text } from "@chakra-ui/react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";

export default function RelativeMarketShareStackedBarChart({ years }) {
  const { organisationData } = useSelector(
    (state) => state.organisationReducer
  );
  const { client } = useSelector((state) => state.clientReducer);
  const { details, competitiveMap } = client;

  const { clusterWiseData = [], totalRevenueFromOperationOfPeers } =
    competitiveMap?.data || {};

  function generateColors(currentUserId, finalData) {
    const colors = [];
    for (let i = 0; i < finalData.length; i++) {
      if (finalData[i].id === currentUserId) {
        colors.push("#30d5c8");
      } else {
        colors.push(`rgba(169, 169, 169, ${0.5 + i * 0.1})`);
      }
    }
    return colors;
  }

  const chartData2 = Object.values(
    clusterWiseData
      ?.filter((company) => company.financialMetrics.length > 0)
      ?.reduce?.((accum, company) => {
        const obj = {};

        obj["id"] = company.id;
        obj["companyName"] = company.companyName;

        const relativeShare = company.financialMetrics.map((item) => {
          const xVal =
            item.revenueFromOperations /
            totalRevenueFromOperationOfPeers[item.year];

          return xVal;
        });

        obj["relativeShare"] = relativeShare;

        accum[company.id] = accum[company.id] || obj;

        return accum;
      }, {})
  );

  const chartOptions = {
    chart: {
      type: "bar",
      stacked: true,
      stackType: "100%",
      height: 350,
      toolbar: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          if (value) {
            return `${Math.abs(value)}%`;
          } else {
            return value;
          }
        },
      },
    },
    xaxis: {
      categories: years,
    },
    fill: {
      opacity: 1,
    },
    colors: generateColors(details.id, chartData2),
  };

  const chartSeries = chartData2.map((data) => {
    return {
      data: data.relativeShare.map((share) => share),
    };
  });

  return (
    <>
      <Text textTransform="uppercase" fontWeight={600} fontSize={18}>
        Relative Market Share
      </Text>
      <Box py={4}>
        <Chart
          options={chartOptions}
          series={chartSeries}
          type="bar"
          height={350}
        />
      </Box>
    </>
    
  );
}
