import {
  Box,
  Flex,
  Grid,
  GridItem,
  Heading,
  Select,
  Stack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getsectorWiseResult } from "../../../actions/home/homeActions";
import Loader from "../../../components/Loader/Loader";
import EmployeeBenefit from "./EmployeeBenefit";
import ShareholdersEquity from "./ShareholdersEquity";
import LongTermBorrowings from "./LongTermBorrowings";
import TotalDebt from "./TotalDebt";
import TotalInterest from "./TotalInterest";
import FixedAssets from "./FixedAssets";

const sectorWiseMetricKeys = [
  "employeeBenefitExpense",
  "ltBorrowings",
  "totalDebt",
  "totalEquity",
  "totalFixedAsset",
  "totalInterest",
  "totalTax",
  "totalRevenueFromOperations",
];

const SectorWiseSection = () => {
  const dispatch = useDispatch();

  const { loading, data } = useSelector(
    (state) => state.homeReducer.sectorWiseSection
  );

  const [sectorOptions, setSectorOptions] = useState([]);
  const [selectedSector, setSelectedSector] = useState("");
  const [sectorWiseData, setSectorWiseData] = useState([]);

  const [graphData, setGraphData] = useState({
    employeeBenefitExpense: [],
    ltBorrowings: [],
    totalDebt: [],
    totalEquity: [],
    totalFixedAsset: [],
    totalInterest: [],
    totalTax: [],
    totalRevenueFromOperations: [],
  });

  useEffect(() => {
    dispatch(getsectorWiseResult());
    // eslint-disable-next-line
  }, []);

  const sectorSelectHandler = (e) => {
    setSelectedSector(e.target.value);
  };

  useEffect(() => {
    if (data?.length > 0) {
      setSectorOptions(
        data?.map((swr) => {
          return {
            name: swr.sector,
            value: swr.sector,
          };
        })
      );
    }
  }, [data]);

  useEffect(() => {
    sectorOptions && setSelectedSector(sectorOptions[0]?.value);
  }, [sectorOptions]);

  useEffect(() => {
    if (selectedSector) {
      setSectorWiseData(
        data?.filter((swr) => swr.sector === selectedSector)[0]
      );
    }
  }, [selectedSector, data]);

  useEffect(() => {
    const yearsWiseData = sectorWiseData?.yearWiseData;
    const years = yearsWiseData && Object.keys(yearsWiseData);

    if (yearsWiseData && years.length > 0) {
      sectorWiseMetricKeys.forEach((key) => {
        let obj;
        if (key === "totalDebt") {
          obj = years.map((year) => {
            const { totalDebt, ltBorrowings } = yearsWiseData[year];
            return {
              year,
              totalDebt,
              ltBorrowings,
              stBorrowing: totalDebt - ltBorrowings,
            };
          });
        } else if (key === "totalRevenueFromOperations") {
          obj = years.map((year) => {
            const { totalRevenueFromOperations } = yearsWiseData[year];
            return {
              year,
              totalRevenueFromOperations,
            };
          });
        } else {
          obj = years.map((year) => {
            return {
              year,
              value: yearsWiseData[year][key],
            };
          });
        }

        setGraphData((currState) => {
          return {
            ...currState,
            [key]: obj,
          };
        });
      });
    }
  }, [sectorWiseData]);

  return (
    <>
      {/* {console.log("regionWiseData: ", regionWiseData)}
        {console.log("graphData: ", graphData)} */}

      <Stack p={["10px", "30px"]} id="sectorwise_section">
        <Heading>Sector Wise Status</Heading>

        {loading ? (
          <>
            <Box h={"200px"}>
              <Loader />
            </Box>
          </>
        ) : (
          <>
            {data?.length > 0 ? (
              <>
                <Flex justifyContent={"flex-end"}>
                  <Select
                    w={"260px"}
                    variant={"filled"}
                    name="state"
                    onChange={sectorSelectHandler}
                    focusBorderColor={"primary.400"}
                    color={"primary.600"}
                    // placeholder="Select state--"
                  >
                    {sectorOptions &&
                      sectorOptions.map((sector, index) => (
                        <option value={sector.value} key={index}>
                          {sector.name}
                        </option>
                      ))}
                  </Select>
                </Flex>

                {sectorWiseData?.yearWiseData &&
                  Object.keys(sectorWiseData?.yearWiseData).length > 0 && (
                    <>
                      <Grid
                        gridTemplateColumns={
                          "repeat(auto-fit, minmax(580px, 1fr))"
                        }
                        p={"36px 0"}
                        overflowX={"auto"}
                        gap={["6", "8", "10", "12"]}
                        justifyItems={"center"}
                      >
                        <GridItem maxW={"580px"}>
                          <EmployeeBenefit
                            data={graphData.employeeBenefitExpense}
                            chartDesc="Total Employee Benefit Expenses of Sector"
                          />
                        </GridItem>

                        <GridItem maxW={"580px"}>
                          <ShareholdersEquity
                            data={graphData.totalEquity}
                            chartDesc="Total Equity Share Capital + Other Equity of Sector"
                          />
                        </GridItem>

                        <GridItem maxW={"580px"}>
                          <LongTermBorrowings
                            data={graphData.ltBorrowings}
                            chartDesc="Total Non-Current Liabilities: Borrowings of Sector"
                          />
                        </GridItem>

                        <GridItem maxW={"580px"}>
                          <TotalDebt
                            data={graphData.totalDebt}
                            chartDesc="Long Term Borrowings + Short Term Borrowings of Sector"
                          />
                        </GridItem>

                        <GridItem maxW={"580px"}>
                          <TotalInterest
                            data={graphData.totalInterest}
                            chartDesc="Total Expenses: Finance Costs of Sector"
                          />
                        </GridItem>

                        <GridItem maxW={"580px"} >
                          <FixedAssets
                            data={graphData.totalFixedAsset}
                            chartDesc="Total Property, Plant and Equipment + Right of use asets + Capital Work in Progress + Investment Properties of Sector"
                          />
                        </GridItem>
                      </Grid>
                    </>
                  )}
              </>
            ) : (
              <>
                <Flex h={"200px"} alignItems={"center"}>
                  <Heading
                    as={"h4"}
                    size={"md"}
                    w={"full"}
                    textAlign={"center"}
                    h={"fit-content"}
                    color={"primary.500"}
                  >
                    None of the company has uploaded their audit sheets. Please
                    visit this page later.
                  </Heading>
                </Flex>
              </>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default SectorWiseSection;
