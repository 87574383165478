import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import Register from "../Register";

const RegistrationPopUp = (props) => {
  return (
    <>
      <Modal
        onClose={() => props.setModelRegistrationPopUp(false)}
        isOpen={props.modelRegistrationPopUp}
        scrollBehavior={"inside"}
        isCentered={true}
        size={"3xl"}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt={5}>
            <Register isModelPopUp={true}/>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RegistrationPopUp;
