import { sendRequest } from "../libs/axiosConfig";

export const addIndustryLeaderInterviewApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/industry-interview`,
    method: "POST",
    body: reqBody,
  });
  return data;
};

export const getIndustryWiseInterviews = async (industryName) => {
  const { data } = await sendRequest({
    url: `/api/v1/industry-interview/${industryName}`,
    method: "GET",
  });
  return data;
};
