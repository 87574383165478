import { toast } from "react-toastify";
import { sendRequest } from "../libs/axiosConfig";
import { errMsg, setToken } from "../utils/helperFunction";

export const initiateClientDashboardPurchase = async (reqBody) => {
  try {
    const {
      subscriptionName,
      paymentFrequency,
      amount,
      clientId,
      paymentMethod,
    } = reqBody;

    const response = await sendRequest({
      url: `/api/v1/client/buyClientDashboard?paymentMethod=${paymentMethod}`,
      method: "POST",
      body: {
        subscriptionName,
        paymentFrequency,
        amount,
        clientId,
      },
    });

    return response.data;
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};

export const verifyClientDashboardPurchasePayment = async (reqBody) => {
  try {
    const {
      razorpay_payment_id,
      razorpay_order_id,
      razorpay_signature,
      paymentMethod,
    } = reqBody;
  
    const response = await sendRequest({
      url: `/api/v1/client/verifyClientDashboardPayment?paymentMethod=${paymentMethod}`,
      method: "POST",
      body: {
        razorpay_payment_id,
        razorpay_order_id,
        razorpay_signature,
      },
    });
  
    return response.data;
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};

/**
 * @author Neha Nihar
 * @param {object} reqBody - The request body containing user information.
 * reqBody = {
 * -email,
 * -password 
 * -companyName,
 * -regdOffice,
 * -homeState,
 * -homeTown,
 * -website,
 * -role,
 * -logo,
 * -products,
  }
 * @returns {object} data
 * -client_id,
 * -name,
 * -email
 */
export const registerOrganisationApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: "/api/v1/client/register",
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

/**
 * @author Neha Nihar
 * @param {object} reqBody - The request body containing user information.
 * @param {string} reqBody.email - The user's email.
 * @param {string} reqBody.password - The user's password.
 * @param {string} reqBody.companyName - The user's company name.
 * @returns {object} data:  An object representing the user with the following properties:
 *   - id: A unique identifier for the user.
 *   - email: The user's email address.
 *   - role: The user's role, which is fixed as 'client'.
 *   - companyName: The user's company name.
 */
export const loginOrganisationApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: "/api/v1/client/login",
    method: "POST",
    body: { ...reqBody },
  });
  setToken(data?.token);
  return data;
};

export const logoutOrganisationApi = async () => {
  const res = await sendRequest({ url: `/api/v1/client/logout` });
  setToken('');
  return res;
}

export const getSectorsApi = async () => {
  const { data } = await sendRequest({
    url: `/api/v1/default/getsectors`,
  });
  return data;
};

export const getSectorByIdApi = async (industryId) => {
  console.log(industryId)
  const { data } = await sendRequest({
    url: `/api/v1/default/getsectorbyid`,
    params: {industryId}
  });
  return data;
};

const initialQueryObj = {
  query: "",
  currentPage: 1,
  pageSize: 20,
};

export const getProductsApi = async (queryObj = initialQueryObj) => {
  const { query = "", currentPage = 1, pageSize = 10, sectorCategory = "" } = queryObj;
  const { data } = await sendRequest({
    url: `/api/v1/default/getproducts?q=${query}&sectorCategory=${sectorCategory}&page=${currentPage}&pageSize=${pageSize}`,
  });
  return { data: data.products, count: data.count };
};

export const getClientProductsApi = async (productIds = []) => {
  const { data } = await sendRequest({
    method: 'POST',
    url: `/api/v1/default/getclientproducts`,
    body: { productIds }
  });
  return { data: data.products };
};

export const getLocationApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: "/api/v1/default/getlocation",
    method: "POST",
    body: { ...reqBody },
  });
  return data;
}

// reqBody is {clientId: string}

export const getPreviewDataApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: "/api/v1/client/getpreviewdata",
    method: "POST",
    body: { ...reqBody },
  });
  return data;
}

export const getClusterwiseApi = async(reqBody) => {
  const { data } = await sendRequest({
    url:"/api/v1/kpi/clusterwise",
    method: "POST",
    body: {...reqBody},
  });
  return data;
}

export const getSectorsCategoriesApi = async () => {
  const { data } = await sendRequest({
    url:"/api/v1/default/getsectorscategories",
    method: "GET"
  });
  return data;
};

export const getClusterwiseForIndustryApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: '/api/v1/kpi/industryclusterwise',
    method: 'POST',
    body: { ...reqBody },
  });
  return data;
};

export const getPreviewDataForIndustryApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: '/api/v1/industry/getindustrypreviewdata',
    method: 'POST',
    body: { ...reqBody },
  });
  return data;
};


export const getClientDetailsAPI = async (reqBody) => {
  const { clientId } = reqBody;

  const { data } = await sendRequest({
    url: `/api/v1/client/${clientId}`,
    method: "POST"
  });
  return data;
};
