import * as Yup from "yup";
import { Field, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Box,
  FormErrorMessage,
  Heading,
  Text,
} from "@chakra-ui/react";

import Loader from "../../components/Loader/Loader";
import {
  getProductsList,
  getSectorList,
} from "../../actions/user/organisationActions";
import { registerNewClient } from "../../actions/user/agencyActions";
import TextInput from "../../components/formikElements/TextInput";
import { getLocationApi, getProductsApi, getSectorsCategoriesApi } from "../../api/organisationApi";
import ProductsDropdown from "../../components/ProductsDropdown";
import SectorCategoriesDropdown from "../../components/SectorCategoriesDropdown";

const CreateClient2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({
    companyName: "",
    email: "",
    regdOffice: "",
    website: "",
    logo: "",
    pincode: null,
    products: [],
  });
  const [showState, setShowState] = useState(false);
  const [location, setLocation] = useState({});
  const [customError, setCustomError] = useState({});
  const [categoriesLoading, setCategoriesLoading] = useState(false);
  const [sectorCategories, setSectorCategories] = useState([]);
  const [selectedSectorCategory, setSelectedSectorCategory] = useState(null);

  useEffect(() => {
    dispatch(getSectorList());
    // dispatch(getProductsList());
  }, [dispatch]);

  useEffect(() => {
    fetchSectorCategories();
  }, [])

  const { loading } = useSelector((state) => state.agencyReducer);

  useEffect(() => {
    if (Object.keys(location).length > 0) {
      setShowState(true);
    } else {
      setShowState(false);
    }
  }, [location]);

  const validationSchema = Yup.object({
    companyName: Yup.string().required(),
    email: Yup.string().email(),
    pincode: Yup.number().required(),

    regdOffice: Yup.string(),
    website: Yup.string().url(),
    logo: Yup.string(),
    products: Yup.array()
      .of(Yup.string())
      .min(3, "At least 3 products are required")
      .max(20, "Maximum of 20 products allowed"),
  });

  const validateProducts = (value) => {
    let error;
    if (value.length < 3) {
      error = "At least 3 products are required";
    }
    if (value.length > 20) {
      error = "Maximum of 20 products allowed";
    }
    return error;
  };

  const validateAndSetLocation = async (e) => {
    const isValidPinCode = /^[0-9]{6}$/.test(e.target.value) && e.target.value;
    try {
      if (isValidPinCode) {
        setCustomError({});
        const data = await getLocationApi({ pincode: e.target.value });
        setLocation(data);
      } else {
        setLocation({});
        setCustomError({ pincode: "pincode is not valid" });
      }
    } catch (err) {
      console.error(err);
      setCustomError({ pincode: "pincode could not be found" });
    }
  };

  const initialValues = {
    companyName: "",
    email: "",
    regdOffice: "",
    website: "",
    logo: "",
    pincode: null,
    products: [],
  };

  const handleProductChange = (event, setFieldValue) => {
    // option Array<{value:id,label:text}>
    const selectedValues = Array.isArray(event)
      ? event.map((item) => item.value)
      : [];
    setFieldValue("products", selectedValues);
  };

  const fetchSectorCategories = useCallback(
    async () => {
      setCategoriesLoading(true);
      try {
        const data = await getSectorsCategoriesApi();
        setSectorCategories(data);
        setSelectedSectorCategory(data?.[0]?.category || null);
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      } finally {
        setCategoriesLoading(false);
      }
    },
    []
  );

  const handleSectorCategoryChange = (selectedSectorCategory) => {
    setSelectedSectorCategory(selectedSectorCategory?.value);
  };

  return (
    <Flex className="body-container auth-page" justifyContent={"space-evenly"}>
      {loading ? (
        <Loader />
      ) : (
        <Box flex={1}>
          <Heading my={10} textAlign="center">Organisation Registration</Heading>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              dispatch(registerNewClient({ ...values }, navigate));
            }}
            validateOnBlur={true}
          >
            {(formik) => (
              <Stack
              height={"calc(100% - 140px)"}
                p={{ base: "0", md: "6" }}
                width={"full"}
                maxW={"2160px"}
                overflowY={"auto"}
              >
                {/* <Heading textAlign={"center"} margin={"12px 0 48px 0"}>
                  Company Registration
                </Heading> */}
                <Box>
                  <Box width={{ base: "100%", md: "48%" }} mt="5" className="custom-input">
                    <FormControl maxWidth={"md"} margin={"auto"}>
                      <FormLabel>Categories</FormLabel>
                      {categoriesLoading ? <p>Loading categories...</p> : <SectorCategoriesDropdown sectorCategories={sectorCategories} selectedSectorCategory={selectedSectorCategory} handleSectorCategoryChange={handleSectorCategoryChange} />}
                    </FormControl>
                  </Box>

                  {selectedSectorCategory && (
                    <Box px={3} pt={2} pb={4}>
                      <Text>{sectorCategories?.find?.((category) => category?.category === selectedSectorCategory)?.overview}</Text>
                    </Box>
                  )}
                </Box>

                <Box className="custom-input">
                  <TextInput
                    name="companyName"
                    placeholder="Enter the name of the company"
                    label="Organization"
                    isRequired={true}
                    focusBorderColor="primary.400"
                  ></TextInput>
                </Box>
                <Flex
                  justifyContent={"space-between"}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="pincode"
                      placeholder="Enter the pincode of company location"
                      label="Pincode"
                      isRequired={true}
                      error={customError?.pincode || null}
                      onBlurCallback={(e) => {
                        // Call your function to validate the Pin Code and set showState
                        validateAndSetLocation(e);
                      }}
                      focusBorderColor="primary.400"
                    ></TextInput>
                  </Box>
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="email"
                      placeholder="Enter the email of your organisation"
                      label="Email"
                      focusBorderColor="primary.400"
                    ></TextInput>
                  </Box>
                </Flex>
                {showState && (
                  <Flex
                    justifyContent={"space-between"}
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    {showState && (
                      <Box
                        width={{ base: "100%", md: "48%" }}
                        mt="5"
                        className="custom-input"
                      >
                        <FormControl maxWidth={"md"} margin={"auto"}>
                          <FormLabel>District</FormLabel>
                          <Input
                            value={location.district}
                            readOnly
                            focusBorderColor="primary.400"
                            // color="primary.600"
                          ></Input>
                        </FormControl>
                      </Box>
                    )}

                    {showState && (
                      <Box
                        width={{ base: "100%", md: "48%" }}
                        mt="5"
                        className="custom-input"
                      >
                        <FormControl maxWidth={"md"} margin={"auto"}>
                          <FormLabel>State</FormLabel>
                          <Input
                            value={location.state}
                            readOnly
                            focusBorderColor="primary.400"
                            // color="primary.600"
                          ></Input>
                        </FormControl>
                      </Box>
                    )}
                  </Flex>
                )}

                <Flex
                  justifyContent={"space-between"}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <Field name="products" validate={validateProducts}>
                      {({ field, form }) => (
                        <FormControl
                          maxWidth={"md"}
                          margin={"auto"}
                          isInvalid={
                            form.errors.products && form.touched.products
                          }
                        >
                          <FormLabel>
                            Products <span style={{ color: "#E53E3E" }}>*</span>
                          </FormLabel>

                          <ProductsDropdown
                            selectedSectorCategory={selectedSectorCategory}
                            fetchDataFunction={getProductsApi}
                            placeholder="Search Products..."
                            isMulti
                            handleOptionChange={(selectedOptions) => {
                              handleProductChange(
                                selectedOptions,
                                form.setFieldValue
                              );
                            }}
                          />
                          {form.errors.products && form.touched.products && (
                            <FormErrorMessage name="products">
                              {form.errors.products}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Box>

                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="regdOffice"
                      placeholder="Enter the registered address of the company"
                      label="Address"
                      focusBorderColor="primary.400"
                    ></TextInput>
                  </Box>
                </Flex>

                <Flex
                  justifyContent={"space-between"}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="website"
                      placeholder="Enter the URL of the company's website"
                      label="URL"
                      focusBorderColor="primary.400"
                    ></TextInput>
                  </Box>

                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="logo"
                      placeholder="Upload file"
                      type="file"
                      label="Logo"
                      focusBorderColor="primary.400"
                    ></TextInput>
                  </Box>
                </Flex>

                <Flex justifyContent={"space-evenly"} p={"36px 0 0 0"}>
                  <Flex>
                    <Button
                      variant={"solid"}
                      m={"0 12px"}
                      onClick={formik.handleSubmit}
                    >
                      Submit
                    </Button>
                  </Flex>
                </Flex>
              </Stack>
            )}
          </Formik>
        </Box>
      )}
    </Flex>
  );
};

export default CreateClient2;
