import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  industryId: null,
  sectorName: null,
  totalClients: null,
  industryData: {
    zScore: {
      loading: false,
      error: null,
      data: null,
    },
    trend: {
      loading: false,
      error: null,
      data: null,
    },
    competitiveMap: {
      loading: false,
      error: null,
      data: null,
    },
    auditsheet: {
      loading: false,
      error: null,
      data: null,
    },
  },
};

const industryReducer = createReducer(initialState, {
  SET_CURRENT_INDUSTRY: (state, actions) => {
    console.log('====SET_CURRENT_INDUSTRY', actions);
    state.industryId = actions?.payload?.industryId;
    state.sectorName = actions?.payload?.sectorName;
    state.totalClients = actions?.payload?.totalClients;
  },
  REMOVE_CURRENT_INDUSTRY: (state) => {
    state.industryId = null;
    state.sectorName = null;
    state.totalClients = null;
  },
  GET_IND_ZSCORE_REQ: (state, actions) => {
    state.industryData.zScore = {
      ...initialState.industryData.zScore,
      loading: true,
    };
  },
  GET_IND_ZSCORE_SUCCESS: (state, actions) => {
    const loading = false;
    const error = null;
    const data = actions.payload;
    state.industryData.zScore = { loading, error, data };
  },
  GET_IND_ZSCORE_FAIL: (state, actions) => {
    state.industryData.zScore = {
      ...initialState.industryData.zScore,
      loading: false,
      error: actions.payload,
    };
  },
  GET_IND_TREND_REQ: (state, actions) => {
    state.industryData.trend = {
      ...initialState.industryData.trend,
      loading: true,
    };
  },
  GET_IND_TREND_SUCCESS: (state, actions) => {
    const loading = false;
    const error = null;
    const data = actions.payload;
    state.industryData.trend = { loading, error, data };
  },
  GET_ORG_TREND_FAIL: (state, actions) => {
    state.industryData.trend = {
      ...initialState.industryData.trend,
      loading: false,
      error: actions.payload,
    };
  },
});

export default industryReducer;
