import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react"; // Updated import path
import { useField } from "formik";
import React, { useState } from "react"; // Don't forget to import React

const TextInput = ({
  label,
  leftElement = null,
  isRequired = false,
  onBlurCallback = null,
  error = null,
  rightElement=null,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [inputValue, setInputValue] = useState(null);

  const handleBlur = (e) => {
    // keep intact the onBlur functionality of field
    const { onBlur } = field;
    onBlur(e);
    // Call your onBlurCallback if it's provided
    if (onBlurCallback && inputValue!== e.target.value) {
      setInputValue(e.target.value)
      onBlurCallback(e);
    }
  };

  return (
    <FormControl
      maxWidth={"md"}
      margin={"auto"}
      isInvalid={(meta.error && meta.touched)|| error}
    >
      <FormLabel htmlFor={props.name}>
        {label}
        <span style={{ color: "#E53E3E" }}>{isRequired && " *"}</span>
      </FormLabel>
      <InputGroup>
        {leftElement && (
          <InputLeftElement pointerEvents="none" children={leftElement} />
        )}
        <Input {...field} {...props} id={props.name} onBlur={handleBlur} />
        {rightElement && (
          <InputRightElement children={rightElement}/>
        )}
      </InputGroup>
      <FormErrorMessage>{meta.error || error}</FormErrorMessage>
    </FormControl>
  );
};

export default TextInput;
