import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import bulletIcon from "../../assets/icons/bullet.png";
import urlIcon from "../../assets/icons/url.png";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { getIndustryWiseInterviews } from "../../api/industryLeaderInterviewApi";
import { toast } from "react-toastify";

export default function IndustryLeaderInterview({
  industryName,
  industryType,
}) {
  const [currentInterview, setCurrentInterview] = useState(0);
  const [interviews, setInterviews] = useState([]);

  useEffect(() => {
    const main = async () => {
      try {
        const result = await getIndustryWiseInterviews(industryName);
        setInterviews(
          result.slice().sort((a, b) => {
            const dateA = new Date(a.dateOfPublishing);
            const dateB = new Date(b.dateOfPublishing);

            return dateA - dateB;
          })
        );
      } catch (error) {
        console.error(error);
        toast.error(
          error?.response?.data?.message ||
            error?.message ||
            "Failed to add interview",
          {
            position: "bottom-center",
          }
        );
      }
    };

    main();
  }, [industryName]);

  const currentInterviewData = interviews?.[currentInterview];

  const currentInterviewQnaData = currentInterviewData?.qna?.filter(
    (qna) => qna?.qnaLabel === industryType
  );

  const nextInterview = () => {
    if (currentInterview + 1 < interviews.length) {
      setCurrentInterview(currentInterview + 1);
    }
  };

  const prevInterview = () => {
    if (currentInterview - 1 >= 0) {
      setCurrentInterview(currentInterview - 1);
    }
  };

  return (
    <Box
      mb={10}
      // p={5}
      borderWidth={1}
      borderRadius={8}
      boxShadow="0 0 5px 0 rgba(0,0,0,0.2)" 
      h={'auto'}
    >
      <Text textAlign="center" color="#0C543C" fontWeight={600} fontSize={18} pt={2}>
        Voice of Industry Leaders
      </Text>

      {currentInterviewData && currentInterviewQnaData?.length > 0 ? (
        <Box
          m={3}
          px={4}
          py={3}
          borderWidth={2}
          borderColor="#E6E6E6"
          borderRadius={4}
        >
          <Text
            mb={1}
            textAlign="center"
            color="#162421"
            fontWeight={600}
            fontSize={16}
          >
            {currentInterviewData?.journalSourceName}
          </Text>

          <Box textAlign="right" color={'#4E6565'} fontWeight={600} fontSize={14}>
            <Text>
              - {currentInterviewData?.firstName}{" "}
              {currentInterviewData?.lastName}
            </Text>
            <Text>
              {currentInterviewData?.designation},{" "}
              {currentInterviewData?.company}
            </Text>
            <Text>{currentInterviewData?.dateOfPublishing}</Text>
          </Box>

          <Grid
            mt={2}
            p={1}
            templateColumns="repeat(12, 1fr)"
            rowGap={5}
            h={'auto'}
            // h={350}
            // overflowY="auto"
          >
            {currentInterviewQnaData?.length > 0 && currentInterviewQnaData?.map?.((qna, index) => (
                <React.Fragment key={index}>
                  <GridItem colSpan={1}>
                    <Image
                      mt={1}
                      src={bulletIcon}
                      alt={"bullet"}
                      transform="rotate(0deg)"
                    />
                  </GridItem>
                  <GridItem colSpan={11}>
                    <Text fontSize={14} fontWeight={600} color={'#162421'}>
                      {qna?.question}
                    </Text>
                    <Text fontSize={14} fontWeight={400} color={'#162421'}>
                      {qna?.answer}
                    </Text>
                  </GridItem>
                </React.Fragment>
              ))}
          </Grid>

          <Link href={currentInterviewData?.sourceLink} target="_blank" color={'#0C543C'}>
            <Flex my={2} align="center" gap={1.5}>
              {/* <Image src={urlIcon} alt="url" /> */}
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M12 3.42857H4.28571C4.05839 3.42857 3.84037 3.51888 3.67962 3.67962C3.51888 3.84037 3.42857 4.05839 3.42857 4.28571V19.7143C3.42857 19.9416 3.51888 20.1596 3.67962 20.3204C3.84037 20.4811 4.05839 20.5714 4.28571 20.5714H19.7143C19.9416 20.5714 20.1596 20.4811 20.3204 20.3204C20.4811 20.1596 20.5714 19.9416 20.5714 19.7143V12C20.5714 11.7749 20.6158 11.552 20.7019 11.344C20.7881 11.136 20.9143 10.947 21.0735 10.7878C21.2327 10.6286 21.4217 10.5024 21.6297 10.4162C21.8377 10.3301 22.0606 10.2857 22.2857 10.2857C22.5108 10.2857 22.7338 10.3301 22.9417 10.4162C23.1497 10.5024 23.3387 10.6286 23.4979 10.7878C23.6571 10.947 23.7834 11.136 23.8695 11.344C23.9557 11.552 24 11.7749 24 12V19.7143C24 20.8509 23.5485 21.941 22.7447 22.7447C21.941 23.5485 20.8509 24 19.7143 24H4.28571C3.14907 24 2.05898 23.5485 1.25526 22.7447C0.451529 21.941 0 20.8509 0 19.7143L0 4.28571C0 3.14907 0.451529 2.05898 1.25526 1.25526C2.05898 0.451529 3.14907 0 4.28571 0L12 0C12.4547 0 12.8907 0.180612 13.2122 0.502103C13.5337 0.823594 13.7143 1.25963 13.7143 1.71429C13.7143 2.16894 13.5337 2.60498 13.2122 2.92647C12.8907 3.24796 12.4547 3.42857 12 3.42857ZM16.3509 0.529714C16.4156 0.372966 16.5255 0.238964 16.6665 0.144669C16.8075 0.050375 16.9732 2.75328e-05 17.1429 0L23.1429 0C23.3702 0 23.5882 0.0903059 23.7489 0.251051C23.9097 0.411797 24 0.629814 24 0.857143V6.85714C24.0003 7.02689 23.9502 7.19291 23.856 7.33415C23.7619 7.47539 23.6279 7.58549 23.4711 7.65049C23.3143 7.71549 23.1417 7.73247 22.9752 7.69927C22.8087 7.66607 22.6559 7.58418 22.536 7.464L20.7497 5.67429L13.212 13.212C12.8887 13.5243 12.4556 13.6971 12.0062 13.6932C11.5567 13.6892 11.1267 13.509 10.8089 13.1911C10.491 12.8733 10.3108 12.4433 10.3068 11.9938C10.3029 11.5444 10.4757 11.1113 10.788 10.788L18.324 3.25029L16.536 1.464C16.4162 1.34404 16.3347 1.19124 16.3017 1.02494C16.2688 0.858637 16.2859 0.686297 16.3509 0.529714Z" fill="#0C543C"/>
              </svg>
              <Text fontSize={14} fontWeight={600} textDecor="underline" >
              View Source
              </Text>
            </Flex>
          </Link>

          <Flex justify="center" align="center" gap={3} fontSize={14} color={'#4E6565'}>
            <Text cursor="pointer" onClick={prevInterview}>
              <ChevronLeftIcon fontSize={14} />
            </Text>
            <Text cursor="pointer" onClick={() => setCurrentInterview(0)}>
              First
            </Text>
            <Text>...</Text>
            <Text>{currentInterview + 1}</Text>
            <Text>...</Text>
            <Text
              cursor="pointer"
              onClick={() => setCurrentInterview(interviews.length - 1)}
            >
              Last
            </Text>
            <Text cursor="pointer" onClick={nextInterview}>
              <ChevronRightIcon fontSize={14} />
            </Text>
          </Flex>
        </Box>
      ) : (
        <Box
          mt={2}
          px={4}
          py={3}
          borderWidth={2}
          borderColor="#E6E6E6"
          borderRadius={4}
        >
          <Text textAlign="center">No Q&A found</Text>
        </Box>
      )}
    </Box>
  );
}
