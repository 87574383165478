import {
  Box,
} from "@chakra-ui/react";
import ClientBackground from "../../Agency/ClientBackground";
import { useSelector } from "react-redux";
import UnlockRegistration from "../../../shared/UnlockRegistration/UnlockRegistration";
import RegistrationPopUp from "../RegistrationPopUp";
import { useState } from "react";



const ClientProfile = () => {
  const { isAuthenticatedUser } = useSelector((state) => state.userReducer);
  const [modelRegistrationPopUp, setModelRegistrationPopUp] = useState(false);
  return (
    <Box>
      {isAuthenticatedUser ? (
        <ClientBackground />
      ) : (
        <Box p={"15%"}>
          <UnlockRegistration
            setModelRegistrationPopUp={setModelRegistrationPopUp}
          />
        </Box>
      )}
      <RegistrationPopUp
        setModelRegistrationPopUp={setModelRegistrationPopUp}
        modelRegistrationPopUp={modelRegistrationPopUp}
      />
    </Box>
  );
};

export default ClientProfile;
