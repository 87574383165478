import { sendRequest } from "../libs/axiosConfig";

export const loginApi = async (reqBody) => {
  const { data } = await sendRequest({
    method: 'POST',
    url: `/api/v1/user/login`,
    data: reqBody,
  });
  return data;
};

export const loginGoogleApi = async (reqBody) => {
  const { data } = await sendRequest({
    method: 'POST',
    url: `/api/v1/user/googlelogin`,
    data: reqBody,
  });
  return data;
};

export const getAdvisoryApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/industry/advisory`,
    method: "POST",
    body: reqBody,
  });
  return data;
};

export const addAdvisoryApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/industry/addadvisory`,
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

export const deleteAdvisoryApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/industry/deleteadvisory`,
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

export const editAdvisoryApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/industry/editadvisory`,
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

export const userTransactionHistoryApi = async () => {
  const { data } = await sendRequest({
    url: `/api/v1/user/transactionhistory`,
    method: "POST"
  });
  return data;
};
