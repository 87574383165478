import { Box, Button, Collapse, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { RxAvatar } from "react-icons/rx";
import { MdLogout } from "react-icons/md";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { logoutAgency } from "../../actions/user/agencyActions";
import OrganisationIcon from "../../assets/images/orgIcon.png";
import { logoutOrganisation } from "../../actions/user/organisationActions";
import { Link, useNavigate } from "react-router-dom";

const UserMenu = () => {
  const dispatch = useDispatch();

  const { type, user } = useSelector((state) => state.userReducer);
  const navigate = useNavigate();

  const [expandMenu, setExpandMenu] = useState(false);

  const agencyLogoutHandler = () => {
    dispatch(logoutAgency(navigate));
  };

  const organisationLogoutHandler = () => {
    dispatch(logoutOrganisation(navigate));
  };

  return (
    <>
      {type === "agency" && (
        <>
          <Button
            leftIcon={<RxAvatar size={"25px"} />}
            rightIcon={expandMenu ? <ChevronDownIcon /> : <ChevronUpIcon />}
            size={"lg"}
            padding={"31px 20px"}
            display={"flex"}
            justifyContent={"flex-end"}
            borderRadius={0}
            variant={"ghost"}
            onClick={() => setExpandMenu(!expandMenu)}
          >
            <Box className="nav-expand-menu-btn-txt">{user?.name}</Box>
          </Button>

          <Collapse in={expandMenu} animateOpacity>
            <Stack pb={4} pl={2} pr={2}>
              <Link to={`/agency/profile`}>
                <Button w={"full"} variant={"ghost"}>
                  Profile
                </Button>
              </Link>
              <Link to={`/agency`}>
                <Button w={"full"} variant={"ghost"}>
                  Manage Clients
                </Button>
              </Link>

              <Button rightIcon={<MdLogout />} onClick={agencyLogoutHandler}>
                Logout
              </Button>
            </Stack>
          </Collapse>
        </>
      )}

      {type === "client" && (
        <>
          <Button
            leftIcon={<img width={"20px"} src={OrganisationIcon} alt="org" />}
            rightIcon={expandMenu ? <ChevronDownIcon /> : <ChevronUpIcon />}
            size={"lg"}
            padding={"31px 20px"}
            display={"flex"}
            justifyContent={"flex-end"}
            borderRadius={0}
            variant={"ghost"}
            onClick={() => setExpandMenu(!expandMenu)}
          >
            <Box className="nav-expand-menu-btn-txt">{user?.companyName}</Box>
          </Button>

          <Collapse in={expandMenu} animateOpacity>
            <Stack pb={4} pl={2} pr={2}>
              <Link to={`/client/${user.id}`}>
                <Button w={"full"} variant={"ghost"}>
                  Dashboard
                </Button>
              </Link>
              <Button
                rightIcon={<MdLogout />}
                onClick={organisationLogoutHandler}
              >
                Logout
              </Button>
            </Stack>
          </Collapse>
        </>
      )}
    </>
  );
};

export default UserMenu;
