import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Image,
  Link,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import bulletIcon from "../../assets/icons/bullet.png";
import urlIcon from "../../assets/icons/url.png";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { getIndustryWiseInterviews } from "../../api/industryLeaderInterviewApi";
import { toast } from "react-toastify";
import { getIndustryObservationsApi } from "../../api/industryObservationApi";
import './IndustryObservations.scss';

export default function IndustryObservations({
  currentSegmentRiskTab,
  industryPayload,
  industrySeqLoad,
  isblur,
}) {
  const [currentInterview, setCurrentInterview] = useState(0);
  const [interviews, setInterviews] = useState([]);
  const [isLoading] = useState(true);
  const [isObservationsLoading, setIsObservationsLoading] = useState(false);
  const [observations, setObservations] = useState({});
  const [payloadobservations, setPayloadObservations] = useState([]);

  useEffect(() => {
    const main = async (industryPayload) => {
      setIsObservationsLoading(false);
      try {
        const result = await getIndustryObservationsApi(industryPayload);
        setObservations(result);
        setIsObservationsLoading(true);
      } catch (error) {
        console.error(error);
        toast.error(
          error?.response?.data?.message || error?.message || "Failed",
          {
            position: "bottom-center",
          }
        );
      }
    };
    setPayloadObservations(industrySeqLoad);
    if (industryPayload) main(industryPayload);
  }, [industrySeqLoad, industryPayload]);

  const currentInterviewData = interviews?.[currentInterview];

  const currentInterviewQnaData = currentInterviewData?.qna?.filter(
    (qna) => qna?.qnaLabel === ""
  );

  const nextInterview = () => {
    if (currentInterview + 1 < interviews.length) {
      setCurrentInterview(currentInterview + 1);
    }
  };

  const prevInterview = () => {
    if (currentInterview - 1 >= 0) {
      setCurrentInterview(currentInterview - 1);
    }
  };
  
  return (
    <Box id={"observations_container"}>
      <Text id={"observations_container_label"}>Observations</Text>
      <Flex className="observation-heading">
        <Box className="observation-label">Label</Box>
        <Box className="observation-label">Last 2 years</Box>
        <Box className="observation-label">Overall</Box>
      </Flex>

      {isObservationsLoading ? (
        <Box id={"observations_content"}>
          {payloadobservations &&
            payloadobservations.map((payloadobservation, index) => (
              <>
                {currentSegmentRiskTab === payloadobservation.holder && (
                  <Box
                    textAlign="left"
                    color={"#1E1E1E"}
                    fontWeight={400}
                    fontSize={10}
                    key={`onbservations-${index}`}
                    filter={index > 4 && isblur ? "blur(2px)" : "blur(0px)"}
                  >
                    <Text
                      textAlign="left"
                      fontWeight={"var(--sylvr-h4-fw)"}
                      fontSize={"var(--sylvr-h4-fs)"}
                      m="2px 0px"
                    >
                      {payloadobservation?.label}
                    </Text>

                    {payloadobservation?.sublevel.map((observation) => {
                      return (
                        !observations[payloadobservation?.key][
                          observation?.skey
                        ]?.error && (
                          <>
                            <Box ml={1}>
                              <Flex ml={2}>
                                <Box
                                  mb={"2px"}
                                  mr={"10px"}
                                  width={"150px!important"}
                                >
                                  <Text
                                    m={"2px 0px"}
                                    p={"4px 8px"}
                                    textAlign="left"
                                    fontWeight={500}
                                    fontSize={"var(--sylvr-h4-fs)"}
                                    bg={"#F2F7F7"}
                                    borderRadius={"5px"}
                                  >
                                    {observation?.slabel}
                                  </Text>
                                </Box>
                                <Box mb={"2px"} fontSize={"var(--sylvr-h4-fs)"}>
                                  {observations[payloadobservation?.key][
                                    observation?.skey
                                  ]?.change_last_two_years < 0
                                    ? "A decrease"
                                    : "An increase"}{" "}
                                  in the last two years by{" "}
                                  {observations[payloadobservation?.key][
                                    observation?.skey
                                  ]?.change_last_two_years?.toFixed(2)}
                                  %
                                </Box>
                                <Box className="verticalbar">
                                  {/* <Box className="verticalbar"></Box> */}
                                </Box>
                                <Box fontSize={"var(--sylvr-h4-fs)"}>
                                  The overall trend is{" "}
                                  {observations[payloadobservation?.key][
                                    observation?.skey
                                  ]?.change_overall < 0
                                    ? "decrease"
                                    : "increase"}{" "}
                                  by{" "}
                                  {observations[payloadobservation?.key][
                                    observation?.skey
                                  ]?.change_overall?.toFixed(2)}
                                  %
                                </Box>
                              </Flex>
                            </Box>
                          </>
                        )
                      );
                    })}
                  </Box>
                )}
              </>
            ))}
        </Box>
      ) : (
        <Box
          mt={2}
          px={4}
          py={3}
          borderWidth={2}
          borderColor="#E6E6E6"
          borderRadius={4}
        >
          <Text textAlign="center">Loading Observations...</Text>
        </Box>
      )}
    </Box>
  );
}
