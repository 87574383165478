import {
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  Link,
  Text,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Flex,
  Button,
  Img,
  useDisclosure,
  Heading,
  ButtonGroup,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuOptionGroup,
  Divider,
  Input,
  Textarea,
  Select,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";

import moreInsightsImage from "../assets/images/more-insights.png";
import ratingLikeImage from "../assets/images/rating-like.png";
import { usePayment } from "../contexts/PaymentContext";
import {
  getEarlyBirdApi,
  initiatePayment,
  registerEarlyBirdApi,
  verifyPayment,
} from "../api/agencyApi";
import { refreshUserWithoutNavigate } from "../actions/user/userActions";
import {
  initiateClientDashboardPurchase,
  verifyClientDashboardPurchasePayment,
} from "../api/organisationApi";
import party_popper from "../assets/images/party-popper.png";
import party_box from "../assets/images/party-box.png";
import flash from "../assets/images/flash.png";
import check_arrow from "../assets/images/check-arrow.png";
import msg_subscription from "../assets/images/msg-subscription.png";

import "./PaymentModel.scss";
import { errMsg } from "../utils/helperFunction";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Step1Component = ({ setStep }) => {
  return (
    <ModalContent py="10" px="15" position="relative">
      <ModalHeader fontSize="28px" fontWeight="700" color={"#0C543C"}>
        Add Client Dashboards
      </ModalHeader>
      <ModalCloseButton
        position="absolute"
        top="-15px"
        height="42px"
        width="42px"
        right="-15px"
        background="#fff"
        borderRadius="50px"
        boxShadow="-4px -4px 18px rgba(0, 0, 0, 0.4)"
      />
      <ModalBody>
        <Flex>
          <Img
            src={moreInsightsImage}
            alt="More Insights Image"
            h={"111.59px"}
            w={"130.56px"}
          />
          <Box display="flex" flexDir="column" justifyContent="center" pl={8}>
            <Heading
              fontSize="24px"
              color="#1E1E1E"
              fontWeight={600}
              textColor={"#162421"}
            >
              More Insights
            </Heading>
            <Text fontWeight="400" color="#000000" mt={2} fontSize={16}>
              Expand your clientele and make better decisions
            </Text>
          </Box>
        </Flex>

        <Flex mt={10}>
          <Img
            src={ratingLikeImage}
            alt="More Insights Image"
            h={"118px"}
            w={"123px"}
          />
          <Box display="flex" flexDir="column" justifyContent="center" pl={8}>
            <Heading
              fontSize="24px"
              color="#1E1E1E"
              fontWeight={600}
              textColor={"#162421"}
            >
              At your fingertips
            </Heading>
            <Text fontWeight="400" color="#000000" mt={2} fontSize={16}>
              Find all your clients and their business insights, any time, any
              place
            </Text>
          </Box>
        </Flex>
      </ModalBody>

      <ModalFooter justifyContent="center" mt={12}>
        <Button
          bg="#0C543C"
          color={"white"}
          mr={3}
          onClick={() => setStep(2)}
          fontSize="18px"
          fontWeight={700}
          px={10}
        >
          Buy Client Dashboards
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};

const StepPlanSubcriptionComponent = ({ setStep }) => {
  const DASHBOARD_PRICE = 5000;

  const dispatch = useDispatch();

  const { showPaymentModal, hidePaymentModal } = usePayment();
  const { user: userDetails } = useSelector((state) => state.userReducer);
  const [noOfDashboards, setNoOfDashboards] = useState(1);
  const [payFlag, setPayFlag] = useState(false);
  const [earlyBirdRemaining, setEarlyBirdRemaining] = useState(0);
  const [earlyBirdCurrent, setEarlyBirdCurrent] = useState();
  const [earlyBirdFlag, setEarlyBirdFlag] = useState(false);
  const [answer1, setAnswer1] = useState("");
  const [answer2, setAnswer2] = useState("");
  const [answer3, setAnswer3] = useState("");
  const [answer4, setAnswer4] = useState("");
  const [answer5a, setAnswer5a] = useState("");
  const [answer5b, setAnswer5b] = useState("");
  const [answer1Flag, setAnswer1Flag] = useState(true);
  const [answer2Flag, setAnswer2Flag] = useState(false);
  const [answer3Flag, setAnswer3Flag] = useState(false);
  const [answer4Flag, setAnswer4Flag] = useState(false);
  const [answer5Flag, setAnswer5Flag] = useState(false);
  const [answer1ErrorFlag, setAnswer1ErrorFlag] = useState(false);
  const [answer2ErrorFlag, setAnswer2ErrorFlag] = useState(false);
  const [answer3ErrorFlag, setAnswer3ErrorFlag] = useState(false);
  const [answer4ErrorFlag, setAnswer4ErrorFlag] = useState(false);
  const [answer5aErrorFlag, setAnswer5aErrorFlag] = useState(false);
  const [answer5bErrorFlag, setAnswer5bErrorFlag] = useState(false);
  const [subscriptionAmount1, setSubscriptionAmount1] = useState(5000);
  const [subscriptionOffer, setSubscriptionOffer] = useState({});
  const [subscriptionAmountSuffix1, setSubscriptionAmountSuffix1] =
    useState("monthly");

  const totalAmount = noOfDashboards * DASHBOARD_PRICE;

  const formattedCurrency = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(subscriptionAmount1);

  const freeContent = [
    "2 partners",
    "10 segments in segment trend charts",
    "All industrial segment observations",
    "Save Advisory Notes",
  ];
  const financialAdvisoryContent = [
    "5 partners",
    "15 clients",
    "20 segments in segment trend charts (on request)",
    "All industrial segment observations",
    "Save, share via mail and collaborate on Advisory Notes",
    "Review Initiatives for each client",
    "Connect with dedicated experts",
  ];
  const multiDisciplineAdvisoryContent = [
    "10 partners",
    "50 clients",
    "Unlimited segments in segment trend charts (on request)",
    "All industrial segment observations",
    "Save, share via mail and collaborate on Advisory Notes",
    "Review Initiatives for each client",
    "Connect with dedicated experts",
    "Offer your expertise",
    "Compliance Risks",
    "Value Chain Networking",
  ];
  const SMEContent = [
    "1 client",
    "SME segment trend charts",
    "SME industry segment observations",
    "Collaborate on Advisory Notes",
    "Review Initiatives for SME",
    "Connect with dedicated experts",
    "Compliance Risks",
    "KPIs management",
    "SME profile management",
    "Value Chain Networking",
  ];
  const offerAmount = (sequence) => {
    let returnOffer = {
      offerDiscountAmount: subscriptionAmount1,
    };
    if (sequence >= 1 && sequence <= 10) {
      if (subscriptionAmountSuffix1 === "monthly") {
        returnOffer = {
          offerDiscountPer: 40,
          offerDiscountAmount: subscriptionAmount1 * 0.4,
        };
      } else if (subscriptionAmountSuffix1 === "quarterly") {
        returnOffer = {
          offerDiscountPer: 50,
          offerDiscountAmount: subscriptionAmount1 * 0.5,
        };
      } else {
        returnOffer = {
          offerDiscountPer: 60,
          offerDiscountAmount: subscriptionAmount1 * 0.6,
        };
      }
    } else if (sequence >= 11 && sequence <= 30) {
      if (subscriptionAmountSuffix1 === "monthly") {
        returnOffer = {
          offerDiscountPer: 30,
          offerDiscountAmount: subscriptionAmount1 * 0.3,
        };
      } else if (subscriptionAmountSuffix1 === "quarterly") {
        returnOffer = {
          offerDiscountPer: 40,
          offerDiscountAmount: subscriptionAmount1 * 0.4,
        };
      } else {
        returnOffer = {
          offerDiscountPer: 50,
          offerDiscountAmount: subscriptionAmount1 * 0.5,
        };
      }
    }
    return returnOffer;
  };
  const handlePayment = async () => {
    setPayFlag(true);
    let response = null;
    if (userDetails?.role === "agency") {
      response = await initiatePayment({
        noOfDashboards,
        subscriptionName: "Financial Advisory",
        paymentFrequency: subscriptionAmountSuffix1,
        amount: parseInt(subscriptionOffer?.offerDiscountAmount),
        agencyId: userDetails.id,
        paymentMethod: "razorpay",
      });
    } else if (userDetails?.role === "client") {
      response = await initiateClientDashboardPurchase({
        subscriptionName: "Financial Advisory",
        paymentFrequency: subscriptionAmountSuffix1,
        amount: parseInt(subscriptionOffer?.offerDiscountAmount),
        clientId: userDetails.id,
        paymentMethod: "razorpay",
      });
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: response.amount,
      currency: "INR",
      name: "Sylvr",
      description: "Payment for purchase of new dashboards",
      order_id: response.razorpay.razorpayOrderId,
      handler: async function (paymentResponse) {
        if (userDetails.role === "agency") {
          await verifyPayment({
            razorpay_payment_id: paymentResponse.razorpay_payment_id,
            razorpay_order_id: paymentResponse.razorpay_order_id,
            razorpay_signature: paymentResponse.razorpay_signature,
            paymentMethod: "razorpay",
          });
        } else if (userDetails.role === "client") {
          await verifyClientDashboardPurchasePayment({
            razorpay_payment_id: paymentResponse.razorpay_payment_id,
            razorpay_order_id: paymentResponse.razorpay_order_id,
            razorpay_signature: paymentResponse.razorpay_signature,
            paymentMethod: "razorpay",
          });
        }

        dispatch(refreshUserWithoutNavigate());

        showPaymentModal();
        setStep(3);
      },
      prefill: {
        name: userDetails.name,
        email: userDetails.email,
        contact: "1234567890",
      },
    };

    hidePaymentModal();

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", function (response) {
      alert(response.error.description);
    });

    paymentObject.open();
  };
  const handleEarlyBird = async () => {
    if (answer5a === "") {
      setAnswer5aErrorFlag(true);
    } else if (answer5b === "") {
      setAnswer5aErrorFlag(false);
      setAnswer5bErrorFlag(true);
    } else {
      setAnswer1("");
      setAnswer2("");
      setAnswer3("");
      setAnswer4("");
      setAnswer5a("");
      setAnswer5b("");
      setAnswer5aErrorFlag(false);
      setAnswer1Flag(true);
      setAnswer5Flag(false);
      setAnswer5bErrorFlag(false);
      setEarlyBirdFlag(false);
      try {
        const { message, data } = await registerEarlyBirdApi({
          answer1,
          answer2,
          answer3,
          answer4,
          answer5a,
          answer5b: parseInt(answer5b),
        });
        toast.info(message, {
          toastId: message,
          position: "bottom-center",
        });
        setSubscriptionOffer(offerAmount(data?.sequence));
        setSubscriptionAmount1(5000);
        setEarlyBirdCurrent(data?.sequence);
        setEarlyBirdRemaining(data?.remaining);
      } catch (err) {
        const message = errMsg(err);
        toast.error(message, {
          toastId: message,
          position: "bottom-center",
        });
      }
    }
  };
  const getEarlyBird = async () => {
    const data = await getEarlyBirdApi();
    let offerSequnce = data?.sequence;
    setSubscriptionOffer(offerAmount(offerSequnce));
    setEarlyBirdCurrent(offerSequnce);
    setEarlyBirdRemaining(data?.remaining);
  };
  useEffect(() => {
    getEarlyBird();
  }, []);
  useEffect(() => {
    setSubscriptionOffer(offerAmount(earlyBirdCurrent));
  }, [subscriptionAmount1]);
  return earlyBirdFlag ? (
    <ModalContent className="earlyBird" position="relative">
      <ModalCloseButton className="close-btn" />
      <ModalBody>
        <Box
          className="plan-block"
          margin={"15px"}
          justifyContent={"space-around"}
          display={"inline-flex"}
          minH={"200px"}
        >
          {answer1Flag && (
            <Box width={"100%"}>
              <Box m={"15px 5px"}>
                For your Advisory practice to succeed, <b>what</b> is{" "}
                <i style={{ textDecoration: "underline" }}>the one project</i>{" "}
                you really need to accomplish
              </Box>
              <Box m={"15px 5px"}>
                <Textarea
                  onChange={(e) => setAnswer1(e.target.value)}
                  value={answer1}
                />
                {answer1ErrorFlag && (
                  <span style={{ color: "orange" }}>Please enter value</span>
                )}
              </Box>
              <Flex justifyContent={"space-around"}>
                <Box>
                  <Button
                    onClick={() => {
                      if (answer1 === "") {
                        setAnswer1ErrorFlag(true);
                      } else {
                        setAnswer1ErrorFlag(false);
                        setAnswer1Flag(false);
                        setAnswer2Flag(true);
                      }
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Flex>
            </Box>
          )}
          {answer2Flag && (
            <Box width={"100%"}>
              <Box m={"15px 5px"}>
                <b>Why</b> do you need to accomplish{" "}
                <i style={{ textDecoration: "underline" }}>this specifically</i>{" "}
                vs everything else possible
              </Box>
              <Box m={"15px 5px"}>
                <Textarea
                  onChange={(e) => setAnswer2(e.target.value)}
                  value={answer2}
                />
                {answer2ErrorFlag && (
                  <span style={{ color: "orange" }}>Please enter value</span>
                )}
              </Box>
              <Flex justifyContent={"space-around"}>
                <Box>
                  <Button
                    onClick={() => {
                      setAnswer1Flag(true);
                      setAnswer2Flag(false);
                    }}
                  >
                    Previous
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      if (answer2 === "") {
                        setAnswer2ErrorFlag(true);
                      } else {
                        setAnswer2ErrorFlag(false);
                        setAnswer2Flag(false);
                        setAnswer3Flag(true);
                      }
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Flex>
            </Box>
          )}
          {answer3Flag && (
            <Box width={"100%"}>
              <Box m={"15px 5px"}>
                <b>What</b> will success look like? <b>How</b> will you{" "}
                <i style={{ textDecoration: "underline" }}>know</i> you have
                accomplished it, no going back?
              </Box>
              <Box m={"15px 5px"}>
                <Textarea
                  onChange={(e) => setAnswer3(e.target.value)}
                  value={answer3}
                />
                {answer3ErrorFlag && (
                  <span style={{ color: "orange" }}>Please enter value</span>
                )}
              </Box>
              <Flex justifyContent={"space-around"}>
                <Box>
                  <Button
                    onClick={() => {
                      setAnswer2Flag(true);
                      setAnswer3Flag(false);
                    }}
                  >
                    Previous
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      if (answer3 === "") {
                        setAnswer3ErrorFlag(true);
                      } else {
                        setAnswer3ErrorFlag(false);
                        setAnswer3Flag(false);
                        setAnswer4Flag(true);
                      }
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Flex>
            </Box>
          )}
          {answer4Flag && (
            <Box width={"100%"}>
              <Box m={"15px 5px"}>
                <b>What</b>{" "}
                <i style={{ textDecoration: "underline" }}>options</i> do you
                consider to accomplish this
              </Box>
              <Box m={"15px 5px"}>
                <Textarea
                  onChange={(e) => setAnswer4(e.target.value)}
                  value={answer4}
                />
                {answer4ErrorFlag && (
                  <span style={{ color: "orange" }}>Please enter value</span>
                )}
              </Box>
              <Flex justifyContent={"space-around"}>
                <Box>
                  <Button
                    onClick={() => {
                      setAnswer3Flag(true);
                      setAnswer4Flag(false);
                    }}
                  >
                    Previous
                  </Button>
                </Box>
                <Box>
                  <Button
                    onClick={() => {
                      if (answer4 === "") {
                        setAnswer4ErrorFlag(true);
                      } else {
                        setAnswer4ErrorFlag(false);
                        setAnswer4Flag(false);
                        setAnswer5Flag(true);
                      }
                    }}
                  >
                    Next
                  </Button>
                </Box>
              </Flex>
            </Box>
          )}
          {answer5Flag && (
            <Box width={"100%"}>
              <Box m={"15px 5px"}>
                [Scene: After lot of research, you have found the most optimum
                option.]
                <br></br>
                Enter the <b>maximum price</b> you would{" "}
                <i style={{ textDecoration: "underline" }}>pay</i>
              </Box>
              <Flex m={"15px 5px"} justifyContent={"space-around"}>
                <Box>
                  <Box>
                    <Select
                      focusBorderColor="primary.400"
                      size={"sm"}
                      ml={"10px"}
                      borderRadius={"10px"}
                      w={"120px"}
                      onChange={(e) => setAnswer5a(e.target.value)}
                    >
                      <option value={""}>--Select--</option>
                      {["One-time", "Monthly", "Quarterly", "Half-yearly"].map(
                        (frequency, index) => (
                          <option key={index} value={frequency}>
                            {frequency}
                          </option>
                        )
                      )}
                    </Select>
                  </Box>
                  {answer5aErrorFlag && (
                    <span style={{ color: "orange" }}>Please select value</span>
                  )}
                </Box>
                <Box>
                  <Input
                    onChange={(e) => setAnswer5b(e.target.value)}
                    placeholder="Enter the maximum price"
                    value={answer5b}
                  />
                  {answer5bErrorFlag && (
                    <span style={{ color: "orange" }}>Please select value</span>
                  )}
                </Box>
              </Flex>
              <Flex justifyContent={"space-around"}>
                <Box>
                  <Button
                    onClick={() => {
                      setAnswer4Flag(true);
                      setAnswer5Flag(false);
                    }}
                  >
                    Previous
                  </Button>
                </Box>
                <Box>
                  <Button onClick={handleEarlyBird}>Submit</Button>
                </Box>
              </Flex>
            </Box>
          )}
        </Box>
      </ModalBody>
    </ModalContent>
  ) : (
    <ModalContent position="relative" maxW={"90%"}>
      <ModalHeader className="payment-title" paddingBottom={"0px"}>
        Focus on advisory without frustrations of searching industry PDFs,
        purchasing & cleaning datasets for competitive research and doubts of
        data inaccuracy.
      </ModalHeader>
      {earlyBirdCurrent > 0 && earlyBirdCurrent <= 30 ? (
        <>
          <Flex className="payment-early-bird" alignItems={"center"}>
            <Box className="payment-early-bird-image">
              <Img
                src={party_popper}
                alt="Party Popper"
                h={"24px"}
                w={"24px"}
              />
            </Box>
            <Box>
              <Box className="payment-early-bird-text">
                Early Bird Offer Activated. Enjoy!
              </Box>
            </Box>
            <Box className="payment-early-bird-image">
              <Img
                src={party_popper}
                alt="Party Popper"
                h={"24px"}
                w={"24px"}
              />
            </Box>
          </Flex>
        </>
      ) : (
        <>
          {earlyBirdRemaining > 0 && (
            <>
              <Flex className="payment-early-bird">
                <Box className="payment-early-bird-image">
                  <Img
                    src={party_box}
                    alt="Party Popper"
                    h={"20px"}
                    w={"20px"}
                  />
                </Box>
                <Box>
                  <Box
                    className="payment-early-bird-link"
                    onClick={() => setEarlyBirdFlag(true)}
                  >
                    Grab the Early Bird Offer ( 30-60% by taking a survey )
                  </Box>
                </Box>
                <Box className="payment-early-bird-image">
                  <Img
                    src={party_box}
                    alt="Party Popper"
                    h={"20px"}
                    w={"20px"}
                  />
                </Box>
              </Flex>
              <Flex className="payment-early-bird" alignItems={"center"}>
                <Box className="payment-early-bird-image">
                  <Img src={flash} alt="Party Popper" h={"18px"} w={"15px"} />
                </Box>
                <Box>Only {earlyBirdRemaining} seats remaining</Box>
                <Box className="payment-early-bird-image">
                  <Img src={flash} alt="Party Popper" h={"18px"} w={"15px"} />
                </Box>
              </Flex>
            </>
          )}
        </>
      )}
      <ModalCloseButton className="close-btn" />
      <ModalBody>
        <Box margin={"5px 22px"}>
          <RadioGroup defaultValue="1">
            <Stack spacing={4} direction="row">
              <Radio
                value="1"
                onClick={() => {
                  setSubscriptionAmount1(5000);
                  setSubscriptionAmountSuffix1("monthly");
                }}
              >
                Monthly
              </Radio>
              <Radio
                value="2"
                onClick={() => {
                  setSubscriptionAmount1(15000);
                  setSubscriptionAmountSuffix1("quarterly");
                }}
              >
                Quarterly
              </Radio>
              <Radio
                value="3"
                onClick={() => {
                  setSubscriptionAmount1(30000);
                  setSubscriptionAmountSuffix1("half-yearly");
                }}
              >
                Half Yearly
              </Radio>
            </Stack>
          </RadioGroup>
        </Box>
        <Flex
          className="plan-block"
          margin={"5px 15px"}
          justifyContent={"space-around"}
          display={"inline-flex"}
        >
          <Box className="plan-container">
            <Box>
              <Flex padding={"5px"} alignItems={"center"}>
                <Box className="plan-title">Free Forever</Box>
                <Box fontSize={"16px"} fontWeight={"400"}>
                  (Current)
                </Box>
              </Flex>
              <Flex justifyContent={"center"}>
                <Box fontSize={"13px"} fontWeight={"400"} color={"#5D5D5D"}>
                  ₹
                </Box>
                <Box fontSize={"29px"} fontWeight={"700"} color={"#5D5D5D"}>
                  0
                </Box>
              </Flex>
              <Box className="plan-content">
                <Box fontSize={"14px"} fontWeight={"500"} color={"#1E1E1E"}>
                  Standard Analysis
                </Box>
                <Box>
                  {freeContent &&
                    freeContent.map((item) => (
                      <Flex
                        justifyContent={"start"}
                        alignItems={"center"}
                        mb="5px"
                      >
                        <Box style={{ margin: "0px 5px" }}>
                          <Img
                            src={check_arrow}
                            alt="Party Popper"
                            h={"7.5px"}
                            w={"10px"}
                          />
                        </Box>
                        <Box
                          fontSize={"14px"}
                          fontWeight={"400"}
                          color={"#1E1E1E"}
                        >
                          {item}
                        </Box>
                      </Flex>
                    ))}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box className="plan-container">
            <Box>
              <Flex
                borderTopRadius={"12px"}
                justifyContent={"space-between"}
                alignItems={"center"}
                backgroundColor={"#5D5D5D"}
                padding={"5px"}
              >
                <Box className="plan-title" color={"#FFFFFF"}>
                  Financial Advisory
                </Box>
                <Box style={{ margin: "0px 5px" }}>
                  <Img
                    src={msg_subscription}
                    alt="msg_subscription"
                    h={"24px"}
                    w={"24px"}
                  />
                </Box>
              </Flex>
              {subscriptionOffer?.offerDiscountPer && (
                <Box
                  className="plan-title"
                  color={"#0C543C"}
                  padding={"5px"}
                  textAlign={"center"}
                >
                  {subscriptionOffer?.offerDiscountPer}% discount applied
                </Box>
              )}
              <Flex justifyContent={"center"} padding={"0px"}>
                <Box fontSize={"13px"} fontWeight={"400"} color={"#5D5D5D"}>
                  ₹
                </Box>
                <Flex
                  alignItems={"center"}
                  fontSize={"29px"}
                  fontWeight={"700"}
                  color={"#5D5D5D"}
                >
                  <Box>{subscriptionOffer?.offerDiscountAmount}</Box>
                  <Box fontSize={"13px"} fontWeight={"600"}>
                    / partner {subscriptionAmountSuffix1}
                  </Box>
                </Flex>
              </Flex>
              <Box className="plan-content">
                <Box fontSize={"14px"} fontWeight={"500"} color={"#1E1E1E"}>
                  Analysis for every client, with tools ranging from competitive
                  risks to connection with experts
                </Box>
                <Box>
                  {financialAdvisoryContent &&
                    financialAdvisoryContent.map((item) => (
                      <Flex
                        justifyContent={"start"}
                        alignItems={"center"}
                        mb="5px"
                      >
                        <Box style={{ margin: "0px 5px" }}>
                          <Img
                            src={check_arrow}
                            alt="Party Popper"
                            h={"7.5px"}
                            w={"10px"}
                          />
                        </Box>
                        <Box
                          fontSize={"14px"}
                          fontWeight={"400"}
                          color={"#1E1E1E"}
                        >
                          {item}
                        </Box>
                      </Flex>
                    ))}
                </Box>
              </Box>
              <Flex justifyContent={"center"} margin={"10px"}>
                {payFlag ? (
                  <Text>Processing please wait...</Text>
                ) : (
                  <Button
                    bgColor={"#5D5D5D"}
                    h={"33px"}
                    fontSize={"14px"}
                    fontWeight={"700"}
                    onClick={handlePayment}
                  >
                    Get Started
                  </Button>
                )}
              </Flex>
            </Box>
          </Box>
          <Box className="plan-container">
            <Box>
              <Flex justifyContent={"space-between"}>
                <Box className="plan-title">Multi-discipline Advisory</Box>
                <Box style={{ margin: "0px 5px" }}>
                  <Img
                    src={msg_subscription}
                    alt="msg_subscription"
                    h={"24px"}
                    w={"24px"}
                  />
                </Box>
              </Flex>
              <Box className="plan-content">
                <Box fontSize={"14px"} fontWeight={"500"} color={"#1E1E1E"}>
                  Tools ranging from compliance risks to value chain networking
                  per client
                </Box>
                <Box>
                  {multiDisciplineAdvisoryContent &&
                    multiDisciplineAdvisoryContent.map((item) => (
                      <Flex
                        justifyContent={"start"}
                        alignItems={"center"}
                        mb="5px"
                      >
                        <Box style={{ margin: "0px 5px" }}>
                          <Img
                            src={check_arrow}
                            alt="Party Popper"
                            h={"7.5px"}
                            w={"10px"}
                          />
                        </Box>
                        <Box
                          fontSize={"14px"}
                          fontWeight={"400"}
                          color={"#1E1E1E"}
                        >
                          {item}
                        </Box>
                      </Flex>
                    ))}
                </Box>
              </Box>
              <Flex
                fontSize={"14px"}
                fontWeight={"700"}
                justifyContent={"center"}
                margin={"10px"}
              >
                Coming Soon
              </Flex>
            </Box>
          </Box>
          <Box className="plan-container">
            <Box>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Box className="plan-title">SME</Box>
                <Box style={{ margin: "0px 5px" }}>
                  <Img
                    src={msg_subscription}
                    alt="msg_subscription"
                    h={"24px"}
                    w={"24px"}
                  />
                </Box>
              </Flex>
              <Box className="plan-content">
                <Box fontSize={"14px"} fontWeight={"500"} color={"#1E1E1E"}>
                  Empower your SME with the complete toolset, from analysis to
                  production view
                </Box>
                <Box>
                  {SMEContent &&
                    SMEContent.map((item) => (
                      <Flex
                        justifyContent={"start"}
                        alignItems={"center"}
                        mb="5px"
                      >
                        <Box style={{ margin: "0px 5px" }}>
                          <Img
                            src={check_arrow}
                            alt="Party Popper"
                            h={"7.5px"}
                            w={"10px"}
                          />
                        </Box>
                        <Box
                          fontSize={"14px"}
                          fontWeight={"400"}
                          color={"#1E1E1E"}
                        >
                          {item}
                        </Box>
                      </Flex>
                    ))}
                </Box>
              </Box>
              <Flex
                fontSize={"14px"}
                fontWeight={"700"}
                justifyContent={"center"}
                margin={"10px"}
              >
                Coming Soon
              </Flex>
            </Box>
          </Box>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

const Step2Component = ({ setStep }) => {
  const DASHBOARD_PRICE = 5999;

  const dispatch = useDispatch();

  const { showPaymentModal, hidePaymentModal } = usePayment();
  const { user: userDetails } = useSelector((state) => state.userReducer);

  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [noOfDashboards, setNoOfDashboards] = useState(1);

  const totalAmount = noOfDashboards * DASHBOARD_PRICE;

  const formattedCurrency = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(totalAmount);

  const handlePayment = async () => {
    let response = null;

    if (userDetails?.role === "agency") {
      response = await initiatePayment({
        noOfDashboards,
        amount: totalAmount,
        agencyId: userDetails.id,
        paymentMethod: "razorpay",
      });
    } else if (userDetails?.role === "client") {
      response = await initiateClientDashboardPurchase({
        amount: totalAmount,
        clientId: userDetails.id,
        paymentMethod: "razorpay",
      });
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: response.amount,
      currency: "INR",
      name: "Sylvr",
      description: "Payment for purchase of new dashboards",
      order_id: response.razorpay.razorpayOrderId,
      handler: async function (paymentResponse) {
        if (userDetails.role === "agency") {
          await verifyPayment({
            razorpay_payment_id: paymentResponse.razorpay_payment_id,
            razorpay_order_id: paymentResponse.razorpay_order_id,
            razorpay_signature: paymentResponse.razorpay_signature,
            paymentMethod: "razorpay",
          });
        } else if (userDetails.role === "client") {
          await verifyClientDashboardPurchasePayment({
            razorpay_payment_id: paymentResponse.razorpay_payment_id,
            razorpay_order_id: paymentResponse.razorpay_order_id,
            razorpay_signature: paymentResponse.razorpay_signature,
            paymentMethod: "razorpay",
          });
        }

        dispatch(refreshUserWithoutNavigate());

        showPaymentModal();
        setStep(3);
      },
      prefill: {
        name: userDetails.name,
        email: userDetails.email,
        contact: "1234567890",
      },
    };

    hidePaymentModal();

    const paymentObject = new window.Razorpay(options);

    paymentObject.on("payment.failed", function (response) {
      alert(response.error.description);
    });

    paymentObject.open();
  };

  return (
    <ModalContent py="10" px="15" position="relative">
      <ModalCloseButton
        position="absolute"
        top="-15px"
        height="42px"
        width="42px"
        right="-15px"
        background="#fff"
        borderRadius="50px"
        boxShadow="-4px -4px 18px rgba(0, 0, 0, 0.4)"
      />
      <ModalBody>
        <Flex justifyContent="space-between">
          <Box>
            <Heading fontSize="24px" fontWeight="700" textColor={"#0C543C"}>
              Choose Your Purchase
            </Heading>
            <Text mt={4} textColor={"#162421"} fontWeight={600} fontSize={16}>
              Select the number of client dashboards
            </Text>
            <Box pos="relative">
              <ButtonGroup
                my={3}
                size="md"
                isAttached
                variant="outline"
                onClick={() => setIsShowDropdown(true)}
              >
                <Button _hover={{ bg: "#E6E6E6" }} color={"#4E6565"}>
                  {noOfDashboards}
                </Button>
                <IconButton
                  _hover={{ bg: "#E6E6E6" }}
                  color={"#4E6565"}
                  aria-label="Add to friends"
                  icon={<ChevronDownIcon />}
                />
              </ButtonGroup>
              {isShowDropdown && (
                <Box
                  p={0.5}
                  bg="#fff"
                  color={"#162421"}
                  zIndex={10}
                  pos="absolute"
                  rounded="lg"
                  border="1px"
                  borderColor="#E6E6E6"
                  top={45}
                  left={0}
                >
                  {userDetails?.role === "agency" && (
                    <>
                      {[...new Array(5)].fill(0).map((_, i) => (
                        <Box
                          bg={noOfDashboards === i + 1 ? "#0C543C" : "#F8F8F8"}
                          color={noOfDashboards === i + 1 ? "white" : "#5D5D5D"}
                          rounded="lg"
                          borderBottom={i === 4 ? "0" : "1px"}
                          py={i === 4 ? "0" : "2px"}
                          borderColor="#E6E6E6"
                          px={10}
                          cursor="pointer"
                          _hover={{ bg: "#E6E6E6", color: "#fff" }}
                          onClick={() => {
                            setNoOfDashboards(i + 1);
                            setIsShowDropdown(false);
                          }}
                        >
                          {i + 1}
                        </Box>
                      ))}
                    </>
                  )}

                  {userDetails?.role === "client" && (
                    <>
                      {[...new Array(1)].fill(0).map((_, i) => (
                        <Box
                          bg={noOfDashboards === i + 1 ? "#0C543C" : "#F8F8F8"}
                          color={noOfDashboards === i + 1 ? "white" : "#5D5D5D"}
                          rounded="lg"
                          borderBottom={i === 4 ? "0" : "1px"}
                          py={i === 4 ? "0" : "2px"}
                          borderColor="#E6E6E6"
                          px={10}
                          cursor="pointer"
                          _hover={{ bg: "#E6E6E6", color: "#fff" }}
                          onClick={() => {
                            setNoOfDashboards(i + 1);
                            setIsShowDropdown(false);
                          }}
                        >
                          {i + 1}
                        </Box>
                      ))}
                    </>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          <Box
            borderRight="3px solid #E6E6E6"
            borderLeft="3px solid #E6E6E6"
            px={14}
          >
            <Heading fontSize="24px" fontWeight="600" textColor={"#0C543C"}>
              Total
            </Heading>
            <Text fontSize="28px" fontWeight="700" color={"#162421"} mt={4}>
              {formattedCurrency}
            </Text>
          </Box>
          <Box>
            <Heading fontSize="24px" fontWeight="600" textColor={"#0C543C"}>
              What you recieve
            </Heading>
            <Text fontSize="18px" fontWeight="700" color="#162421" mt={4}>
              {noOfDashboards} Client Dashboards
            </Text>
          </Box>
        </Flex>

        <Box my={50}>
          <Heading fontSize="24px" fontWeight="700" textColor={"#0C543C"}>
            Select Payment Method
          </Heading>

          <Box mt={5}>
            <Button
              bg="#0C543C"
              border={1}
              borderColor="#1E1E1E"
              color="#fff"
              onClick={handlePayment}
            >
              Razorpay
            </Button>
          </Box>
        </Box>
      </ModalBody>
    </ModalContent>
  );
};

const Step3Component = () => {
  return (
    <ModalContent py="10" px="15" position="relative">
      <ModalCloseButton
        position="absolute"
        top="-15px"
        height="42px"
        width="42px"
        right="-15px"
        background="#fff"
        borderRadius="50px"
        boxShadow="-4px -4px 18px rgba(0, 0, 0, 0.4)"
      />
      <ModalBody>
        <Flex flexDir="column" justifyContent="center" alignItems="center">
          <Heading fontSize="28px" fontWeight="600" color="#0C543C">
            Congratulations on your purchase!
          </Heading>
          <Box mt={8} mb={14}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="32"
              height="34"
              viewBox="0 0 32 34"
              fill="none"
            >
              <path
                d="M8 0C3.55556 0 0 3.55556 0 8C0 12.4444 3.55556 16 8 16C11.5556 16 14.4 13.8667 15.4667 10.6667H21.3333V16H26.6667V10.6667H32V5.33333H15.4667C14.4 2.13333 11.5556 0 8 0ZM8 5.33333C9.42222 5.33333 10.6667 6.57778 10.6667 8C10.6667 9.42222 9.42222 10.6667 8 10.6667C6.57778 10.6667 5.33333 9.42222 5.33333 8C5.33333 6.57778 6.57778 5.33333 8 5.33333ZM16 19.5556L14.0444 24.1778L8.88889 24.7111L12.8 28.0889L11.5556 33.0667L16 30.4L20.2667 33.0667L19.2 28.0889L23.1111 24.7111L17.9556 24.3556L16 19.5556Z"
                fill="#8F8F8F"
              />
            </svg>
          </Box>
          <Text fontSize="16px" fontWeight="400" color="#000">
            To make the most out of your newly acquired dashboards, visit{" "}
            <Link textDecor="underline">https://link</Link>
          </Text>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};

export default function PaymentModal() {
  const { isShowPaymentModal, hidePaymentModal } = usePayment();
  const [step, setStep] = useState(2);

  return (
    <>
      <Modal
        size="4xl"
        isOpen={isShowPaymentModal}
        onClose={() => {
          setStep(2);
          hidePaymentModal();
        }}
        isCentered
      >
        <ModalOverlay />
        {step === 1 && <Step1Component setStep={setStep} />}

        {step === 2 && <StepPlanSubcriptionComponent setStep={setStep} />}
        {/* {step === 2 && <Step2Component setStep={setStep} />} */}

        {step === 3 && <Step3Component setStep={setStep} />}
      </Modal>
    </>
  );
}
