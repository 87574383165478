import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  Link as ReactRouterLink,
  useLocation,
  useNavigate,
} from "react-router-dom";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  ChevronUpIcon,
} from "@chakra-ui/icons";
import {
  Box,
  VStack,
  Collapse,
  Link as ChakraLink,
  Button,
  Tooltip,
  Input,
} from "@chakra-ui/react";

import { setCurrentClient } from "../../actions/client/clientActions";
import { setCurrentIndustry } from "../../actions/industry/industryActions";
import { getPopularSectorsResult } from "../../actions/home/homeActions";

import { usePayment } from "../../contexts/PaymentContext";
import { INDUSTRY_INTERVIEW_AGENCY } from "../../constants/agency";
import { useSectorsContext } from "../../customHooks/useSectorsContext";
import { toast } from "react-toastify";
import "./AgencySidebar.scss";
import search from "../../assets/images/search.png";
import { INDUSTRY_ICONS } from "../../utils/Stringconstant";

const AgencyLeftSidebar = (props) => {
  const { sectorsData } = useSectorsContext();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const location = useLocation();
  const [page_location, setPageLocation] = useState("");
  const { showPaymentModal } = usePayment();
  const [industryImage, setIndustryImage] = useState(true);
  const [searchText, setSearchText] = useState("");
  const { industryId } = useSelector((state) => state.industryReducer);
  // const { clientsData } = useSelector((state) => state.agencyReducer);
  const { user, isAuthenticatedUser } = useSelector(
    (state) => state.userReducer
  );
  // const clientDashboards = user?.clientDashboards;

  const [isOpen, setIsOpen] = useState(true);
  const [currentIndustryId, setCurrentIndustryId] = useState("");
  // const { popularSectors } = useSelector((state) => state.homeReducer);
  // const [sectorsData, setSectorsData] = useState([]);
  const [filterSectorsData, setFilterSectorsData] = useState([]);

  useEffect(() => {
    setPageLocation(location.pathname);
  }, []);

  useEffect(() => {
    if (sectorsData.length > 0) setCurrentIndustryId(sectorsData[0].id);
    setFilterSectorsData(sectorsData);
  }, [sectorsData]);

  useEffect(() => {
    if (searchText !== "") {
      let searchTextLower = searchText.toLowerCase();
      let filterSectors = [];
      for (let sectorIndex in sectorsData) {
        let clients = sectorsData[sectorIndex].clients;
        let filterClients = [];
        for (let clientIndex in clients) {
          let client = clients[clientIndex];
          let companyName = client.companyName.toLowerCase();
          if (companyName.includes(searchTextLower)) {
            filterClients.push(client);
          }
        }
        if (filterClients.length > 0) {          
          let sectorFilter = {
            id: sectorsData[sectorIndex].id,
            sector: sectorsData[sectorIndex].sector,
            totalClients: filterClients.length,
            clients: filterClients,
          };
          filterSectors.push(sectorFilter);
        }
      }
      setFilterSectorsData(filterSectors);
    } else {
      setFilterSectorsData(sectorsData);
    }
  }, [searchText]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleIndustryClick = (sector) => {
    console.log("====sector", isAuthenticatedUser, sector.id);
    if (isAuthenticatedUser) {
      if (currentIndustryId === sector.id) {
        setCurrentIndustryId("");
      } else {
        setCurrentIndustryId(sector.id);
      }
    } else {
      setIndustry({
        industryId: sector.id,
        sectorName: sector.sector,
        totalClients: sector.totalClients,
      });
    }
  };

  const addClientButtonHandler = (event) => {
    toast.error("You will recieve recieve update shortly", {
      position: "top-center",
    });
    // event.stopPropagation();

    // if (clientDashboards?.availableDashboards >= 1) {
    //   navigation("/agency/client-new");
    // } else {
    //   showPaymentModal();
    // }
  };

  const openPaymentModalButtonHandler = (event) => {
    event.stopPropagation();
    event.preventDefault();
    console.log("=====props.onClose", props.onClose);
    if (props.onClose) props.onClose();
    showPaymentModal();
  };

  const setIndustry = (industry) => {
    console.log("====setIndustry", isAuthenticatedUser, industry);
    if (props.onClose) props.onClose();
    dispatch(setCurrentIndustry(industry));
    if (!isAuthenticatedUser) {
      if (props.onClose) props.onClose();
      dispatch(setCurrentIndustry(industry));
      navigation(`/industry/${industry.industryId}/dashboard`);
    }
    // if (isAuthenticatedUser) {
    //   navigation(`/agency/industry/${industry.industryId}/industry-activity`);
    // } else {
    //   navigation(`/industry/${industry.industryId}/dashboard`);
    // }
  };
  const setClientId = (sector) => {
    if (props.onClose) props.onClose();
    dispatch(setCurrentClient(sector.clientId));
    setIndustry({
      industryId: sector.id,
      sectorName: sector.sector,
      totalClients: sector.totalClients,
    });
    navigation(`/agency/client/${sector.clientId}/risk-assessment`);
  };

  const OnClickToOrganisationHome = (client) => {
    const data = { client: { ...client } };
    dispatch({
      type: "GET_ORG_DETAILS_SUCCESS",
      payload: data,
    });
  };

  return (
    <>
      <Box className="left-menu-container">
        <VStack align="start" className="left-menu">
          {INDUSTRY_INTERVIEW_AGENCY.email === user?.email && (
            <ChakraLink
              py="3"
              width="100%"
              fontWeight="600"
              borderBottom="2px"
              borderColor="white"
              mt="0"
              as={ReactRouterLink}
              to={`/${user?.id}/industry-leader-interviews`}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display="inline-block">Industry Leader Interviews</Box>
              </Box>
            </ChakraLink>
          )}

          {!isAuthenticatedUser && (
            <ChakraLink
              className="navigation-label"
              as={ReactRouterLink}
              to="https://beta.sylvr.co.in"
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display="inline-block">Home</Box>
              </Box>
            </ChakraLink>
          )}

          <ChakraLink
            className="navigation-label"
            as={ReactRouterLink}
            to={
              isAuthenticatedUser
                ? "/agency/practice-dashboard"
                : "/practice-dashboard"
            }
          >
            <Box
              display="flex"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Box display="inline-block">Practice Dashboard</Box>
              {isAuthenticatedUser && (
                <Button
                  mr={2}
                  size="xs"
                  onClick={openPaymentModalButtonHandler}
                >
                  +
                </Button>
              )}
            </Box>
          </ChakraLink>

          {page_location === "/practice-dashboard" ? (
            <ChakraLink
              className="navigation-label"
              as={Link}
              to={"/dashboard"}
            >
              <Box
                display="flex"
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Box display="inline-block">Industries</Box>
              </Box>
            </ChakraLink>
          ) : (
            <>
              <Box className="navigation-label-industry search-container">
                {industryImage && (
                  <Box
                    className="search-box"
                    style={{ cursor: "pointer" }}
                    onClick={() => setIndustryImage(!industryImage)}
                  >
                    <img src={search} alt="search" className="industry-icon" />
                  </Box>
                )}
                <Box className="search-box-label" onClick={toggleSidebar}>
                  <Box>{"Industries"}</Box>
                  <Box display="flex" justifyContent="end">
                    {isAuthenticatedUser && (
                      <Button mr={2} size="xs" onClick={addClientButtonHandler}>
                        +
                      </Button>
                    )}
                    {/* {isOpen ? <ChevronDownIcon /> : <ChevronRightIcon />} */}
                  </Box>
                </Box>
              </Box>
              {!industryImage && (
                <Box
                  className="search-box"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Input onChange={(e) => setSearchText(e.target.value)} />
                  <span
                    style={{
                      position: "absolute",
                      right: "6px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setSearchText("");
                      setIndustryImage(!industryImage);
                    }}
                  >
                    X
                  </span>
                </Box>
              )}
              <Collapse in={isOpen}>
                <VStack
                  p="0px"
                  align="start"
                  fontSize={"14px"}
                  maxHeight={"calc(100vh - 170px)"}
                  overflow={"auto"}
                >
                  {filterSectorsData &&
                    filterSectorsData.map((sector, index) => {
                      return (
                        <>
                          <Box
                            w={"100%"}
                            bgColor={
                              sector.id === currentIndustryId ? "#B1B1B1" : ""
                            }
                            p={"3px"}
                            display={"flex"}
                            key={`sector-${sector.id}`}
                          >
                            {isAuthenticatedUser ? (
                              <Box className="industry-box">
                                <Box className="industry-image">
                                  <img
                                    src={INDUSTRY_ICONS[sector?.sector]}
                                    alt="menu-arrow"
                                    className="industry-icon"
                                  />
                                </Box>
                                <Box className="industry-label-container">
                                  <ChakraLink
                                    className="industry-label-text"
                                    key={sector.id}
                                    onClick={() => handleIndustryClick(sector)}
                                  >
                                    <Tooltip
                                      label={sector.sector}
                                      fontSize="11px"
                                    >
                                      {sector?.sector &&
                                      sector?.sector.length > 25
                                        ? (sector?.sector).substring(0, 25) +
                                          "..."
                                        : sector?.sector}
                                    </Tooltip>
                                  </ChakraLink>
                                </Box>
                                {currentIndustryId === sector.id ? (
                                  <ChevronUpIcon
                                    width={"20px"}
                                    height={"20px"}
                                  />
                                ) : (
                                  <ChevronDownIcon />
                                )}
                              </Box>
                            ) : (
                              <Box className="industry-box">
                                <Box className="industry-image">
                                  <img
                                    src={INDUSTRY_ICONS[sector?.sector]}
                                    alt="menu-arrow"
                                    className="industry-icon"
                                  />
                                </Box>
                                <Box className="industry-label-container">
                                  <ChakraLink
                                    className="industry-label-text"
                                    key={sector.id}
                                    onClick={() => handleIndustryClick(sector)}
                                  >
                                    <Tooltip
                                      label={sector.sector}
                                      fontSize="11px"
                                    >
                                      {sector?.sector &&
                                      sector?.sector.length > 25
                                        ? (sector?.sector).substring(0, 25) +
                                          "..."
                                        : sector?.sector}
                                    </Tooltip>
                                  </ChakraLink>
                                </Box>
                              </Box>
                            )}
                          </Box>
                          {isAuthenticatedUser && (
                            <Box
                              p="0px 0px 0px 10px"
                              style={{
                                display:
                                  currentIndustryId === sector.id
                                    ? "block"
                                    : "none",
                              }}
                            >
                              {sector?.clients &&
                                sector.clients.map((client, index) => {
                                  return (
                                    <Box>
                                      <ChakraLink
                                        fontSize={"12px"}
                                        p={"0px"}
                                        key={client._id}
                                        onClick={() =>
                                          setClientId({
                                            ...sector,
                                            clientId: client._id,
                                          })
                                        }
                                      >
                                        {client.companyName}
                                      </ChakraLink>
                                    </Box>
                                  );
                                })}
                            </Box>
                          )}
                        </>
                      );
                    })}
                </VStack>
              </Collapse>
            </>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default AgencyLeftSidebar;
