import {Button, Flex, Stack } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import Instructions from "./Instructions";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import SheetUploaderBox from "./SheetUploaderBox";
import { toast } from "react-toastify";
import { uploadAuditSheets } from "../../actions/audit/auditUploadActions";
import Loader from "../../components/Loader/Loader";

import { areArraysEqual } from "../../utils/helperFunction";
import {
  BALANCE_SHEET,
  PROFIT_LOSS_STATEMENT,
} from "../../utils/Stringconstant";
import { balanceSheetReqBody, IncomeStatReqBody } from "./helper";
import { allUnits } from "../../assets/auditSheetFormats";

// const toCamelCase = (str) => {
//   // Replace any hyphens or underscores with a space
//   str = str.replace(/[-_]/g, " ");

//   // Convert the string to title case
//   str = str.replace(/\w\S*/g, function (txt) {
//     return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
//   });

//   // Replace any spaces with an empty string and make the first character lowercase
//   str = str.replace(/\s+/g, "");
//   str = str.charAt(0).toLowerCase() + str.slice(1);

//   return str;
// };

const AuditSheetUploader = ({ clientData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const balanceSheetRef = useRef(null);
  const statementRef = useRef(null);

  const [unit, setUnit] = useState("");
  const [currentUploadOnly, setCurrentUploadOnly] = useState(false);
  const [balanceSheet, setBalanceSheetData] = useState("");
  const [rawBalanceSheet, setRawBalanceSheetData] = useState("");
  const [currentBalanceSheet, setCurrentBalanceSheetData] = useState("");
  const [currentRawBalanceSheet, setCurrentRawBalanceSheetData] = useState("");
  const [profitLoss, setStatementSheetData] = useState("");
  const [rawProfitLoss, setRawStatementSheetData] = useState("");
  const [currentProfitLoss, setCurrentStatementSheetData] = useState("");
  const [currentRawProfitLoss, setCurrentRawStatementSheetData] = useState("");
  const [balanceSheetEntryYears, setBalanceSheetEntryYears] = useState([]);
  const [statSheetEntryYears, setStatSheetEntryYears] = useState([]);
  const [balanceSheetFile, setBalanceSheetFile] = useState(null);
  const [statementSheetFile, setStatementSheetFile] = useState(null);

  /* const { loading: auditLoading, clientId } = useSelector(
    (state) => state.auditUploadReducer.audit
  ); */

  const { clientId } = useParams();

  const balanceSheetRefOnClickHandler = (e) => {
    balanceSheetRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const statementRefOnClickHandler = (e) => {
    statementRef.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    console.log("==submitHandler==currentUploadOnly", currentUploadOnly);
    if (!unit) {
      toast.error("Please select your unit to Proceed", {
        toastId: "unit-empty",
        position: "bottom-center",
      });
      return;
    }
    if (currentUploadOnly) {
      if (!currentBalanceSheet) {
        toast.error("Please Upload Your Current Balance Sheet to Proceed", {
          toastId: "bal-sheet-empty",
          position: "bottom-center",
        });
        return;
      }

      if (!currentProfitLoss) {
        toast.error("Please Upload Your Current Statement Sheet to Proceed", {
          toastId: "st-sheet-empty",
          position: "bottom-center",
        });
        return;
      } 
    } else {
      if (!balanceSheet) {
        toast.error("Please Upload Your Balance Sheet to Proceed", {
          toastId: "bal-sheet-empty",
          position: "bottom-center",
        });
        return;
      }

      if (!profitLoss) {
        toast.error("Please Upload Your Statement Sheet to Proceed", {
          toastId: "st-sheet-empty",
          position: "bottom-center",
        });
        return;
      }
    }

    if (!areArraysEqual(balanceSheetEntryYears, statSheetEntryYears)) {
      toast.error("Both sheets must have data for equal number of years", {
        toastId: "unequal-cols",
        position: "bottom-center",
      });
      return;
    }
    const reqBodyB_S = currentUploadOnly
      ? balanceSheetReqBody(currentBalanceSheet, unit)
      : balanceSheetReqBody(balanceSheet, unit);
    const reqBodyP_L = currentUploadOnly
      ? IncomeStatReqBody(currentProfitLoss, unit)
      : IncomeStatReqBody(profitLoss, unit);
    const reqBodyRawBS = currentUploadOnly
      ? currentRawBalanceSheet
      : rawBalanceSheet;
    const reqBodyRawIS = currentUploadOnly
      ? currentRawProfitLoss
      : rawProfitLoss;
    console.log("===clientId,", clientId);
    let uploadCurrentYear = currentUploadOnly ? '2024' : ''
    dispatch(
      uploadAuditSheets(
        uploadCurrentYear,
        clientId,
        allUnits.find((val) => val.value === Number(unit)).name,
        reqBodyB_S,
        reqBodyP_L,
        reqBodyRawBS,
        reqBodyRawIS,
        navigate
      )
    );
    // Reset the state here after dispatch
    setBalanceSheetFile(null);
    setStatementSheetFile(null);
    setBalanceSheetData("");
    setStatementSheetData("");
    setUnit("");

    toast.success(
      '⚠️ This will SUBMIT the current information. Any future EDITS to past audited financial performance will require Admin consent. You can VERIFY in "View Past Performance" tab.',
      {
        position: "bottom-center",
      }
    );
  };

  return (
    <>
      {/* {auditLoading ? (
        <Loader />
      ) : (
        <> */}
      <Stack
        p={["21px", "32px"]}
        className="body-container"
        height={"395px"}
        overflow={"auto"}
      >
        <Instructions
          balanceSheetRefOnClickHandler={balanceSheetRefOnClickHandler}
          statementRefOnClickHandler={statementRefOnClickHandler}
          setUnit={setUnit}
        />
        <Flex justifyContent={"space-between"}>
          <SheetUploaderBox
            setAllyears={setBalanceSheetEntryYears}
            labelName={"Balance Sheet Historical"}
            boxName={BALANCE_SHEET}
            boxRef={balanceSheetRef}
            setData={setBalanceSheetData}
            setRawData={setRawBalanceSheetData}
            clientData={clientData}
            currentUploadOnly={currentUploadOnly}
            setCurrentUploadOnly={setCurrentUploadOnly}
            dataState={"historical"}
          />

          <SheetUploaderBox
            setAllyears={setBalanceSheetEntryYears}
            labelName={"Balance Sheet Current"}
            boxName={BALANCE_SHEET}
            boxRef={balanceSheetRef}
            setData={setCurrentBalanceSheetData}
            setRawData={setCurrentRawBalanceSheetData}
            clientData={clientData}
            currentUploadOnly={currentUploadOnly}
            setCurrentUploadOnly={setCurrentUploadOnly}
            dataState={"current"}
          />
        </Flex>
        <Flex justifyContent={"space-between"}>
          <SheetUploaderBox
            setAllyears={setStatSheetEntryYears}
            labelName={"Income Statement Historical"}
            boxName={PROFIT_LOSS_STATEMENT}
            boxRef={statementRef}
            setData={setStatementSheetData}
            setRawData={setRawStatementSheetData}
            clientData={clientData}
            currentUploadOnly={currentUploadOnly}
            setCurrentUploadOnly={setCurrentUploadOnly}
            dataState={"historical"}
          />
          <SheetUploaderBox
            setAllyears={setStatSheetEntryYears}
            labelName={"Income Statement Current"}
            boxName={PROFIT_LOSS_STATEMENT}
            boxRef={statementRef}
            setData={setCurrentStatementSheetData}
            setRawData={setCurrentRawStatementSheetData}
            clientData={clientData}
            currentUploadOnly={currentUploadOnly}
            setCurrentUploadOnly={setCurrentUploadOnly}
            dataState={"current"}
          />
        </Flex>

        <Flex justifyContent={"space-around"} pt={"20px"}>
          <Button
            onClick={submitHandler}
          >
            Submit
          </Button>
        </Flex>

        {/* <Tabs variant="unstyled">
              <TabList>
                <Tab _selected={{ color: "white", bg: "gray.500" }}>
                  View Existing Balance Sheet
                </Tab>
                <Tab _selected={{ color: "white", bg: "gray.500" }}>
                  View Existing Profit & Loss
                </Tab>
              </TabList>
              <Box h="200px" border="2px" color="gray.500">
                <TabPanels>
                    <TabPanel>
                      
                    </TabPanel>
                    <TabPanel>
                      
                    </TabPanel>
                  </TabPanels>
                <Box w="100%" h="100%" display="flex" alignItems="center" justifyContent="center">There is no historical data</Box>
              </Box>
            </Tabs> */}
      </Stack>
      {/* </>
      )} */}
    </>
  );
};

export default AuditSheetUploader;
