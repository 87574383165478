import { useRef } from 'react';
import {
  Box,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  useDisclosure,
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import AgencyLeftSidebar from '../../components/AgencySidebar/AgencyLeftSidebar';
import './navigation.scss';

const FloatingLeftNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef();
  return (
    <>
      <Box bg="#fff" width={"54px"}>
        <Button
          ref={btnRef}
          className="sidebar-btn"
          display={{ base: "block", lg: "block" }}
          onClick={onOpen}
        >
          <HamburgerIcon fontSize={20} />
        </Button>
      </Box>

      <Drawer
        isOpen={isOpen}
        placement="left"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody bg="#E6E6E6">
            <AgencyLeftSidebar onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Box
        bg="#E6E6E6"
        // mt="50px"
        display={{ base: "none", lg: "none" }}
        width={"20%"}
      >
        <AgencyLeftSidebar onClose={onClose} />
      </Box>
    </>
  );
};

export default FloatingLeftNav;
