import { Box, Button, Text, Flex } from '@chakra-ui/react';
import { Link } from "react-router-dom";
import './Footer.scss'

const Footer = () => {
  return (
    <Flex className="footer-nav">
      <Box textAlign={"left"} maxW={["", "430px"]} minW={["", "430px"]}>
        <Text fontSize="12px" p={"1px"} m={"0px"}>
          SYLVR is owned by 50EEN Technologies Private Limited
        </Text>
        {/* <Text fontSize="12px" p={'1px'} m={'0px'}>
          {' '}
          • CIN – U72200CH2021PT C043821
        </Text>
        <Text fontSize="12px" p={'1px'} m={'0px'}>
          {' '}
          • DPIT – DIPP106245
        </Text>
        <Text fontSize="12px" p={'1px'} m={'0px'}>
          {' '}
          • Regd. Address: SCF 6, Sector 11/D, Chandigarh
        </Text> */}
      </Box>
      <Box display={["none", "none", "block"]}>
        <Box>
          <Link to={"https://beta.sylvr.co.in/about"}>
            <Button
              fontSize={"14px"}
              // paddingY={{ base: 2, md: 4 }}
              fontWeight={400}
              textTransform={"uppercase"}
              variant={"link"}
              padding={"0px 5px"}
            >
              ABOUT US
            </Button>
          </Link>
          <Link
            to={
              "https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/contact_us"
            }
          >
            <Button
              fontSize={"14px"}
              paddingY={{ base: 2, md: 4 }}
              fontWeight={400}
              textTransform={"uppercase"}
              variant={"link"}
              padding={"0px 5px"}
            >
              CONTACT US
            </Button>
          </Link>
          <Link
            to={"https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/terms"}
          >
            <Button
              fontSize={"14px"}
              // paddingY={{ base: 2, md: 4 }}
              fontWeight={400}
              variant={"link"}
              padding={"0px 5px"}
            >
              Terms & Conditions
            </Button>
          </Link>
          <Link
            to={"https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/privacy"}
          >
            <Button
              fontSize={"14px"}
              paddingY={{ base: 2, md: 4 }}
              fontWeight={400}
              variant={"link"}
              padding={"0px 5px"}
            >
              Privacy Policy
            </Button>
          </Link>
          <Link
            to={"https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/refund"}
          >
            <Button
              fontSize={"14px"}
              // paddingY={{ base: 2, md: 4 }}
              fontWeight={400}
              variant={"link"}
              padding={"0px 5px"}
            >
              Cancellation & Refunds Policy
            </Button>
          </Link>
        </Box>
      </Box>
    </Flex>
  );
};

export default Footer;
