import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import App from "./App";
import reduxStore from "./reduxStore";
import customStyle from "./styles/style";
import ErrorBoundary from "./ErrorBoundary";
import PaymentContextProvider from "./contexts/PaymentContext";
import { SectorsContextProvider } from "./contexts/SectorsContext";
import { TourProvider } from "./contexts/TourContext";

const chakraTheme = extendTheme({ ...customStyle });

// axios config
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <Provider store={reduxStore}>
    <ErrorBoundary>
      <BrowserRouter>
        <TourProvider>
        <ChakraProvider theme={chakraTheme}>
          <PaymentContextProvider>
              <SectorsContextProvider>
            <App />
              </SectorsContextProvider>
          </PaymentContextProvider>
          <ToastContainer />
        </ChakraProvider>
        </TourProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </Provider>
  // </React.StrictMode>
);
