import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Tab,
  TabList,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Td,
  Text,
  Th,
  Tr,
} from "@chakra-ui/react";

import Loader from "../../components/Loader/Loader";
import { getRawAuditSheets } from "../../actions/audit/auditUploadActions";

const SheetPreview = ({ sheetData }) => {
  const getCellData = (col) => (isNaN(col) ? col : Number(col).toFixed(2));

  return (
    <>
      <TableContainer>
        <Table size="sm">
          <Tbody>
            {sheetData.map((row, index) => (
              <Tr my={10} key={index}>
                <Th width={"400px"}>{row[0]}</Th>

                {row.slice(1).map((col, ind) => (
                  <Td key={ind}>
                    {getCellData(col) === "0.00" ? "-" : getCellData(col)}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

const PastPerformance = () => {
  const SHEET_TABS = {
    balance: "balance",
    profitloss: "profitloss",
  };

  const RENDER_MODES = {
    tabular: "tabular",
    chart: "chart",
  };

  const dispatch = useDispatch();

  const { clientId } = useParams();

  const { loading, error, rawAuditSheetsData } = useSelector(
    (state) => state.auditRawSheetsReducer
  );

  const [sheetTab, setSheetTab] = useState(SHEET_TABS.balance);
  const [renderMode, setRenderMode] = useState(RENDER_MODES.tabular);

  useEffect(() => {
    if (clientId) {
      dispatch(getRawAuditSheets({ clientId }));
    }
  }, [clientId]);

  return (
    <Box mt={5} mx={5}>
      <Flex align="end" justify="space-between">
        <Tabs variant="unstyled">
          <TabList>
            <Tab
              fontSize={16}
              fontWeight={600}
              color={"#B1B1B1"}
              _selected={{ color: "white", bg: "gray" }}
              onClick={() => setSheetTab(SHEET_TABS.balance)}
            >
              View Balance Sheet
            </Tab>
            <Tab
              fontSize={16}
              fontWeight={600}
              color={"#B1B1B1"}
              _selected={{ color: "white", bg: "gray" }}
              onClick={() => setSheetTab(SHEET_TABS.profitloss)}
            >
              View Profit & Loss
            </Tab>
          </TabList>
        </Tabs>
        <Tabs variant="unstyled">
          <TabList>
            <Tab
              _selected={{ color: "white", bg: "gray" }}
              onClick={() => setRenderMode(RENDER_MODES.tabular)}
            >
              Tabular
            </Tab>
            {/* <Tab
              _selected={{ color: "white", bg: "gray" }}
              onClick={() => setRenderMode(RENDER_MODES.tabular)}
            >
              Chart View
            </Tab> */}
          </TabList>
        </Tabs>
      </Flex>

      <Box
        w="100%"
        h={500}
        p={1}
        border="2px"
        borderColor="gray"
        overflowY="auto"
      >
        {loading && (
          <Flex w="100%" h="100%" justify="center" align="center">
            <Loader />
          </Flex>
        )}

        {error && (
          <Flex w="100%" h="100%" justify="center" align="center">
            <Text>{JSON.stringify(error)}</Text>
          </Flex>
        )}

        {rawAuditSheetsData?.rawBalanceSheet &&
          rawAuditSheetsData?.rawBalanceSheet?.length < 1 &&
          rawAuditSheetsData?.rawProfitLossSheet &&
          rawAuditSheetsData?.rawProfitLossSheet?.length < 1 && (
            <Flex w="100%" h="100%" justify="center" align="center">
              <Text>No Audit sheet found</Text>
            </Flex>
          )}

        {sheetTab === SHEET_TABS.balance &&
          renderMode === RENDER_MODES.tabular && (
            <SheetPreview
              sheetData={rawAuditSheetsData.rawBalanceSheet || []}
            />
          )}

        {sheetTab === SHEET_TABS.profitloss &&
          renderMode === RENDER_MODES.tabular && (
            <SheetPreview
              sheetData={rawAuditSheetsData.rawProfitLossSheet || []}
            />
          )}
      </Box>

      <Box ps={1} pt={1}>
        <Text>
          {sheetTab} sheet values in {rawAuditSheetsData?.unit}
        </Text>
      </Box>
    </Box>
  );
};

export default PastPerformance;
