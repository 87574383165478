import {
  Box,
  VStack,
  Link as ChakraLink,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Button,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import "./LeftSideNav.scss";
import { useSectorsContext } from "../../customHooks/useSectorsContext";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { setCurrentIndustry } from "../../actions/industry/industryActions";
import { useNavigate } from "react-router-dom";
import { setCurrentClient } from "../../actions/client/clientActions";
import { INDUSTRY_ICONS } from "../../utils/Stringconstant";

const LeftSideNav = () => {
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { sectorsData } = useSectorsContext();
  const { industryId } = useSelector((state) => state.industryReducer);
  const [currentIndustryId, setCurrentIndustryId] = useState();
  const { user, isAuthenticatedUser } = useSelector(
    (state) => state.userReducer
  );
  const handleIndustryClick = (sector) => {
    console.log("====sector", isAuthenticatedUser, sector);
    if (isAuthenticatedUser) {
      setCurrentIndustryId(sector.id);
    } else {
      setIndustry({
        industryId: sector.id,
        sectorName: sector.sector,
        totalClients: sector.totalClients,
      });
    }
  };
  const setIndustry = (industry) => {
    console.log("====setIndustry", isAuthenticatedUser, industry);
    // if (props.onClose) props.onClose();
    dispatch(setCurrentIndustry(industry));
    if (!isAuthenticatedUser) {
      // if (props.onClose) props.onClose();
      dispatch(setCurrentIndustry(industry));
      navigation(`/industry/${industry.industryId}/dashboard`);
    }
  };
  const setClientId = (sector) => {
    // if (props.onClose) props.onClose();
    dispatch(setCurrentClient(sector.clientId));
    setIndustry({
      industryId: sector.id,
      sectorName: sector.sector,
      totalClients: sector.totalClients,
    });
    navigation(`/agency/client/${sector.clientId}/risk-assessment`);
  };
  return (
    <VStack className="left-side-nav">
      <VStack align="start" fontSize={"14px"}>
        {sectorsData &&
          sectorsData.map((sector, index) => {
            return (
              <>
                <Box
                  className="industry-block-icon"
                  bgColor={sector.id === industryId ? "#B1B1B1" : "#FFFFFF"}
                  key={`sector-${sector.id}`}
                >
                  <Menu>
                    <Tooltip label={sector.sector} fontSize="11px">
                      <MenuButton
                        onClick={() => {
                          if (!isAuthenticatedUser) {
                            handleIndustryClick(sector);
                          }
                        }}
                      >
                        <img
                          src={INDUSTRY_ICONS[sector?.sector]}
                          alt="menu-arrow"
                          className="industry-icon"
                        />
                      </MenuButton>
                    </Tooltip>
                    {isAuthenticatedUser && sector?.clients && (
                      <MenuList maxHeight={"200px"} overflowY={"auto"}>
                        {sector.clients.map((client, index) => {
                          return (
                            <MenuItem
                              key={client._id}
                              onClick={() =>
                                setClientId({
                                  ...sector,
                                  clientId: client._id,
                                })
                              }
                            >
                              {client.companyName}
                            </MenuItem>
                          );
                        })}
                      </MenuList>
                    )}
                  </Menu>
                </Box>
                {/* {isAuthenticatedUser ? (
                    <Box
                      style={{ cursor: "pointer" }}
                      onClick={() => handleIndustryClick(sector)}
                    >
                      <Tooltip label={sector.sector} fontSize="11px">
                        {sector?.sector && sector?.sector.length > 25
                          ? (sector?.sector).substring(0, 25) + "..."
                          : sector?.sector}
                      </Tooltip>
                    </Box>
                  ) : (
                    <ChakraLink
                      pt={4}
                      key={sector.id}
                      onClick={() => handleIndustryClick(sector)}
                    >
                      <Tooltip label={sector.sector} fontSize="11px">
                        {sector?.sector && sector?.sector.length > 25
                          ? (sector?.sector).substring(0, 25) + "..."
                          : sector?.sector}
                      </Tooltip>
                    </ChakraLink>
                  )} */}
                {/* </Box> */}
                {false && isAuthenticatedUser && (
                  <Box
                    p="0px 0px 0px 10px"
                    style={{
                      display:
                        currentIndustryId === sector.id ? "block" : "none",
                    }}
                  >
                    {sector?.clients &&
                      sector.clients.map((client, index) => {
                        return (
                          <Box>
                            <ChakraLink
                              fontSize={"12px"}
                              pt={4}
                              key={client._id}
                              onClick={() =>
                                setClientId({
                                  ...sector,
                                  clientId: client._id,
                                })
                              }
                            >
                              {client.companyName}
                            </ChakraLink>
                          </Box>
                        );
                      })}
                  </Box>
                )}
              </>
            );
          })}
      </VStack>
    </VStack>
  );
};

export default LeftSideNav;
