import { Flex } from "@chakra-ui/react";
import React from "react";
import { FileUploader } from "react-drag-drop-files";

const FileDragAndDrop = ({ fileTypes = ["CSV", "XLSX"], handleChange }) => {
  return (
    <>
      <Flex h={"120px"} justifyContent={"start"} className="drag-drop-box">
        <FileUploader types={fileTypes} handleChange={handleChange} classes="rddf" />
      </Flex>
    </>
  );
};

export default FileDragAndDrop;
