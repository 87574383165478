/* import axios from "axios";
import axiosHeader from "../axiosHeader"; */
import { toast } from "react-toastify";

import { sendRequest } from "../../libs/axiosConfig";
import { errMsg, setToken } from "../../utils/helperFunction";
import { logoutApi } from "../../api/refreshUserApi";
import { loginApi, loginGoogleApi } from "../../api/userApi";

/* const ServerBaseURI = process.env.REACT_APP_URI_SERVER_BASE_URL; */

export const loginUser =
  ({ email, pswd: password }, navigate) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "USER_LOGIN_REQ",
      });

      const data = await loginApi({ email, password });
      setToken(data.token);

      dispatch({
        type: "USER_LOGIN_SUC",
        payload: data,
      });

      if (data.role === "agency") {
        localStorage.setItem("isAuthenticatedUser", true);
        localStorage.setItem('type', 'agency')
        // navigate(`/agency/practice-dashboard`);
        navigate("/agency/industry/20/industry-activity");
      } else if (data.role === "client") {
        localStorage.setItem("type", "client");
        const dataObj = { client: { ...data } };
        dispatch({
          type: "GET_ORG_DETAILS_SUCCESS",
          payload: dataObj,
        });
        navigate(`/client/${data.id}/sme-background`);
      }

      toast.success(`Welcome back ${data.companyName || data.name}`, {
        toastId: data.id,
        position: "bottom-center",
      });
    } catch (err) {
      const message = errMsg(err);

      toast.error(message, {
        toastId: message,
        position: "bottom-center",
      });

      dispatch({
        type: "USER_LOGIN_FAIL",
        payload: { error: true, message },
      });
    }
  };

export const refreshUserWithoutNavigate = () => async (dispatch) => {
  try {
    dispatch({
      type: "REFRESH_LOGIN_REQ",
    });

    const { data } = await sendRequest({
      url: `/api/v1/user/refresh-user-session`,
      method: "GET",
    });

    if (data.role === "agency") {
      dispatch({
        type: "REFRESH_LOGIN_SUC",
        payload: data,
      });
    } else if (data.role === "client") {
      dispatch({
        type: "REFRESH_LOGIN_SUC",
        payload: data,
      });
    }
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};

export const refreshUser = (navigate, isFirstLogin) => async (dispatch) => {
  if (isFirstLogin) {
    try {
      dispatch({
        type: "REFRESH_LOGIN_REQ",
      });

      const { data } = await sendRequest({
        url: `/api/v1/user/refresh-user-session`,
        method: "GET",
      });

      /* const { data } = await axios.get(`${ServerBaseURI}/api/v1/user/refresh`, {
        headers: axiosHeader,
      }); */

      if (data.role === "agency") {
        dispatch({
          type: "REFRESH_LOGIN_SUC",
          payload: data,
        });

        navigate(`/agency`);
      } else if (data.role === "client") {
        const dataObj = { client: { ...data } };
        dispatch({
          type: "GET_ORG_DETAILS_SUCCESS",
          payload: dataObj,
        });

        dispatch({
          type: "REFRESH_LOGIN_SUC",
          payload: data,
        });

        navigate(`/client/${data.id}/sme-background`);
      }

      /* dispatch({
        type: "REFRESH_LOGIN_SUC",
      }); */
    } catch (err) {
      const message = errMsg(err);

      if (message === "Authorization Failed : jwt expired") {
        return dispatch(logoutAgency(navigate));
      }

      toast.error(message, {
        toastId: message,
        position: "bottom-center",
      });

      dispatch({
        type: "REFRESH_LOGIN_FAIL",
        payload: { error: true, message },
      });
    }
  }
};

export const logoutAgency = (navigate) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGOUT_USER_REQ",
    });

    const { message } = await logoutApi();

    dispatch({
      type: "LOGOUT_USER_SUCCESS",
    });

    navigate(`/`);

    toast.success(message, {
      toastId: "log-out-succ",
      position: "bottom-center",
    });
  } catch (err) {
    const message = errMsg(err);

    dispatch({
      type: "LOGOUT_USER_FAIL",
      payload: { error: true, message },
    });

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};


export const loginGoogleUser =
  (codeResponse, navigate) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'USER_LOGIN_REQ',
      });
      const data = await loginGoogleApi(codeResponse);

      setToken(data.token);

      dispatch({
        type: 'USER_LOGIN_SUC',
        payload: data,
      });

      if (data.role === 'agency') {
        navigate(`/agency/practice-dashboard`);
      } else if (data.role === 'client') {
        const dataObj = { client: { ...data } };
        dispatch({
          type: 'GET_ORG_DETAILS_SUCCESS',
          payload: dataObj,
        });
        navigate(`/client/${data.id}/sme-background`);
      }

      toast.success(`Welcome back ${data.companyName || data.name}`, {
        toastId: data.id,
        position: 'bottom-center',
      });
    } catch (err) {
      const message = errMsg(err);

      toast.error(message, {
        toastId: message,
        position: 'bottom-center',
      });

      dispatch({
        type: 'USER_LOGIN_FAIL',
        payload: { error: true, message },
      });
    }
  };
