import { useColorModeValue } from "@chakra-ui/react";
import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { bgColorDark, bgColorLight } from "../styles/style";
import { getClientProductsApi } from "../api/organisationApi";

function ProductsDropdown({
  defaultProductValues = [],
  fetchDataFunction,
  placeholder,
  isMulti,
  handleOptionChange,
  styles,
  clientData,
  ...props
}) {
  const selectedSectorCategory = props?.selectedSectorCategory;
  const isEditable = props?.isEditable || false;

  const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [oldSelectedOptions, setOldSelectedOptions] = useState([]);
  const [TotalPageCount, setTotalPageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const pageSize = 20;

  // Function to fetch data from your API
  const fetchData = useCallback(
    async (query, currentPage) => {
      if (!selectedSectorCategory && !isEditable) return [];
      setIsLoading(true);
      try {
        const { data, count } = await fetchDataFunction({
          query: query || "",
          sectorCategory: selectedSectorCategory,
          currentPage: currentPage || 1,
          pageSize,
        });
        return { data, count };
      } catch (error) {
        console.error("Error fetching data:", error);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [inputValue, page, selectedSectorCategory]
  );

  // Function to load more data when the user scrolls to the end
  const loadDataOnScrollToBottom = () => {
    if (!isLoading && page < TotalPageCount) {
      fetchData(inputValue, page + 1).then(({ data }) => {
        // setOptions((prevOptions) => [...data]);
        setOptions((prev) => [...prev, ...data]);
        setPage(page + 1);
      });
    }
  };

  const loadDataOnScrollToTop = () => {
    if (!isLoading && page > 1) {
      fetchData(inputValue, page - 1).then(({ data }) => {
        // setOptions((prevOptions) => [...data]);
        setOptions((prev) => [...prev, ...data]);
        setPage((prevpage) => prevpage - 1);
      });
    }
  };

  // Function to handle input value changes
  const handleInputChange = (newValue) => {
    setInputValue(newValue);
    setPage(1); // Reset page when the input changes
  };

  // Effect to fetch initial data when inputValue or page changes
  useEffect(() => {
    fetchData(inputValue, 1).then((results) => {
      if (results?.data?.length > 0) {
        setOptions(results?.data || []);
        setTotalPageCount(results?.count);
        setPage(1);
      }
    });
  }, [inputValue, selectedSectorCategory]);

  useEffect(() => {
    if (defaultProductValues.length > 0) {
      getClientProductsApi(defaultProductValues).then((results) => {
        if (results?.data?.length > 0) {
          setOldSelectedOptions(results?.data || []);
        }
      });
    }
  }, [defaultProductValues]);

  // Function to handle option selection
  const handleOnChange = (option) => {
    handleOptionChange(option);
    var newOptions = option.map((data)=> {return {
      _id: data.value,
      product: data.label,
    };})
    setOldSelectedOptions(newOptions);
  };

  const backgroundColor = useColorModeValue(bgColorLight, bgColorDark);
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: backgroundColor,
      border: state.isFocused
        ? "2px solid #5856d6"
        : "1px solid rgb(226, 232, 240)",
      boxShadow: "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: backgroundColor,
      border: "1px solid gray",
      boxShadow: "none",
      margin: "0px",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? "blue" : backgroundColor,
      // color: state.isSelected ? "white" : "black",
      "&:hover": {
        backgroundColor: "lightblue",
      },
    }),
  };

  return (
    <div>
      {defaultProductValues.length > 0 && !isEditable ? (
        <Select
          styles={customStyles || styles}
          isLoading={isLoading}
          selections
          isMulti={isMulti}
          placeholder={placeholder || "Search..."}
          value={oldSelectedOptions.map((option) => ({
            value: option?._id,
            label: option?.product,
          }))}
          isDisabled={clientData?.id}
        />
      ) : (
        <Select
          styles={customStyles || styles}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          onMenuScrollToTop={loadDataOnScrollToTop}
          onMenuScrollToBottom={loadDataOnScrollToBottom}
          isLoading={isLoading}
          closeMenuOnSelect={!isMulti} // Allow multiple selections
          isMulti={isMulti}
          selections
          placeholder={placeholder || 'Search...'}
          isClearable
          isSearchable
          options={options.map((option) => ({
            value: option?._id,
            label: option?.product,
          }))}
          value={oldSelectedOptions.map((option) => ({
            value: option?._id,
            label: option?.product,
          }))}
          onChange={handleOnChange}
        />
      )}
    </div>
  );
}

export default ProductsDropdown;
