import Electronics from "../assets/images/Electronics.png";
import FabricatedMetalProducts from "../assets/images/FabricatedMetalProducts.png";
import Papers from "../assets/images/Papers.png";
import FoodAndKindredProducts from "../assets/images/FoodAndKindredProducts.png";
import Rubber from "../assets/images/Rubber.png";
import TextileMillProducts from "../assets/images/TextileMillProducts.png";
import IndustrialAndCommercial from "../assets/images/IndustrialAndCommercial.png";
import Apparel from "../assets/images/Apparel.png";
import MetalIndustries from "../assets/images/MetalIndustries.png";
import Transportation from "../assets/images/Transportation.png";


export const BALANCE_SHEET = "Balance Sheet";
export const PROFIT_LOSS_STATEMENT = "Income Statement";
export const ACTUAL_RESULT = "actualResult";

export const AGENCY_TABS = {
  CLIENT_BACKGROUND: "CLIENT_BACKGROUND",
  SEGMENT_RISK: "SEGMENT_RISK",
  COMPETITIVE_RISK: "COMPETITIVE_RISK",
  RISK_MODELLING: "RISK_MODELLING",
};

export const INDUSTRY_ICONS = {
  "Electronic & Other Electrical Equipment & Components": Electronics,
  "Fabricated Metal Products": FabricatedMetalProducts,
  "Paper and Allied Products": Papers,
  "Food and Kindred Products": FoodAndKindredProducts,
  "Rubber and Miscellaneous Plastic Products": Rubber,
  "Textile Mill Products": TextileMillProducts,
  "Industrial and Commercial Machinery and Computer Equipment":
    IndustrialAndCommercial,
  "Apparel, Finished Products from Fabrics & Similar Materials": Apparel,
  "Primary Metal Industries": MetalIndustries,
  "Transportation Equipment": Transportation,
};
