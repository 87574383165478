import { sendRequest } from "../libs/axiosConfig";

export const uploadAuditApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/auditsheet/uploadauditsheet`,
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

export const getRawAuditSheetsApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/auditsheet/rawAuditSheets`,
    method: 'POST',
    body: { ...reqBody },
  });

  return data;
};
