import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
import React from "react";
import "./common.scss";

const ContactUs = () => {
  return (
    <>
      <Box className="body-container">
        <Flex
          overflow={"auto"}
          h={"full"}
          alignItems={"center"}
          justifyContent={["flex-start", "space-evenly"]}
        >
          <Stack p={"36px"} spacing={5} className="common-stack" minW={"400px"}>
            <Heading textAlign={"center"} m={"60px 0"}>
              Contact Us
            </Heading>

            <FormControl
              width={["xs", "sm", "md", "lg", "xl"]}
              justifyContent={"flex-start"}
              justifyItems={"flex-start"}
            >
              <Flex alignItems={"center"}>
                <FormLabel mb={"0"}>Name: </FormLabel>
                <Input name="name" />
              </Flex>
            </FormControl>

            <FormControl width={["xs", "sm", "md", "lg", "xl"]}>
              <Flex alignItems={"center"}>
                <FormLabel mb={"0"}>Name: </FormLabel>
                <Input name="name" />
              </Flex>
            </FormControl>

            <FormControl width={["xs", "sm", "md", "lg", "xl"]}>
              <Flex alignItems={"center"}>
                <FormLabel mb={"0"}>Name: </FormLabel>
                <Input name="name" />
              </Flex>
            </FormControl>
          </Stack>
        </Flex>
      </Box>
    </>
  );
};

export default ContactUs;
