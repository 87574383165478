import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { Flex } from "@chakra-ui/react";
import MetricsSpectrum from "../../components/MetricsSpectrum/MetricsSpectrum";
import { getClientDetails } from "../../actions/user/agencyActions";

export const MetricsSpectrumComponent = ({
  metricColors,
  patternData,
  currentType,
  currentMetric,
  clientId
}) => {
  const { client } = useSelector((state) => state.clientReducer);
  const { details } = client;
  return (
    <>
      {details?.loading ? (
        <Loader />
      ) : (
        <>
          <Flex justifyContent={"space-around"} alignItems={"center"}>
            <MetricsSpectrum
              currentMetric={currentMetric}
              metricColors={metricColors}
              patternData={patternData}
              currentType={currentType}
              clientDetails={details}
            />
          </Flex>
        </>
      )}
    </>
  );
};
