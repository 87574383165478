import React from "react";
import ReactApexChart from "react-apexcharts";

const StackedBarChart = ({ data, dimension }) => {
  const series = [];

  const keys = Object.keys(data?.[0]?.value || {});
  const years = data.map((item) => item?.year);

  keys?.forEach?.((key) => {
    const newData = data.map((item) => item?.value?.[key]);
    series.push({
      name: key,
      data: newData,
    });
  });

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: false,
      },
      background: "#fff",
    },
    plotOptions: {
      bar: {
        horizontal: false,
      },
    },
    xaxis: {
      categories: years,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return (value / 10000000).toFixed(1) + " Cr";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
      offsetX: 40,
    },
    dataLabels: {
      enabled: false,
    },
  };

  return (
    <div>
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={dimension?.height || 380}
        width={dimension?.width || "100%"}
      />
    </div>
  );
};

export default StackedBarChart;
