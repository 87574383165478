import { ArrowBackIcon, EmailIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { forgotPasswordAgency } from "../../../actions/user/agencyActions";
import Loader from "../../../components/Loader/Loader";

const ForgotPasword = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.userReducer);

  const [data, setData] = useState({
    email: "",
  });

  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };

  const submitHandler = (e) => {
    dispatch(forgotPasswordAgency(data.email, setData));
  };

  return (
    <>
      <Box className="body-container" overflowY={"auto"}>
        <Stack
          className="auth-page"
          justifyContent={"space-evenly"}
          alignItems={"center"}
          minH={"540px"}
          h={"full"}
        >
          <Stack
            w={"fit-content"}
            p={[3]}
            spacing={6}
            borderRadius={"9px"}
            alignItems={"center"}
          >
            <Flex justifyContent={"space-between"}>
              <Link to={"/"}>
                <Button variant={"ghost"}>
                  <ArrowBackIcon fontSize={"30px"} />
                </Button>
              </Link>
              <Heading
                width={"full"}
                textAlign={"center"}
                margin={"0 0 30px 0"}
              >
                Forgot Passsword
              </Heading>
            </Flex>

            <FormControl width={["xs", "sm", "md"]} margin={"auto"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<EmailIcon />}
                />
                <Input
                  name="email"
                  onChange={changeHandler}
                  value={data.email}
                  placeholder="Email"
                  focusBorderColor={"primary.400"}
                  color={"primary.600"}
                />
              </InputGroup>
            </FormControl>

            <Flex justifyContent={"space-evenly"} p={"18px 0 40px 0"}>
              {loading ? (
                "Sending Reset Link"
              ) : (
                <Button variant={"solid"} onClick={submitHandler}>
                  Get Reset Link
                </Button>
              )}
            </Flex>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default ForgotPasword;
