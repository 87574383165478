import { LinkIcon, EmailIcon, UnlockIcon, LockIcon, ViewOffIcon, ViewIcon } from "@chakra-ui/icons";
import { FormErrorMessage, Icon, Text } from "@chakra-ui/react";
import { BsPinMap } from "react-icons/bs";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Stack,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { CgOrganisation } from "react-icons/cg";
import { MdLocationOn } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsList,
  getSectorList,
  registerOrganisation
} from "../../../actions/user/organisationActions";
import Loader from "../../../components/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { getLocationApi, getProductsApi } from "../../../api/organisationApi";
import TextInput from "../../../components/formikElements/TextInput";
import ProductsDropdown from "../../../components/ProductsDropdown";


const OrgRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const [pswdShow, setPswdShow] = useState(false);
  const [cnfPswdShow, setCnfPswdShow] = useState(false);
  const [data, setData] = useState({
    companyName: "",
    email: "",
    password: "",
    cnfPswd: "",
    regdOffice: "",
    website: "",
    logo: "",
    pincode: null,
    products: [],
  });
  const [showState, setShowState] = useState(false);
  const [location, setLocation] = useState({});
  const [customError, setCustomError] = useState({});


  useEffect(() => {
    dispatch(getSectorList());
    dispatch(getProductsList());
  }, [dispatch]);


  const { loading } = useSelector((state) => state.agencyReducer);


  useEffect(() => {
    if (Object.keys(location).length > 0) {
      setShowState(true);
    } else {
      setShowState(false);
    }
  }, [location]);

  const validationSchema = Yup.object({
    companyName: Yup.string().required(),
    email: Yup.string().email(),
    pincode: Yup.number().required(),

    regdOffice: Yup.string(),
    website: Yup.string().url(),
    logo: Yup.string(),
    products: Yup.array()
    .of(Yup.string())
    .min(3, "At least 3 products are required")
    .max(12, "Maximum of 12 products allowed"),
    password: Yup.string()
    .required('Password is required')
    .min(6, 'Password must be at least 6 characters')
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]+$/,
      'Password must contain at least one letter and one number'
    ),

  cnfPswd: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  });

  const validateProducts = (value) => {
    let error;
    if (value.length < 3) {
      error = "At least 3 products are required";
    }
    if (value.length >= 13) {
      error = "Maximum of 12 products allowed";
    }
    return error;
  };

  const validateAndSetLocation = async (e) => {

    const isValidPinCode = /^[0-9]{6}$/.test(e.target.value) && e.target.value;
    try {
      if (isValidPinCode) {
        setCustomError({}); 
        const data = await getLocationApi({ pincode: e.target.value });
        setLocation(data); 
      } else {
        setLocation({});
        setCustomError({ pincode: "pincode is not valid" }); 
      }
    } catch (err) {
      console.error(err); 
      setCustomError({ pincode: "pincode could not be found" });  
    }
  };

  const initialValues = {
    companyName: "",
    email: "",
    password: "",
    cnfPswd:"",
    regdOffice: "",
    website: "",
    logo: "",
    pincode: null,
    products: [],
  };

  const handleProductChange = (event, setFieldValue) => {
    // option Array<{value:id,label:text}>
    const selectedValues = Array.isArray(event)
      ? event.map((item) => item.value)
      : [];
    setFieldValue("products", selectedValues);
  };

  // const submitHandler = () => {
  //   dispatch(registerOrganisation({ ...data }, navigate));
  //   console.log(data)
  // };

  return (
    <>
      <Flex
        className="body-container auth-page"
        justifyContent={"space-evenly"}
      >
        {loading ? (
          <Loader />
        ) : (
          // formik for form validation
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              dispatch(registerOrganisation({ ...values }, navigate));

            }}
            validateOnBlur={true}
          >
            {(formik) => (
              <Stack p={6} width={"full"} maxW={"2160px"} overflowY={"auto"}>
                <Heading textAlign={"center"} margin={"12px 0 48px 0"}>
                  Organisation Registration
                </Heading>

                <Grid templateColumns={["1fr", "0.5fr 0.5fr"]} gap={"6"}>
                  <GridItem>
                    <TextInput
                      name="companyName"
                      placeholder="Enter the name of the company"
                      label="Company Name"
                      isRequired={true}
                      leftElement={<CgOrganisation />}
                      focusBorderColor="primary.400"
                      color="primary.600"
                    ></TextInput>
                  </GridItem>
                  <GridItem>
                    <TextInput
                      name="email"
                      placeholder="Enter the email of your organisation"
                      label="Email Address"
                      leftElement={<EmailIcon />}
                      focusBorderColor="primary.400"
                      color="primary.600"
                    ></TextInput>
                  </GridItem>
                  <GridItem>
                    <TextInput
                      label="Password"
                      isRequired={true}
                      type={pswdShow ? "text" : "password"}
                      name="password"
                      placeholder="Password"
                      leftElement={<UnlockIcon />}
                      rightElement={
                        <Button
                          variant={"ghost"}
                          onClick={() => setPswdShow(!pswdShow)}
                        >
                          {pswdShow ? <ViewOffIcon /> : <ViewIcon />}
                        </Button>
                      }
                      focusBorderColor="primary.400"
                      color="primary.600"
                    />
                  </GridItem>

                  <GridItem>
                    <TextInput
                      label="Confirm Password"
                      isRequired={true}
                      type={cnfPswdShow ? "text" : "password"}
                      name="cnfPswd"
                      placeholder="Confirm Password"
                      leftElement={<LockIcon />}
                      rightElement={
                        <Button
                          variant={"ghost"}
                          onClick={() => setCnfPswdShow(!cnfPswdShow)}
                        >
                          {cnfPswdShow ? <ViewOffIcon /> : <ViewIcon />}
                        </Button>
                      }
                      focusBorderColor="primary.400"
                      color="primary.600"
                    />
                  </GridItem>
                  <GridItem>
                    <TextInput
                      name="pincode"
                      placeholder="Enter the pincode of company location"
                      label="Pin Code"
                      isRequired={true}
                      leftElement={<Icon as={BsPinMap} />}
                      error={customError?.pincode || null}
                      onBlurCallback={(e) => {
                        // Call your function to validate the Pin Code and set showState
                        validateAndSetLocation(e);
                      }}
                      focusBorderColor="primary.400"
                      color="primary.600"
                    ></TextInput>
                  </GridItem>
                  {showState && (
                    <GridItem>
                      <FormControl maxWidth={"md"} margin={"auto"}>
                        <FormLabel>District</FormLabel>
                        <Input
                          value={location.district}
                          readOnly
                          focusBorderColor="primary.400"
                          // color="primary.600"
                        ></Input>
                      </FormControl>
                    </GridItem>
                  )}
                  {showState && (
                    <GridItem>
                      <FormControl maxWidth={"md"} margin={"auto"}>
                        <FormLabel>State</FormLabel>
                        <Input
                          value={location.state}
                          readOnly
                          focusBorderColor="primary.400"
                          // color="primary.600"
                        ></Input>
                      </FormControl>
                    </GridItem>
                  )}

                  <GridItem>
                    <Field name="products" validate={validateProducts}>
                      {({ field, form }) => (
                        <FormControl
                          maxWidth={"md"}
                          margin={"auto"}
                          isInvalid={
                            form.errors.products && form.touched.products
                          }
                        >
                          <FormLabel>
                            Products <span style={{ color: "#E53E3E" }}>*</span>
                          </FormLabel>

                          <ProductsDropdown
                            fetchDataFunction={getProductsApi}
                            placeholder="Search Products..."
                            isMulti
                            handleOptionChange={(selectedOptions) => {
                              handleProductChange(
                                selectedOptions,
                                form.setFieldValue
                              );
                            }}
                          />
                          {form.errors.products && form.touched.products && (
                            <FormErrorMessage name="products">
                              {form.errors.products}
                            </FormErrorMessage>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </GridItem>

                  <GridItem>
                    <TextInput
                      name="regdOffice"
                      placeholder="Enter the registered address of the company"
                      label="Registered Office Address"
                      leftElement={<MdLocationOn size={"20px"} />}
                      focusBorderColor="primary.400"
                      color="primary.600"
                    ></TextInput>
                  </GridItem>

                  <GridItem>
                    <TextInput
                      name="website"
                      placeholder="Enter the URL of the company's website"
                      label="Website URL"
                      leftElement={<LinkIcon />}
                      focusBorderColor="primary.400"
                      color="primary.600"
                    ></TextInput>
                  </GridItem>
                  <GridItem>
                    <TextInput
                      name="logo"
                      placeholder="Upload file"
                      type="file"
                      label="Company Logo"
                      leftElement={<LinkIcon />}
                      focusBorderColor="primary.400"
                      color="primary.600"
                    ></TextInput>
                  </GridItem>
                </Grid>

                <Flex justifyContent={"space-evenly"} p={"36px 0 0 0"}>
                  <Button
                    variant={"solid"}
                    m={"0 12px"}
                    onClick={formik.handleSubmit}
                  >
                    Submit
                  </Button>
                </Flex>
                <Flex justifyContent={"space-evenly"} p={"36px 0 36px 0"}>
                  <Text>
                    Already have an account?{" "}
                    <Link to={"/organisation/login"}>
                      <Button variant={"link"}>Login</Button>
                    </Link>
                  </Text>
                </Flex>
              </Stack>
            )}
          </Formik>
        )}
      </Flex>
    </>
  );
};

export default OrgRegister;
