export const allUnits = [
  {
    name: "Thousands",
    value: 1000,
  },
  {
    name: "Lakh",
    value: 100000,
  },
  {
    name: "Crores",
    value: 10000000,
  },
];
