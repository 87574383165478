import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  clientsData: [],
  clientDetails: {
    loading: false,
    error: null,
    clientDetails: null,
  },
};

const agencyReducer = createReducer(initialState, {
  CREATE_AGENCY_REQ: (state, actions) => {
    state.loading = true;
  },
  CREATE_AGENCY_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },

  GET_REGISTERED_CLIENTS_DATA_REQ: (state, actions) => {
    state.loading = true;
  },
  GET_REGISTERED_CLIENTS_DATA_SUC: (state, actions) => {
    state.loading = false;
    state.clientsData = actions.payload;
    state.error = false;
  },
  GET_REGISTERED_CLIENTS_DATA_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },

  GET_CLIENT_DETAILS_REQ: (state) => {
    state.clientDetails.loading = true;
  },
  GET_CLIENT_DETAILS_SUC: (state, action) => {
    state.clientDetails.loading = false;
    state.clientDetails.clientDetails = action.payload;
  },
  GET_CLIENT_DETAILS_FAIL: (state, payload) => {
    state.clientDetails.loading = false;
    state.clientDetails.error = payload;
  }
});

export default agencyReducer;
