import { toast } from "react-toastify";
import {
  getPopularSectorsResultApi,
  getRegionWiseResultApi,
  getSectorWiseResultApi,
} from "../../api/kpiApi";
import { errMsg } from "../../utils/helperFunction";
import { getAllSectorsResultApi } from "../../api/homeApi";

export const getRegionWiseResult = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_REGION_WISE_RESULT_REQ",
    });

    const data = await getRegionWiseResultApi();

    dispatch({
      type: "GET_REGION_WISE_RESULT_SUC",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });

    dispatch({
      type: "GET_REGION_WISE_RESULT_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getsectorWiseResult = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SECTOR_WISE_RESULT_REQ",
    });

    const data = await getSectorWiseResultApi();

    dispatch({
      type: "GET_SECTOR_WISE_RESULT_SUC",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });

    dispatch({
      type: "GET_SECTOR_WISE_RESULT_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getPopularSectorsResult = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_POPULAR_SECTORS_REQ",
    });

    const data = await getPopularSectorsResultApi();

    dispatch({
      type: "GET_POPULAR_SECTORS_SUC",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });

    dispatch({
      type: "GET_POPULAR_SECTORS_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getAllSectorsResult = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ALL_SECTORS_REQ",
    });

    const data = await getAllSectorsResultApi();

    dispatch({
      type: "GET_ALL_SECTORS_SUC",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });

    dispatch({
      type: "GET_ALL_SECTORS_FAIL",
      payload: { error: true, message },
    });
  }
};
