import { useSelector } from "react-redux";
import { Link as ReactRouterLink } from "react-router-dom";
import { Box, VStack, Link as ChakraLink } from "@chakra-ui/react";

const ClientSidebar = () => {
  const { user } = useSelector((state) => state.userReducer);

  return (
    <>
      <Box
        pt={"45px"}
        px={5}
        overflow={"auto"}
        maxHeight={"calc(100vh - 170px)"}
      >
        <VStack align="start" className="left-menu" spacing="2">
          <ChakraLink
            py="3"
            width="100%"
            fontWeight="600"
            borderBottom="2px"
            borderColor="white"
            mt="0"
            textDecoration="none"
            as={ReactRouterLink}
            to={`/client/${user.id}/sme-background`}
          >
            SME Dashboard
          </ChakraLink>
          <ChakraLink
            py="3"
            width="100%"
            fontWeight="600"
            borderBottom="2px"
            borderColor="white"
            mt="0"
            textDecoration="none"
            as={ReactRouterLink}
            to={`/client/${user.id}/sme-industry-activity`}
          >
            Systematic factors
          </ChakraLink>
          {user?.isDashboardPurchased && (
            <ChakraLink
              py="3"
              width="100%"
              fontWeight="600"
              borderBottom="2px"
              borderColor="white"
              mt="0"
              textDecoration="none"
              as={ReactRouterLink}
              to={`/client/${user.id}/sme-risk-assessment`}
            >
              Unsystematic factors
            </ChakraLink>
          )}
        </VStack>
      </Box>
    </>
  );
};

export default ClientSidebar;
