import axios from "axios";
import { getToken } from "../utils/helperFunction";

export async function sendRequest(paramObj) {
  // base URI
  const ServerBaseURI = process.env.REACT_APP_URI_SERVER_BASE_URL;
  const token = getToken();
  const axiosHeader = {
    "Content-Type": "application/json",
    ...(token ? { authorization: `Bearer ${token}` } : {}),
  };
  const {
    method = "GET", // Default to GET method if not specified
    url,
    body = null,
    headers = axiosHeader,
    ...rest
  } = paramObj;
  const reqBody = {
    method,
    url: `${ServerBaseURI}${url}`,
    data: { ...body },
    headers: { ...axiosHeader, ...headers },
    ...rest,
  };
  try {
    const response = await axios(reqBody);

    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}
