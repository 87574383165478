import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  regionWiseSection: {
    loading: false,
    error: null,
    data: null,
  },
  sectorWiseSection: {
    loading: false,
    error: null,
    data: null,
  },
  popularSectors: {
    loading: false,
    error: null,
    data: [],
  },
  allSectors: {
    loading: false,
    error: null,
    data: [],
  }
};

const homeReducer = createReducer(initialState, {
  GET_REGION_WISE_RESULT_REQ: (state, actions) => {
    state.regionWiseSection.loading = true;
  },
  GET_REGION_WISE_RESULT_SUC: (state, actions) => {
    state.regionWiseSection.loading = false;
    state.regionWiseSection.data = actions.payload;
  },
  GET_REGION_WISE_RESULT_FAIL: (state, actions) => {
    state.sectorWiseSection.loading = false;
    state.sectorWiseSection.error = actions.payload;
  },
  GET_SECTOR_WISE_RESULT_REQ: (state, actions) => {
    state.sectorWiseSection.loading = true;
  },
  GET_SECTOR_WISE_RESULT_SUC: (state, actions) => {
    state.sectorWiseSection.loading = false;
    state.sectorWiseSection.data = actions.payload;
  },
  GET_SECTOR_WISE_RESULT_FAIL: (state, actions) => {
    state.sectorWiseSection.loading = false;
    state.sectorWiseSection.error = actions.payload;
  },
  GET_POPULAR_SECTORS_REQ: (state) => {
    state.popularSectors.loading = true;
  },
  GET_POPULAR_SECTORS_SUC: (state, actions) => {
    state.popularSectors.loading = false;
    state.popularSectors.data = actions.payload;
  },
  GET_POPULAR_SECTORS_FAIL: (state, actions) => {
    state.popularSectors.loading = false;
    state.popularSectors.error = actions.payload;
  },

  GET_ALL_SECTORS_REQ: (state) => {
    state.allSectors.loading = true;
  },
  GET_ALL_SECTORS_SUC: (state, actions) => {
    state.allSectors.loading = false;
    state.allSectors.data = actions.payload;
  },
  GET_ALL_SECTORS_FAIL: (state, actions) => {
    state.allSectors.loading = false;
    state.allSectors.error = actions.payload;
  },
});

export default homeReducer;
