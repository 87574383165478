import { Box, Stack } from "@chakra-ui/react";
import StackedBarChart from "../StackedBarChart";

export default function SystematicFactorsStackedBarChart({ data, heading, dimension }) {
  return (
    <Stack className="sylvr-graph-card">
      <Box className="sylvr-graph-card-heading">
        <Box className="sylvr-graph-card-heading-label">{heading}</Box>
      </Box>
      <Box>
        <StackedBarChart data={data} dimension={dimension} />
      </Box>
    </Stack>
  );
}
