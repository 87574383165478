import { Box, Flex, Heading } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import InterpretationModal from "./InterpretationModal";
import { getRechartContainerWidth } from "../../../../utils/common";
import { getPerformanceMatrixHoverText } from "../../../../utils/organisationDashboard";
import { ResponsiveContainer } from "recharts";

const PerformanceMatrix = ({ displayLanguage = "english" }) => {
  const dispatch = useDispatch();
  const [isInterpretationMeodalOpen, setIsInterpretationModalOpen] =
    useState(false);
  const [interpretationModalText, setInterpretationModalText] = useState([]);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [varianceData, setVarianceData] = useState(null);
  const { windowSize } = useSelector((state) => state.uiReducer);

  const [data, setData] = useState(null);

  const { organisationData:{zScore,trend, client} } = useSelector(
    (state) => state.organisationReducer
  );

  useEffect(() => {
    
    if (client && zScore?.data?.length>0 && trend?.data) {
      const years = [...client?.auditUploadYears];
      const currentYear = years?.sort((a, b) => b - a)[0];
      const currentYearData = zScore?.data?.filter((item) => {
        if(Number(item?.year) === Number(currentYear)) {
         return true
        }
        return false
     })
     const varianceData = currentYearData[0]?.metrics;
     setVarianceData(varianceData);

     const result = {
       "Very High": {
         falling: [],
         stagnant: [],
         rising: [],
       },
       High: {
         falling: [],
         stagnant: [],
         rising: [],
       },
       Moderate: {
         falling: [],
         stagnant: [],
         rising: [],
       },
       Low: {
         falling: [],
         stagnant: [],
         rising: [],
       },
       "Very Low": {
         falling: [],
         stagnant: [],
         rising: [],
       },
     };
     for (const variable in varianceData) {
       const zscoreLabel = varianceData[variable].label;
       const trendCategory = trend?.data[variable] || ""; 
       result[zscoreLabel][trendCategory]?.push(variable);
     }
     setData({...result});
    }
  }, [dispatch, zScore, trend, client]);

  // useEffect(()=>{
  //   // console.log(data)
  // },[data])

  const handleMouseOver = (e) => {
    setMousePosition({ x: e.clientX + 10, y: e.clientY + 15 });

    const inputString = e.target.innerText;
    // convert the 1st letter to lowercase before accessing situation and trend
    const key = inputString.charAt(0).toLowerCase() + inputString.slice(1);
    const situation = varianceData[key].label;
    const trendOfKey = trend?.data[key];

    // call asset function here
    setInterpretationModalText(
      getPerformanceMatrixHoverText(
        displayLanguage,
        key,
        situation,
        trendOfKey
      )
    );
    setIsInterpretationModalOpen(true);
  };

  const handleMouseOut = (e) => {
    setIsInterpretationModalOpen(false);
  };

  if(!data){
    return (
      <ResponsiveContainer
        width={getRechartContainerWidth(windowSize.innerWidth)}
        aspect={1.2}
        textAlign={"center"}
      >
        <Box
          bg="#B3A7FA1A"
          w="100%"
          p={4}
          color="white"
          display="flex"
          alignItems="center"
        >
          <Heading
            color={"primary.500"}
            w={"full"}
            size={"md"}
            textAlign={"center"}
          >
            No data available
          </Heading>
        </Box>
      </ResponsiveContainer>
    );
  }else{
    return (
      <>
        {/* {console.log(displayLanguage)} */}
        <table id="resource-matrix-table">
          <thead>
            <tr>
              <th>Trend/Situation</th>
              <th>Falling</th>
              <th>Stagnant</th>
              <th>Rising</th>
            </tr>
          </thead>
  
          <tbody>
            <tr>
              <th>Very high</th>
              <td>
                <Flex>
                  {data?.["Very High"]?.falling.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-very-high-falling"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.["Very High"]?.stagnant.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-very-high-stagnant"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.["Very High"]?.rising.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-very-high-rising"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
            <tr>
              <th>High</th>
              <td>
                <Flex>
                  {data?.High?.falling.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-high-falling"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.High?.stagnant.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-high-stagnant"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.High?.rising.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-high-rising"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
            <tr>
              <th>Moderate</th>
              <td>
                <Flex>
                  {data?.Moderate?.falling.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-medium-falling"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.Moderate?.stagnant.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-medium-stagnant"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.Moderate?.rising.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-medium-rising"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
            <tr>
              <th>Low</th>
              <td>
                <Flex>
                  {data?.Low?.falling.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-low-falling"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.Low?.stagnant.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-low-stagnant"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.Low?.rising.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-low-rising"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
            <tr>
              <th>Very Low</th>
              <td>
                <Flex>
                  {data?.["Very Low"]?.falling.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-very-low-falling"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.["Very Low"]?.stagnant.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-very-low-stagnant"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
              <td>
                <Flex>
                  {data?.["Very Low"]?.rising.map((item, index) => (
                    <Box
                      className="res-mat-strip res-mat-very-low-rising"
                      key={index}
                      onMouseEnter={handleMouseOver}
                      onMouseOut={handleMouseOut}
                    >
                      {item}
                    </Box>
                  ))}
                </Flex>
              </td>
            </tr>
          </tbody>
        </table>
  
        {isInterpretationMeodalOpen && (
          <InterpretationModal
            position={mousePosition}
            text={interpretationModalText}
          />
        )}
      </>
    );
  }

 
};

export default PerformanceMatrix;
