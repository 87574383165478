import React from "react";
import ReactApexChart from "react-apexcharts";

const LineChart = ({ data, dimension }) => {
  const series = [];

  const keys = Object.keys(data?.[0]?.value || {});
  const years = data.map((item) => item?.year);

  keys?.forEach?.((key) => {
    const newData = data.map((item) => item?.value?.[key]);
    series.push({
      name: key,
      data: newData,
    });
  });

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      background: "#fff",
    },
    xaxis: {
      categories: years,
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return (value / 10000000).toFixed(1) + " Cr";
        },
      },
    },
  };

  /* const series = [
    {
      name: "Series 1",
      data: data,
    },
  ]; */

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height={dimension?.height || 380}
      width={dimension?.width || "100%"}
    />
  );
};

export default LineChart;
