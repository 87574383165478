import { useSelector } from "react-redux";
import { createContext, useContext, useState } from "react";

const PaymentContext = createContext(null);

export default function PaymentContextProvider({ children }) {
  const [isShowPaymentModal, setIsShowPaymentModal] = useState(false);

  const showPaymentModal = () => {
    setIsShowPaymentModal(true);
  };

  const hidePaymentModal = () => {
    setIsShowPaymentModal(false);
  };

  const providerValue = {
    isShowPaymentModal,
    showPaymentModal,
    hidePaymentModal,
  };

  return (
    <PaymentContext.Provider value={providerValue}>
      {children}
    </PaymentContext.Provider>
  );
}

export const usePayment = () => useContext(PaymentContext);
