import {
  EmailIcon,
  LockIcon,
  UnlockIcon,
  ViewIcon,
  ViewOffIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
  Text,
} from "@chakra-ui/react";
import { HiUserCircle } from "react-icons/hi";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerAgency } from "../../../actions/user/agencyActions";
import Loader from "../../../components/Loader/Loader";
import { toast } from "react-toastify";

const Register = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    pswd: "",
    cnfPswd: "",
    avatar: "",
  });

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.userReducer);

  const [pswdShow, setPswdShow] = useState(false);
  const [cnfPswdShow, setCnfPswdShow] = useState(false);

  const changeHandler = (e) => {
    const name = e.target.name;
    let value = e.target.value;

    if (name === "avatar") {
      value = e.target.files[0];
    }

    setData({ ...data, [name]: value });
  };

  const submitHandler = async (e) => {
    // console.log(data);

    if (data.pswd === data.cnfPswd) {
      dispatch(registerAgency({ ...data }, navigate));
    } else {
      toast.error("Password and confirm password must be same", {
        position: "bottom-center",
        toastId: "pswd-mismatch",
      });
    }
  };
  return (
    <>
      <Flex className="body-container" justifyContent={"space-evenly"}>
        {loading ? (
          <Loader />
        ) : (
          <Stack p={6} width={"full"} maxW={"2160px"} overflowY={"auto"}>
            <Heading textAlign={"center"} margin={"60px 0 48px 0"}>
              Practice Registration
            </Heading>

            <Stack alignItems={"center"}>
              <FormControl maxWidth={"md"} margin={"auto"}>
                <FormLabel>Name:</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<HiUserCircle size={22} />}
                  />
                  <Input
                    name="name"
                    onChange={changeHandler}
                    value={data.name}
                    placeholder="Enter your name"
                    focusBorderColor={"primary.400"}
                    color={"primary.600"}
                  />
                </InputGroup>
              </FormControl>

              <FormControl maxWidth={"md"} margin={"auto"}>
                <FormLabel>Email Address:</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<EmailIcon />}
                  />
                  <Input
                    name="email"
                    onChange={changeHandler}
                    value={data.email}
                    placeholder="Enter your Email"
                    focusBorderColor={"primary.400"}
                    color={"primary.600"}
                  />
                </InputGroup>
              </FormControl>

              <FormControl maxWidth={"md"} margin={"auto"}>
                <FormLabel>Password:</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<UnlockIcon />}
                  />
                  <Input
                    type={pswdShow ? "text" : "password"}
                    name="pswd"
                    onChange={changeHandler}
                    value={data.pswd}
                    placeholder="Password"
                    focusBorderColor={"primary.400"}
                    color={"primary.600"}
                  />
                  <InputRightElement>
                    <Button
                      variant={"ghost"}
                      onClick={() => setPswdShow(!pswdShow)}
                    >
                      {pswdShow ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <FormControl maxWidth={"md"} margin={"auto"}>
                <FormLabel>Confirm Password:</FormLabel>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<LockIcon />}
                  />
                  <Input
                    type={cnfPswdShow ? "text" : "password"}
                    name="cnfPswd"
                    onChange={changeHandler}
                    value={data.cnfPswd}
                    placeholder="Confirm Password"
                    focusBorderColor={"primary.400"}
                    color={"primary.600"}
                  />
                  <InputRightElement>
                    <Button
                      variant={"ghost"}
                      onClick={() => setCnfPswdShow(!cnfPswdShow)}
                    >
                      {cnfPswdShow ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <FormControl maxWidth={"md"} margin={"auto"}>
                <FormLabel>Avatar:</FormLabel>
                <InputGroup>
                  <Input
                    type={"file"}
                    accept="image/*"
                    name="avatar"
                    onChange={changeHandler}
                    focusBorderColor={"primary.400"}
                    color={"primary.600"}
                  />
                </InputGroup>
              </FormControl>
            </Stack>

            <Flex justifyContent={"space-evenly"} p={"36px 0 36px 0"}>
              <Button variant={"solid"} onClick={submitHandler}>
                Submit
              </Button>
            </Flex>

            <Flex justifyContent={"space-evenly"} p={"36px 0 36px 0"}>
              <Text>
                Already have an account?{" "}
                <Link to={"/"}>
                  <Button variant={"link"}>Login</Button>
                </Link>
              </Text>
            </Flex>
          </Stack>
        )}
      </Flex>
    </>
  );
};

export default Register;
