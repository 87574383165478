import { Box, Button, Flex, Heading, Stack } from "@chakra-ui/react";
import React from "react";
import AnimatedCheck from "../../components/AnimatedCheck/AnimatedCheck";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const AuditUploadSuccess = () => {
  const { type, user } = useSelector((state) => state.userReducer);

  const homePageLink = {
    agency: `/agency/clients`,
    organisation: `/organisation/${user._id}`,
  };

  return (
    <>
      <Stack className="body-container" justifyContent={"center"}>
        <Box height={"200px"}>
          <AnimatedCheck />
        </Box>

        <Heading textAlign={"center"} size={"lg"}>
          Your sheet has been uploaded successfully
        </Heading>

        <Flex justifyContent={"space-around"}>
          <Link to={homePageLink[type]}>
            {type === "agency" && <Button>Manage Clients</Button>}
            {type === "organisation" && <Button>Go to Dashboard</Button>}
          </Link>
        </Flex>
      </Stack>
    </>
  );
};

export default AuditUploadSuccess;
