import { Box, Menu, MenuItem } from "@chakra-ui/react";

const AgencyRightSidebar = () => {
  return (
    <Box className="rightbar">
      <Menu>
        <MenuItem className="rightbar-heading">Registers</MenuItem>
        <MenuItem>Clustering</MenuItem>
      </Menu>
    </Box>
  );
};

export default AgencyRightSidebar;
