import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Label,
  Bar,
  Cell,
  ReferenceLine,
} from "recharts";
import {
  Flex,
  FormLabel,
  Heading,
  Box,
  Button,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import { getRechartContainerWidth } from "../../utils/common";
import YearSelection from "../YearSelection";
import { COLORS, TYPES_OF_METRICS } from "../../constants/metrics";
import {
  getAuditSheetDataForClient,
  getCompetitiveMapDataForClient,
  getTrendsForClient,
  getZScoreForClient,
} from "../../actions/user/organisationActions";

const MetricsSpectrum = ({
  currentMetric,
  metricColors,
  patternData,
  currentType,
  clientDetails,
}) => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.clientReducer);
  const { details, zScore } = client;

  const { loading, organisationData } = useSelector(
    (state) => state.organisationReducer
  );
  const { windowSize } = useSelector((state) => state.uiReducer);

  const [data, setData] = useState("");
  const [sortedData, setSortedData] = useState("");
  const [years, setYears] = useState([]);
  let lastYearIndex = details?.data?.auditUploadYears.length;
  const [selectedYear, setSelectedYear] = useState(
    details?.data?.auditUploadYears?.[lastYearIndex - 1]
  );
  const [currentTypeValues, setCurrentTypeValues] = useState([]);

  useEffect(() => {
    if (details?.data) {
      setYears(details?.data?.auditUploadYears);
    }
  }, [dispatch, details]);

  useEffect(() => {
    if (
      details?.data?.auditUploadYears?.length > 0 &&
      zScore?.data &&
      zScore?.data.length > 0
    ) {
      const yearData = zScore?.data?.filter((item) => {
        if (Number(item?.year) === Number(selectedYear)) {
          return true;
        }
        return false;
      });
      const {
        revenueGrowth,
        ebitda,
        materials,
        employees,
        overhead,
        intTaxDep,
        arDays,
        dpo,
        dii,
        roE,
        roCE,
        roA,
        timesInterestEarned,
        debtRatio,
      } = yearData[0].metrics;

      const currentTypeValues = TYPES_OF_METRICS?.[currentType]?.value;
      setCurrentTypeValues(currentTypeValues);

      const datas = [
        {
          lab: "revenueGrowth",
          name: "revenue growth",
          value: revenueGrowth?.zscore,
          tag: revenueGrowth?.label,
          mean: revenueGrowth?.mean,
          stdDev: revenueGrowth?.stdDev,
        },
        {
          lab: "ebitda",
          name: "ebitda",
          value: ebitda?.zscore,
          tag: ebitda?.label,
          mean: ebitda?.mean,
          stdDev: ebitda?.stdDev,
        },
        {
          lab: "materials",
          name: "materials",
          value: materials?.zscore,
          tag: materials?.label,
          mean: materials?.mean,
          stdDev: materials?.stdDev,
        },
        {
          lab: "employees",
          name: "employees",
          value: employees?.zscore,
          tag: employees?.label,
          mean: employees?.mean,
          stdDev: employees?.stdDev,
        },
        {
          lab: "overhead",
          name: "Overhead",
          value: overhead?.zscore,
          tag: overhead?.label,
          mean: overhead?.mean,
          stdDev: overhead?.stdDev,
        },
        {
          lab: "intTaxDep",
          name: "int. Tax Depreciation",
          value: intTaxDep?.zscore,
          tag: intTaxDep?.label,
          mean: intTaxDep?.mean,
          stdDev: intTaxDep?.stdDev,
        },
        {
          lab: "arDays",
          name: "arDays",
          value: arDays?.zscore,
          tag: arDays?.label,
          mean: arDays?.mean,
          stdDev: arDays?.stdDev,
        },
        {
          lab: "dpo",
          name: "dpo",
          value: dpo?.zscore,
          tag: dpo?.label,
          mean: dpo?.mean,
          stdDev: dpo?.stdDev,
        },
        {
          lab: "dii",
          name: "dii",
          value: dii?.zscore,
          tag: dii?.label,
          mean: dii?.mean,
          stdDev: dii?.stdDev,
        },
        {
          lab: "roE",
          name: "roE",
          value: roE?.zscore,
          tag: roE?.label,
          mean: roE?.mean,
          stdDev: roE?.stdDev,
        },
        {
          lab: "roCE",
          name: "roCE",
          value: roCE?.zscore,
          tag: roCE?.label,
          mean: roCE?.mean,
          stdDev: roCE?.stdDev,
        },
        {
          lab: "roA",
          name: "roA",
          value: roA?.zscore,
          tag: roA?.label,
          mean: roA?.mean,
          stdDev: roA?.stdDev,
        },
        {
          lab: "timesInterestEarned",
          name: "Times Interest Earned",
          value: timesInterestEarned?.zscore,
          tag: timesInterestEarned?.label,
          mean: timesInterestEarned?.mean,
          stdDev: timesInterestEarned?.stdDev,
        },
        {
          lab: "debtRatio",
          name: "Debt Ratio",
          value: debtRatio?.zscore,
          tag: debtRatio?.label,
          mean: debtRatio?.mean,
          stdDev: debtRatio?.stdDev,
        },
      ];

      setData(
        datas
          .filter((val) => {
            if (currentTypeValues.find((t) => t.label === val.lab)) {
              return true;
            }
            return false;
          })
          .map((val) => {
            if (
              TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                (m) => m?.label === val?.lab
              )
            ) {
              return { ...val, name: `${val?.name} %` };
            }

            return val;
          })
      );
    }
  }, [dispatch, zScore, selectedYear, currentType]);

  useEffect(() => {
    data && setSortedData(data.sort((a, b) => b.value - a.value));
  }, [dispatch, data]);

  // custom tooltip for metric spectrum chart
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      const tooltip = payload?.[0]?.payload;
      return (
        <div className="metric-spectrum custom-tooltip">
          <p
            className="label"
            style={{ color: "rgb(127, 127, 127)" }}
          >{`label: ${label}`}</p>
          <p className="value">{`value: ${tooltip?.value?.toFixed?.(3)}`}</p>
          <p>{`mean: ${tooltip?.mean?.toFixed?.(3)}`}</p>
          <p>{`stdDev: ${tooltip?.stdDev?.toFixed?.(3)}`}</p>
          <p>{`situation: ${tooltip?.tag}`}</p>
          {/* Add more content here */}
        </div>
      );
    }

    return null;
  };

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };
  return (
    <div style={{ width: "100%" }}>
      <VStack
        py={5}
        px={3}
        display="flex"
        alignItems="start"
        justifyContent="space-between"
      >
        <Text textTransform="uppercase" fontWeight={600} fontSize={18}>
          VARIANCE
        </Text>

        <YearSelection
          years={years}
          selectedYear={selectedYear}
          onSelectYear={handleYearChange}
        />
      </VStack>

      <Box /* padding={"15px"} border={"2px solid #8F8F8F"} mt={"10"} */>
        <Stack>
          {!data && zScore?.error ? (
            <ResponsiveContainer
              width={getRechartContainerWidth(windowSize.innerWidth)}
              aspect={1.2}
              textAlign={"center"}
            >
              <Box
                bg="#B3A7FA1A"
                w="100%"
                p={4}
                color="white"
                display="flex"
                alignItems="center"
              >
                <Heading
                  color={"primary.500"}
                  w={"full"}
                  size={"md"}
                  textAlign={"center"}
                >
                  {zScore?.error?.message}
                </Heading>
              </Box>
            </ResponsiveContainer>
          ) : (
            <>
              <ResponsiveContainer width="100%" aspect={1.1}>
                <BarChart
                  data={sortedData}
                  layout="vertical"
                  maxBarSize={18}
                  margin={{
                    top: 15,
                    right: 20,
                    left: 60,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray={"0.1 0.1"} />
                  <XAxis type="number" dataKey={"value"} orientation="top">
                    <Label
                      value="Z- Score"
                      offset={25}
                      position="insideBottom"
                    />
                  </XAxis>
                  <YAxis
                    type="category"
                    dataKey={"name"}
                    orientation="left"
                    label={{
                      // value: "Particulars for evaluation",
                      angle: -90,
                      position: "insideLeft",
                      offset: 110,
                      textAnchor: "middle",
                    }}
                    domain={
                      currentTypeValues /* [
                      "ebitda",
                      "materials",
                      "employees",
                      "Overhead",
                      "int. Tax Depreciation",
                      "Times Interest Earned",
                      "Debt Ratio",
                      "roE",
                      "roCE",
                      "roA",
                      "dii",
                      "dpo",
                      "Revenue Growth",
                      "ar days",
                    ] */
                    }
                  />
                  <Tooltip content={<CustomTooltip />} />
                  {/* <Legend /> */}
                  {/* <ReferenceLine x={0} stroke="#000" /> */}
                  <Bar dataKey="value">
                    {sortedData &&
                      sortedData.map((entry, index) => {
                        return (
                          <Cell
                            key={index}
                            fill={
                              entry?.lab === currentMetric
                                ? metricColors?.[currentMetric]?.textColor ||
                                  "#30D5C8"
                                : "#30D5C8"
                            }
                          />
                        );
                      })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </>
          )}
        </Stack>
      </Box>
    </div>
  );
};

export default MetricsSpectrum;
