import { Box, UnorderedList, Text, ListItem, Link } from "@chakra-ui/react";

export default function Footer() {
  return (
    <>
      <Box
        background="#E6E6E6"
        // py="4"
        px={10}
        display="flex"
        justifyContent="space-between"
        borderTop="1px solid rgba(0,0,0,0.1)"
        position={"fixed"}
      >
        <Text fontSize="14px" opacity={0.7} color="#060606" fontWeight={500}>
          SYLVR is owned by 50EEN Technologies Private Limited
        </Text>
        <UnorderedList display="flex" listStyleType="none">
          <ListItem fontSize="14px" color="#060606" opacity="0.8" mx={3}>
            <Link
              href="https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/terms"
              target="_blank"
            >
              Terms and Conditions
            </Link>
          </ListItem>
          <ListItem fontSize="14px" color="#060606" opacity="0.8" mx={3}>
            <Link
              href="https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/contact_us"
              target="_blank"
            >
              Contact Us
            </Link>
          </ListItem>
          <ListItem fontSize="14px" color="#060606" opacity="0.8" mx={3}>
            <Link
              href="https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/refund"
              target="_blank"
            >
              Cancellations and Refund Policy
            </Link>
          </ListItem>
          <ListItem fontSize="14px" color="#060606" opacity="0.8" mx={3}>
            <Link
              href="https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/privacy"
              target="_blank"
            >
              Privacy Policy
            </Link>
          </ListItem>
          <ListItem fontSize="14px" color="#060606" opacity="0.8" mx={3}>
            <Link
              href="https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/shipping"
              target="_blank"
            >
              Shipping and Delivery
            </Link>
          </ListItem>
        </UnorderedList>
      </Box>
    </>
  );
}
