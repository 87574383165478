import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Text,
  Grid,
  GridItem,
  Img,
  Heading,
  ListItem,
  List,
} from "@chakra-ui/react";
import bullet from "../../../assets/images/bullet.png";

import Loader from "../../../components/Loader/Loader";
import {
  getAuditSheetDataForClient,
  getCompetitiveMapData,
  getCompetitiveMapDataForClient,
  getTrendsForClient,
  getZScoreForClient,
} from "../../../actions/user/organisationActions";
// import downarrowmenu from "../../../assets/images/arrow-down.png";
// import uparrowmenu from "../../../assets/images/arrow-up.png";

import { COLORS, TYPES_OF_COMPETATIVE_METRICS, TYPES_OF_METRICS, TYPES_OF_SEGMENT_METRICS } from "../../../constants/metrics";
import AdvisoryNotes from "../../../components/AdvisoryNotes/AdvisoryNotes";
import { PerformanceMatrixComponent } from "../../../shared/CompetitiveRisk/PerformanceMatrixComponent";
import { CompetitiveMapComponent } from "../../../shared/CompetitiveRisk/CompetitiveMapComponent";
import { MetricsSpectrumComponent } from "../../../shared/CompetitiveRisk/MetricsSpectrumComponent";
import UnlockRegistration from "../../../shared/UnlockRegistration/UnlockRegistration";
import RegistrationPopUp from "../RegistrationPopUp";
// import sales from "../../../assets/images/sales.png";
// import distribution from "../../../assets/images/distribution.png";
// import hr from "../../../assets/images/hr.png";
// import production from "../../../assets/images/production.png";
// import operation from "../../../assets/images/operation.png";
// import financing from "../../../assets/images/financing.png";
import { CompetativeTabs } from "../../../shared/AgencyTabs/CompetativeTabs";

const CompetitiveRisk = ({ industryId }) => {
  console.log("========CompetitiveRisk==");
  const dispatch = useDispatch();
  const RIGHT_INTERPRETATIONS = [
    "align/maintain/manage/ensure/control",
    "focus/develop/invest/implement/conduct",
    "streamline/optimize/support",
    "enhance/improve/increase/srengthen/expand",
    "reduce/minimize/eliminate",
    "negotiate/restructure",
  ];
  const { user, isAuthenticatedUser } = useSelector(
    (state) => state.userReducer
  );
  const [currentCompetativeMetric, setCurrentCompetativeMetric] = useState(
    TYPES_OF_SEGMENT_METRICS[0].label
  );
  const [metricColors, setMetricColors] = useState([]);
  const METRIC_BG_COLORS = ["#E4F0DD", "#FFF1E1", "#FDE7E7"];
  const METRIC_COLORS = ["#2D6C08", "#BE4400", "#B31B1B"];
  const riskSideMenu = [
    "growthAndReturns",
    "efficiencyAndLeverage",
    "marginAndCost",
  ];
  const [modelRegistrationPopUp, setModelRegistrationPopUp] = useState(false);
  const [currentType, setCurrentType] = useState(riskSideMenu[0]);

  const [currentMetric, setCurrentMetric] = useState(
    TYPES_OF_METRICS[riskSideMenu[0]]?.value?.[0]?.label
  );

  const [patternData, setPatternData] = useState(null);

  const [patternValue, setPatternValue] = useState([]);

  const applyColorsToMetrics = (dataList) => {
    const coloredList = dataList.map((_, index) => {
      const colorIndex = index % METRIC_BG_COLORS.length;
      return {
        bgColor: METRIC_BG_COLORS[colorIndex],
        textColor: METRIC_COLORS[colorIndex],
      };
    });

    return coloredList;
  };

  const coloredDataList = applyColorsToMetrics(
    TYPES_OF_METRICS?.[currentType]?.value
  );

  const { organisationData } = useSelector(
    (state) => state.organisationReducer
  );

  const { client } = organisationData;

  useEffect(() => {
    if (organisationData?.client?.subsector) {
      dispatch(getCompetitiveMapData(organisationData.client.subsector));
    }
  }, [dispatch, organisationData?.client?.subsector]);
  // console.log("====client.id", user, client);
  useEffect(() => {
    if (user?.id && user?.id !== "") {
      user?.id && dispatch(getZScoreForClient(user.id));
      user?.id && dispatch(getTrendsForClient(user.id));
      user?.id && dispatch(getCompetitiveMapDataForClient(user.id));
      user?.id && dispatch(getAuditSheetDataForClient(user.id));
    }
  }, [dispatch, user?.id]);
  return (
    <>
      {isAuthenticatedUser ? (
        <Flex id={"segment_risk_container"}>
          <CompetativeTabs
            setCurrentCompetativeMetric={setCurrentCompetativeMetric}
            currentCompetativeMetric={currentCompetativeMetric}
            metricColors={metricColors}
            setMetricColors={setMetricColors}
          />
          <Box className={"segment_risks_block"}>
            <Box id="activityLabel"></Box>
            <Box id={"segment_risks_content"}>
              <Box id={"segment_risks_graph"}>
                {false ? (
                  <>
                    <Box h={"200px"}>
                      <Loader />
                    </Box>
                  </>
                ) : (
                  <>
                    <Box className="activity-content-graph">
                      <Grid
                        display={"block"}
                        templateAreas={`"PerformanceMatrix CompetitiveMap"
                              "MetricsSpectrum CompetitiveMap"`}
                        gridTemplateRows={"472px"}
                        gridTemplateColumns={"1fr"}
                        gap="1"
                      >
                        <GridItem
                          area={"PerformanceMatrix"}
                          borderBottom={"3px solid #F8F8F8"}
                          pb={4}
                        >
                          <Box py={5} pl={3} pr={3}>
                            <Text
                              textTransform="uppercase"
                              color={"#162421"}
                              fontWeight={600}
                              fontSize={16}
                            >
                              Patterns
                            </Text>
                            <PerformanceMatrixComponent
                              setCurrentType={setCurrentType}
                              currentMetric={currentMetric}
                              setPatternData={setPatternData}
                              setMetricColors={setMetricColors}
                              metricColors={metricColors}
                              setCurrentMetric={setCurrentMetric}
                            />
                          </Box>
                        </GridItem>
                        <GridItem area={"CompetitiveMap"}>
                          <Box p={8} colSpan={1} borderLeft="3px solid #F8F8F8">
                            <Box py={3} px={5}>
                              <CompetitiveMapComponent
                                patternData={patternData}
                                currentType={currentType}
                                currentMetric={currentMetric}
                                setPatternValue={setPatternValue}
                              />
                            </Box>
                          </Box>
                        </GridItem>
                        <GridItem area={"MetricsSpectrum"}>
                          <Box p={5}>
                            <MetricsSpectrumComponent
                              currentMetric={currentMetric}
                              patternData={patternData}
                              currentType={currentType}
                              metricColors={metricColors}
                            />
                          </Box>
                        </GridItem>
                      </Grid>
                    </Box>
                  </>
                )}
              </Box>
              <Box
                id={"segment_risks_observations"}
                height={"100%!important"}
                position={"relative"}
              >
                <Box
                  top="0px"
                  position={"absolute"}
                  height={"50%"}
                  width={"100%"}
                  overflowY={"auto"}
                >
                  {false ? (
                    <>
                      <Box h={"200px"}>
                        <Loader />
                      </Box>
                    </>
                  ) : (
                    <Box boxShadow="0 0 5px 0 rgba(0,0,0,0.2)">
                      <Box display="flex" p={"10px"}>
                        <Heading
                          color="#5D5D5D"
                          textTransform="uppercase"
                          fontWeight={600}
                          fontSize={"16px"}
                          letterSpacing={"0.9px"}
                        >
                          Pattern
                        </Heading>
                        <Text
                          width="auto"
                          backgroundColor={
                            COLORS[patternData?.interpretation?.color]
                              ?.bgColor || "#EAF4EB"
                          }
                          m={"0px 0px 0px 10px"}
                          color={
                            COLORS[patternData?.interpretation?.color]
                              ?.textColor || "#0D3B14"
                          }
                          fontFamily={"Inter"}
                          fontWeight={600}
                          fontSize="14px"
                          px={5}
                          borderRadius={1}
                          letterSpacing={"0.8px"}
                          boxShadow="0px 0px 25px 0px rgba(0, 0, 0, 0.04), 0px 0px 6px 0px rgba(0, 0, 0, 0.06)"
                        >
                          <Text display="inline-block" m={"0px 0px 0px 10px"}>
                            {(patternData?.pattern || "").replace(
                              /\b\w/g,
                              function (match) {
                                return match.toUpperCase();
                              }
                            )}
                          </Text>
                          <Text display="inline-block" m={"0px 0px 0px 10px"}>
                            {patternValue?.metricValue}
                          </Text>
                        </Text>
                      </Box>

                      {currentType === "growthAndReturns" ? (
                        ""
                      ) : (
                        <Box p={"5px 0px 0px 10px"}>
                          {/* <Flex direction={{ base: 'column', lg: 'row' }}> */}
                          <Box
                            width={{ base: "100%" }}
                            borderRight={"4px solid #F2F7F7"}
                            pr={2}
                          >
                            <Heading
                              color="#5D5D5D"
                              textTransform="uppercase"
                              fontWeight={600}
                              fontSize={"16px"}
                              letterSpacing={"0.9px"}
                            >
                              Ways To Assess Or Diagnose
                            </Heading>
                            <List spacing={3} mt="2">
                              <ListItem
                                color="#1E1E1E"
                                letterSpacing="0.8px"
                                display="flex"
                                fontWeight={600}
                                fontSize={"14px"}
                                alignItems="center"
                              >
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13 1H2C1.44772 1 1 1.44772 1 2V24C1 24.5523 1.44772 25 2 25H24C24.5523 25 25 24.5523 25 24V13"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                </svg>
                                <Box mr={2}></Box>
                                {patternData?.interpretation
                                  ?.waysToAssessOrDiagnose || ""}
                              </ListItem>
                            </List>
                          </Box>

                          <Box maxW={{ base: "100%" }} ml={2}>
                            <Heading
                              color="#5D5D5D"
                              textTransform="uppercase"
                              fontWeight={600}
                              fontSize={"16px"}
                              letterSpacing={"0.9px"}
                            >
                              Internal Controls
                            </Heading>
                            <List spacing={3} mt="2">
                              <ListItem
                                color="#1E1E1E"
                                letterSpacing="0.8px"
                                display="flex"
                                fontWeight={600}
                                fontSize={"14px"}
                                alignItems="center"
                              >
                                <svg
                                  width="12"
                                  height="12"
                                  viewBox="0 0 26 26"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M13 1H2C1.44772 1 1 1.44772 1 2V24C1 24.5523 1.44772 25 2 25H24C24.5523 25 25 24.5523 25 24V13"
                                    stroke="black"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                  />
                                </svg>
                                <Box mr={2}></Box>
                                {patternData?.interpretation
                                  ?.internalControls || ""}
                              </ListItem>
                              {RIGHT_INTERPRETATIONS.map((intrPret) => {
                                const interpretation =
                                  patternData?.interpretation
                                    ?.BusinessCapabilityRecommendation?.[
                                    intrPret
                                  ];

                                if (!interpretation) return "";

                                return (
                                  <ListItem
                                    color="#1E1E1E"
                                    fontWeight="400"
                                    letterSpacing="0.8px"
                                    display="flex"
                                    alignItems="center"
                                  >
                                    <Img src={bullet} mr={2} />
                                    {intrPret}: {interpretation}
                                  </ListItem>
                                );
                              })}
                            </List>
                          </Box>
                          {/* </Flex> */}
                        </Box>
                      )}
                    </Box>
                  )}
                </Box>
                <Box
                  top="51%"
                  position={"absolute"}
                  height={"50%"}
                  width={"100%"}
                >
                  <Box
                    className={
                      isAuthenticatedUser
                        ? ""
                        : "segment_risks_advisory_content-topM"
                    }
                    id={"segment_risks_advisory_content"}
                  >
                    <AdvisoryNotes clientId={user?.id} />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      ) : (
        <Box p={"15%"}>
          <UnlockRegistration
            setModelRegistrationPopUp={setModelRegistrationPopUp}
          />
        </Box>
      )}
      <RegistrationPopUp
        setModelRegistrationPopUp={setModelRegistrationPopUp}
        modelRegistrationPopUp={modelRegistrationPopUp}
      />
    </>
  );
};

export default CompetitiveRisk;
