import { Heading, Stack } from "@chakra-ui/react";
import React from "react";
import { Bar, BarChart, Legend, Tooltip, XAxis, YAxis } from "recharts";
import {
  calculateUnitsForValues,
  formatCurrencyValue,
} from "../../../utils/helperFunction";

const TotalDebt = ({ data, yAxisLabel }) => {
  const colors = ["#C3C0ED", "#C0FFB2", "#ADECFF"];

  const valuesWithUnits = calculateUnitsForValues(
    data.map((d) => ({ value: d.total }))
  );

  yAxisLabel = `TD [units in ${valuesWithUnits}]`;

  const keys = data.map((d) => Object.keys(d.value))[0];

  data = data.map((d, i) => ({
    ...d.value,
    year: d.year,
  }));

  return (
    <>
      <Stack className="home-page-card" alignItems="flex-start">
        <Heading
          size={"md"}
          color={"primary.500"}
          bg="#fff"
          mb="2"
          p="5px 10px 8px"
          display="inline-block"
          textColor="#1E1E1E"
          borderRadius={4}
        >
          Total Debt
        </Heading>
        <BarChart
          className="chart-container"
          width={500}
          height={300}
          margin={{ top: 20, right: 20, bottom: 10, left: 60 }}
          data={data}
        >
          <XAxis
            dataKey="year"
            label={{ value: "Period", position: "insideBottom", dy: 10 }}
          />
          <YAxis
            tickFormatter={formatCurrencyValue}
            domain={[0, "dataMax"]}
            label={{
              value: yAxisLabel,
              angle: -90,
              position: "Middle",
              dx: -45,
            }}
          />
          <Tooltip itemStyle={{ color: "#000" }} />
          {/* <Legend /> */}
          {/* <Bar dataKey="ltBorrowings" stackId="1" fill="#8884d8" />
          <Bar dataKey="stBorrowing" stackId="1" fill="#82ca9d" />
          <Bar dataKey="totalDebt" stackId="2" fill="#ffc658" /> */}
          {keys?.map?.((k, i) => (
            <Bar
              key={i}
              stackId="1"
              dataKey={k}
              fill={colors[i % colors.length]}
            />
          ))}
        </BarChart>
      </Stack>
    </>
  );
};

export default TotalDebt;
