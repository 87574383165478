import { toast } from "react-toastify";

import { uploadAuditApi, getRawAuditSheetsApi } from "../../api/auditApi";
import { errMsg } from "../../utils/helperFunction";
import { refreshUserWithoutNavigate } from "../user/userActions";

export const uploadAuditSheets =
  (
    uploadCurrentYear,
    clientId,
    unit,
    balanceSheet,
    profitLoss,
    rawBalanceSheet,
    rawProfitLoss,
    userType,
    navigate
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UPLOAD_AUD_SHEET_REQ",
      });

      const reqBody = {
        uploadCurrentYear,
        clientId,
        balanceSheet,
        profitLoss,
        rawBalanceSheet,
        rawProfitLoss,
        unit,
      };

      const data = await uploadAuditApi(reqBody);

      dispatch({
        type: "UPLOAD_AUD_SHEET_SUC",
        payload: { ...data },
      });

      dispatch(refreshUserWithoutNavigate());

      if (userType === "client") {
        return navigate(`/client/${clientId}/sme-background`);
      }

      // navigate to success page
      navigate(`/agency/client/${clientId}/client-background`);
    } catch (err) {
      const { message } = errMsg(err);
      toast.error(message, {
        toastId: message,
        position: "bottom-center",
      });

      dispatch({
        type: "UPLOAD_AUD_SHEET_FAIL",
        payload: { success:false, message },
      });
    }
  };

export const getRawAuditSheets = (reqBody) => async (dispatch) => {
  try {
    dispatch({ type: "GET_RAW_AUDIT_SHEET_REQ" });
    
    const data = await getRawAuditSheetsApi(reqBody);

    dispatch({
      type: "GET_RAW_AUDIT_SHEET_SUC",
      payload: { ...data },
    });
  } catch (err) {
    console.error(err);
    const { message } = errMsg(err);

      toast.error(message, {
        toastId: message,
        position: "bottom-center",
      });

      dispatch({
        type: "GET_RAW_AUDIT_SHEET_FAIL",
        payload: { success: false, message },
      });
  }
};

export const getClientIdForAudtiSheet = (clientId) => {
  return (dispatch) => {
    dispatch({
      type: "GET_CLIENT_ID_FOR_AUDTI_SHEET",
      payload: { clientId },
    });
  };
};
