import { Box, Flex} from "@chakra-ui/react";
import Header from "../Header/Header";
import LeftSideNav from "../LeftSideNav/LeftSideNav";
import Footer from "../Footer/Footer";
import { Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTourContext } from "../../customHooks/useTourContext";


const Layout = () => {
  const { is_tour } = useTourContext();
  console.log("======istour", is_tour)
  return (
    <>
      <Box w="100%" bg="var(--body-bg)">
        <Box>
          <Header />
        </Box>

        <Flex style={{ backgroundColor: "var(--sylvr-bg-color)"}}>
          <LeftSideNav />
          <Box
            w="100%"
            h="calc(100% - var(--headerbar-height) - var(--footerbar-height))"
            padding={"10px"}
          >
            <Outlet />
          </Box>
          {is_tour && (
            <Box
              position={"absolute"}
              w="100%"
              h="calc(100% - var(--headerbar-height) - var(--footerbar-height))"
              zIndex={2}
              backgroundColor={"#8F8F8F4D"}
              opacity={"1"}
            ></Box>
          )}
        </Flex>
        {/* <Flex w="100%" bg="blue.200"> */}
        {/* <AppBody /> */}
        {/* </Flex> */}
        {/* <Footer /> */}
      </Box>
    </>
  );
};

export default Layout;
