import {
  Flex,
  Box,
  Heading,
  IconButton,
  Button,
  Stack,
  Collapse,
  useDisclosure,
  useColorModeValue,
  Img,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { BiHomeAlt, BiLogInCircle } from "react-icons/bi";
import { TbMessage, TbInfoSquare } from "react-icons/tb";
import { RxAvatar } from "react-icons/rx";
import "./navbar.scss";
import { Link, useParams } from "react-router-dom";
import UserMenu from "./UserMenu";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { bgColorDark, bgColorLight } from "../../styles/style";
import { isHomePage } from "../../utils/helperFunction";
import sylvr_logo from "../../assets/images/sylvr-logo.jpg";
import sylvr_register from "../../assets/images/register-login.png";

const Navbar = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const { isOpen, onToggle } = useDisclosure();
  // const { colorMode, toggleColorMode } = useColorMode();

  const backgroundColor = useColorModeValue(bgColorLight, bgColorDark);

  const [expandUserMenu, setExpandUserMenu] = useState(false);
  const { isAuthenticatedUser } = useSelector((state) => state.userReducer);

  useEffect(() => {
    setExpandUserMenu(false);
  }, [dispatch, params.id]);

  const handleClickScroll = (id) => {
    // Scroll to the element with the "regionwise_result" ID
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <Box px={4}>
        <Flex h={10} alignItems={'center'} justifyContent={'space-between'}>
          <Link to={'https://beta.sylvr.co.in'}>
            {/* <Heading variant={'h1'} size={['md', 'lg']} fontWeight="bold">
              Sylvr
            </Heading> */}
            <Img src={sylvr_logo} width={100} />
          </Link>

          <Flex display={{ sm: 'none' }}>
            {/* <Button
              style={{ "display": "none" }}
              onClick={toggleColorMode}
              variant={"ghost"}
              mr={2}
            >
              {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
            </Button> */}

            <IconButton
              size={'md'}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={'Toggle Navigation'}
              onClick={onToggle}
            />
          </Flex>
          {isAuthenticatedUser && isHomePage() && (
            <Box display={['none', 'flex']} alignItems={'center'}>
              <Stack direction={'row'} spacing={6} alignItems={'center'}>
                <Button
                  variant={'link'}
                  onClick={() => handleClickScroll('regionwise_section')}
                  offset={-100}
                >
                  Regions
                </Button>
                <Button
                  variant={'link'}
                  onClick={() => handleClickScroll('sectorwise_section')}
                  offset={-100}
                >
                  Sectors
                </Button>
              </Stack>
            </Box>
          )}

          <Box display={['none', 'flex']} alignItems={'center'}>
            <Stack direction={'row'} spacing={6} alignItems={'center'}>
              {isAuthenticatedUser && (
                <>
                  <Link to={'/'}>
                    <Button variant={'link'}>Home</Button>
                  </Link>
                  <Link to={'https://beta.sylvr.co.in/about'}>
                    <Button variant={'link'}>About</Button>
                  </Link>
                  <Link to={'https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/contact_us'}>
                    <Button variant={'link'}>Contact</Button>
                  </Link>
                </>
              )}

              {!isAuthenticatedUser && (
                <Link to={'/register-new'}>
                  <div className="nav-register-btn">
                    <div>
                      <Img src={sylvr_register} />
                    </div>
                    <div>Register|Login</div>
                  </div>
                </Link>
              )}

              {/* <Button onClick={toggleColorMode} variant={"ghost"}>
                {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              </Button> */}

              {isAuthenticatedUser && (
                <Button
                  borderRadius={'50%'}
                  variant={'outline'}
                  p={0}
                  onClick={() => setExpandUserMenu(!expandUserMenu)}
                >
                  <RxAvatar />
                </Button>
              )}
            </Stack>
          </Box>

          {isAuthenticatedUser && expandUserMenu && (
            <Box
              display={['none', 'block']}
              className="desk-user-menu"
              minWidth={'230px'}
              bgColor={backgroundColor}
            >
              <UserMenu />
            </Box>
          )}
        </Flex>
      </Box>

      {isOpen && (
        <Box
          className="nav-menu-cont"
          display={{ sm: 'none' }}
          bgColor={backgroundColor}
        >
          <Collapse in={isOpen} animateOpacity>
            <Box pb={4}>
              <Stack spacing={0}>
                {isAuthenticatedUser && <UserMenu />}

                <Link to={'https://beta.sylvr.co.in/'}>
                  <Button
                    w={'full'}
                    leftIcon={<BiHomeAlt size={'20px'} />}
                    size={'lg'}
                    padding={'31px 20px'}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    borderRadius={0}
                    variant={'ghost'}
                  >
                    Home
                  </Button>
                </Link>

                <Link to={'https://beta.sylvr.co.in/about'}>
                  <Button
                    w={'full'}
                    leftIcon={<TbInfoSquare size={'20px'} />}
                    size={'lg'}
                    padding={'31px 20px'}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    borderRadius={0}
                    variant={'ghost'}
                  >
                    About Us
                  </Button>
                </Link>

                <Link to={'https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/contact_us'}>
                  <Button
                    w={'full'}
                    leftIcon={<TbMessage size={'20px'} />}
                    size={'lg'}
                    padding={'31px 20px'}
                    display={'flex'}
                    justifyContent={'flex-start'}
                    borderRadius={0}
                    variant={'ghost'}
                  >
                    Contact Us
                  </Button>
                </Link>

                {!isAuthenticatedUser && (
                  <Flex justifyContent={'space-evenly'} pt={'7px'}>
                    <Link to={'/register-new'} className="nav-menu-login-link">
                      <div className="nav-register-btn">
                        <div>
                          <Img src={sylvr_register} />
                        </div>
                        <div>Register|Login</div>
                      </div>
                    </Link>
                  </Flex>
                )}
              </Stack>
            </Box>
          </Collapse>
        </Box>
      )}
    </>
  );
};

export default Navbar;
