import { FormErrorMessage } from "@chakra-ui/react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Box,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsList,
  getSectorList,
} from "../../actions/user/organisationActions";
import Loader from "../../components/Loader/Loader";
import { getClientDetails } from "../../actions/user/agencyActions";
import TextInput from "../../components/formikElements/TextInput";
import { getProductsApi } from "../../api/organisationApi";
import ProductsDropdown from "../../components/ProductsDropdown";

const CreateClient = ({ clientData }) => {
  const dispatch = useDispatch();

  const [showState, setShowState] = useState(false);
  const [location, setLocation] = useState({});

  const [clientDetail, setClientDetail] = useState({
    companyName: "",
    email: "",
    regdOffice: "",
    website: "",
    logo: "",
    pincode: "",
    products: [],
  });

  const { loading, clientDetails } = useSelector(
    (state) => state.agencyReducer
  );

  useEffect(() => {
    dispatch(getSectorList());
    dispatch(getProductsList());
  }, [dispatch]);

  useEffect(() => {
    if (clientData) {
      dispatch(getClientDetails(clientData.id));
    }
  }, [clientData]);

  useEffect(() => {
    if (clientDetails) {
      setClientDetail(clientDetails?.clientDetails);
    }
  }, [clientDetails]);

  const initialValues = {
    companyName: clientDetail?.companyName || "",
    email: clientDetail?.email || "",
    regdOffice: clientDetail?.regdOffice || "",
    website: clientDetail?.website || "",
    logo: clientDetail?.logo || "",
    pincode: clientDetail?.pincode || "",
    products: clientDetail?.products || [],
  };

  return (
    <>
      <Flex
        className="body-container auth-page"
        justifyContent={"space-evenly"}
      >
        {loading ? (
          <Loader />
        ) : (
          <Formik enableReinitialize={true} initialValues={initialValues}>
            {(formik) => (
              <Stack
                p={{ base: "0", md: "6" }}
                width={"full"}
                maxW={"2160px"}
                overflowY={"auto"}
              >
                <Box className="custom-input">
                  <TextInput
                    name="companyName"
                    placeholder="Enter the name of the company"
                    label="Organization"
                    isRequired={true}
                    focusBorderColor="primary.400"
                    disabled={clientData?.id}
                  ></TextInput>
                </Box>
                <Flex
                  justifyContent={"space-between"}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="pincode"
                      placeholder="Enter the pincode of company location"
                      label="Pincode"
                      isRequired={true}
                      focusBorderColor="primary.400"
                      disabled={clientData?.id}
                    ></TextInput>
                  </Box>
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="email"
                      placeholder="Enter the email of your organisation"
                      label="Email"
                      focusBorderColor="primary.400"
                      disabled={clientData?.id}
                    ></TextInput>
                  </Box>
                </Flex>
                {showState && (
                  <Flex
                    justifyContent={"space-between"}
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    {showState && (
                      <Box
                        width={{ base: "100%", md: "48%" }}
                        mt="5"
                        className="custom-input"
                      >
                        <FormControl maxWidth={"md"} margin={"auto"}>
                          <FormLabel>District</FormLabel>
                          <Input
                            value={location.district}
                            readOnly
                            focusBorderColor="primary.400"
                            // color="primary.600"
                            disabled={clientData?.id}
                          ></Input>
                        </FormControl>
                      </Box>
                    )}

                    {showState && (
                      <Box
                        width={{ base: "100%", md: "48%" }}
                        mt="5"
                        className="custom-input"
                      >
                        <FormControl maxWidth={"md"} margin={"auto"}>
                          <FormLabel>State</FormLabel>
                          <Input
                            value={location.state}
                            readOnly
                            focusBorderColor="primary.400"
                            // color="primary.600"
                            disabled={clientData?.id}
                          ></Input>
                        </FormControl>
                      </Box>
                    )}
                  </Flex>
                )}

                <Flex
                  justifyContent={"space-between"}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <Field name="products">
                      {({ field, form }) => (
                        <FormControl
                          maxWidth={"md"}
                          margin={"auto"}
                          isInvalid={
                            form.errors.products && form.touched.products
                          }
                        >
                          <FormLabel>
                            Products <span style={{ color: "#E53E3E" }}>*</span>
                          </FormLabel>

                          <ProductsDropdown
                            defaultProductValues={field.value}
                            fetchDataFunction={getProductsApi}
                            placeholder="Search Products..."
                            isMulti
                            clientData={clientData}
                          />
                        </FormControl>
                      )}
                    </Field>
                  </Box>
                </Flex>

                <Flex justifyContent={"space-evenly"} p={"36px 0 0 0"}>
                  <Flex>
                    <Button
                      variant={"solid"}
                      m={"0 12px"}
                      isDisabled={clientData?.id}
                    >
                      Submit
                    </Button>
                  </Flex>
                </Flex>
              </Stack>
            )}
          </Formik>
        )}
      </Flex>
    </>
  );
};

export default CreateClient;
