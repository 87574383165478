import React from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import ScatterChartComponent from "../../components/CompetitiveMap/ScatterChart";
import { Flex } from "@chakra-ui/react";

export const CompetitiveMapComponent = ({
  patternData,
  currentType,
  currentMetric,
  setPatternValue,
}) => {

  const { loading, organisationData } = useSelector(
    (state) => state.organisationReducer
  );

  const { competitiveMap } = organisationData;

  return (
    <>
      {loading || competitiveMap?.loading ? (
        <Loader />
      ) : (
        <>
          <Flex justifyContent={"space-around"} alignItems={"center"}>
            <ScatterChartComponent
              patternData={patternData}
              currentType={currentType}
              currentMetric={currentMetric}
              setPatternValue={setPatternValue}
            />
          </Flex>
        </>
      )}
    </>
  );
};
