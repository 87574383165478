import { Box, Stack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import "./main.scss";
import "./menu-button.scss";
import "./theme-overrides.scss";
import "./homeV2.scss";
import "./homeV1.scss";


const HomeV1 = () => {
  useEffect(() => {
    const handleWindowScroll = () => {
      var _cur_top =
        document.documentElement.scrollTop || document.body.scrollTop;
      var segment_risks_type = document.getElementById("global-header");
      if (segment_risks_type) {
        if (_cur_top > 50) {
          segment_risks_type.className = "header active";
        } else {
          segment_risks_type.className = "header";
        }
      }
    };

    window.addEventListener("scroll", handleWindowScroll);

    return () => {
      window.removeEventListener("scroll", handleWindowScroll);
    };
  }, []);
  return (
    <>
      <Stack className="body-container" overflowY={"auto"} spacing={"14"}>
        <Box className="body-wrapper">
          <Box data-global-resource-path="@marketplace/Juice_Tactics_Snacks/Thrifty/templates/partials/header.html">
            <header className="header" id="global-header">
              <Box as="a" href="#main-content" className="header__skip">
                Skip to content
              </Box>

              <Box className="header__container">
                <Box className="header__column ml-40 mr-40">
                  <Box
                    className="header__row-2 border-radius mt-40 ml-auto mr-auto mb-0"
                    id="header-row-2"
                  >
                    <Box className="header__logo header__logo--main">
                      <Box
                        id="hs_cos_wrapper_site_logo"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module widget-type-logo"
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="module"
                      >
                        <Box
                          id="hs_cos_wrapper_site_logo_hs_logo_widget"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_logo"
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="logo"
                        >
                          <Box
                            as="a"
                            href="https://beta.sylvr.co.in/home"
                            target="_blank"
                            id="hs-link-site_logo_hs_logo_widget"
                            rel="noopener"
                            style={{ "border-width": "0px", border: "0px" }}
                          >
                            <img
                              src="https://beta.sylvr.co.in/hs-fs/hubfs/Screenshot_2024-03-13_213737-removebg-preview.png?width=794&amp;height=208&amp;name=Screenshot_2024-03-13_213737-removebg-preview.png"
                              className="hs-image-widget"
                              height="208"
                              style={{
                                height: "auto",
                                width: "794px",
                                "border-width": "0px",
                                border: "0px;",
                              }}
                              width="794"
                              alt="Screenshot_2024-03-13_213737-removebg-preview"
                              title="Screenshot_2024-03-13_213737-removebg-preview"
                              loading="lazy"
                              srcset="
                            https://beta.sylvr.co.in/hs-fs/hubfs/Screenshot_2024-03-13_213737-removebg-preview.png?width=397&amp;height=104&amp;name=Screenshot_2024-03-13_213737-removebg-preview.png   397w,
                            https://beta.sylvr.co.in/hs-fs/hubfs/Screenshot_2024-03-13_213737-removebg-preview.png?width=794&amp;height=208&amp;name=Screenshot_2024-03-13_213737-removebg-preview.png   794w,
                            https://beta.sylvr.co.in/hs-fs/hubfs/Screenshot_2024-03-13_213737-removebg-preview.png?width=1191&amp;height=312&amp;name=Screenshot_2024-03-13_213737-removebg-preview.png 1191w,
                            https://beta.sylvr.co.in/hs-fs/hubfs/Screenshot_2024-03-13_213737-removebg-preview.png?width=1588&amp;height=416&amp;name=Screenshot_2024-03-13_213737-removebg-preview.png 1588w,
                            https://beta.sylvr.co.in/hs-fs/hubfs/Screenshot_2024-03-13_213737-removebg-preview.png?width=1985&amp;height=520&amp;name=Screenshot_2024-03-13_213737-removebg-preview.png 1985w,
                            https://beta.sylvr.co.in/hs-fs/hubfs/Screenshot_2024-03-13_213737-removebg-preview.png?width=2382&amp;height=624&amp;name=Screenshot_2024-03-13_213737-removebg-preview.png 2382w
                          "
                              sizes="(max-width: 794px) 100vw, 794px"
                            />
                          </Box>
                        </Box>
                      </Box>
                    </Box>

                    <Box
                      className="header--toggle header__navigation--toggle"
                      id="header-navigation-toggle"
                    ></Box>

                    <Box
                      className="header__close--toggle"
                      id="header-close-toggle"
                    ></Box>
                    <Box
                      className="header__navigation header--element"
                      id="header-element"
                    >
                      <Box
                        id="hs_cos_wrapper_navigation-primary"
                        className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                        data-hs-cos-general-type="widget"
                        data-hs-cos-type="module"
                      ></Box>
                      <Box>
                        <Box
                          id="hs_cos_wrapper_menu_button"
                          className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                          data-hs-cos-general-type="widget"
                          data-hs-cos-type="module"
                        >
                          <ul className="menu-button">
                            <li className="header-button button-2">
                              <Box
                                as="a"
                                className="button font-size-16"
                                href={`${process.env.REACT_APP_DOMAIN_BASE_URL}/register-new`}
                                target="_blank"
                                rel="noopener"
                              >
                                <Box
                                  id="hs_cos_wrapper_menu_button_"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="icon"
                                >
                                  <svg
                                    version="1.0"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewbox="0 0 512 512"
                                    width="16"
                                    height="16"
                                    aria-hidden="true"
                                  >
                                    <g id="Alternate External Link1_layer">
                                      <path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" />
                                    </g>
                                  </svg>
                                </Box>
                                Sign Up
                              </Box>
                            </li>
                          </ul>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>

              <Box className="container-fluid content-wrapper">
                <Box className="row-fluid-wrapper">
                  <Box className="row-fluid">
                    <Box
                      className="span12 widget-span widget-type-cell"
                      data-widget-type="cell"
                      data-x="0"
                      data-w="12"
                    ></Box>
                  </Box>
                </Box>
              </Box>
            </header>
          </Box>

          <Box id="main-content" className="body-container-wrapper">
            <Box className="container-fluid body-container body-container--home container-fluid-custom">
              <Box className="row-fluid-wrapper">
                <Box className="row-fluid">
                  <Box
                    className="span12 widget-span widget-type-cell"
                    data-widget-type="cell"
                    data-x="0"
                    data-w="12"
                  >
                    <Box className="row-fluid-wrapper row-depth-1 row-number-1 dnd-section dnd_area-row-0-padding dnd_area-row-0-background-image">
                      <Box className="row-fluid">
                        <Box
                          className="span6 widget-span widget-type-cell dnd-column"
                          data-widget-type="cell"
                          data-x="0"
                          data-w="6"
                        >
                          <Box className="row-fluid-wrapper row-depth-1 row-number-2 dnd-row">
                            <Box className="row-fluid">
                              <Box
                                className="span12 widget-span widget-type-custom_widget dnd-module"
                                data-widget-type="custom_widget"
                                data-x="0"
                                data-w="12"
                              >
                                <Box
                                  id="hs_cos_wrapper_widget_1707281327040"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="module"
                                >
                                  <Box className="th-hero-2 text-left">
                                    <Box className="max-width-850 ml-auto mr-auto">
                                      <Box className="tagline-wrap mb-15">
                                        <Box className="tagline secondary-color">
                                          RISKS ASSISTANT for SMEs &amp; their
                                          TRUSTED ADVISORS
                                        </Box>
                                        <Box className="tagline-line secondary-bg-color ml-15"></Box>
                                      </Box>

                                      <Box className="heading-text">
                                        <h1>
                                          <Box className="heading-type heading-color font-size-60">
                                            Democratizing management
                                            <strong>intelligence</strong> for
                                            SMEs
                                          </Box>
                                        </h1>
                                      </Box>
                                      <Box className="heading-line"></Box>

                                      <Box className="mb-50">
                                        <Box>
                                          <Box style={{ color: "#ffffff" }}>
                                            <Box
                                              style={{ "font-size": "18px" }}
                                            >
                                              Business metrics are the report
                                              card that stakeholders trust.
                                              Sylvr empowers you to understand
                                              &amp; address them proactively.
                                            </Box>
                                          </Box>
                                          <Box
                                            style={{ color: "#ffffff" }}
                                          ></Box>
                                        </Box>
                                      </Box>

                                      <Box className="button-wrap">
                                        <Box className="button-2 button-col">
                                          <Box
                                            as="a"
                                            className="button"
                                            href={`${process.env.REACT_APP_DOMAIN_BASE_URL}/dashboard`}
                                            target="_blank"
                                            rel="noopener"
                                          >
                                            Access Free Dashboard
                                            <Box
                                              id="hs_cos_wrapper_widget_1707281327040_"
                                              className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_icon"
                                              data-hs-cos-general-type="widget"
                                              data-hs-cos-type="icon"
                                            >
                                              <svg
                                                version="1.0"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewbox="0 0 512 512"
                                                width="16"
                                                height="16"
                                                aria-hidden="true"
                                              >
                                                <g id="Alternate External Link1_layer">
                                                  <path d="M432,320H400a16,16,0,0,0-16,16V448H64V128H208a16,16,0,0,0,16-16V80a16,16,0,0,0-16-16H48A48,48,0,0,0,0,112V464a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V336A16,16,0,0,0,432,320ZM488,0h-128c-21.37,0-32.05,25.91-17,41l35.73,35.73L135,320.37a24,24,0,0,0,0,34L157.67,377a24,24,0,0,0,34,0L435.28,133.32,471,169c15,15,41,4.5,41-17V24A24,24,0,0,0,488,0Z" />
                                                </g>
                                              </svg>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </Box>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                        <Box
                          className="span6 widget-span widget-type-cell dnd-column"
                          data-widget-type="cell"
                          data-x="6"
                          data-w="6"
                        >
                          <Box className="row-fluid-wrapper row-depth-1 row-number-3 dnd-row">
                            <Box className="row-fluid">
                              <Box
                                className="span12 widget-span widget-type-custom_widget dnd-module"
                                data-widget-type="custom_widget"
                                data-x="0"
                                data-w="12"
                              >
                                <Box
                                  id="hs_cos_wrapper_widget_1707281360758"
                                  className="hs_cos_wrapper hs_cos_wrapper_widget hs_cos_wrapper_type_module"
                                  data-hs-cos-general-type="widget"
                                  data-hs-cos-type="module"
                                >
                                  <Box
                                    id="embed_container"
                                    className="embed_container"
                                  >
                                    <Box className="iframe_wrapper">
                                      <iframe
                                        width="600"
                                        height="350"
                                        src="https://www.youtube.com/embed/c0NnMhgU9dU?si=UE43QJ6iP8fe0ISO"
                                        title="YouTube video player"
                                        frameborder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                        allowfullscreen
                                      ></iframe>
                                    </Box>
                                  </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Stack>
    </>
  );
};

export default HomeV1;
