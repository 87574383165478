import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  previewData: null,
};

const auditPreviewReducer = createReducer(initialState, {
  GET_AUD_PREV_REQ: (state, actions) => {
    state.loading = true;
  },
  GET_AUD_PREV_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.previewData = actions.payload;
  },
  GET_AUD_PREV_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },
});

export default auditPreviewReducer;
