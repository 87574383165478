import { sendRequest } from "../libs/axiosConfig";

export const getIndustryObservationsApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/industry-observations`,
    method: 'POST',
    body: reqBody,
  });
  return data;
};
