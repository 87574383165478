import { useEffect, useState } from 'react';
import { Box, Button, Stack, Text } from '@chakra-ui/react';
import './Cookies.scss';

const Cookies = () => {
  const [cookies, setCookies] = useState('')
  useEffect(() => {
    let current_cookies = JSON.parse(localStorage.getItem('sylvr') || '{"status": false }');
    let current_time = Number(new Date());
    if (
      current_cookies.status === false &&
      (!current_cookies.loggedtime || (current_time - current_cookies.loggedtime) > 3600*24)
    ) {
      setCookies(false);
    } else {
      setCookies(true);
    }
  }, []);

  const rejectCookies = () =>{
    setCookies(true);
    localStorage.setItem('sylvr', JSON.stringify({status:false, loggedtime:Number(new Date()) }));
  }

  const acceptCookies = () => {
    setCookies(true);
    localStorage.setItem('sylvr', JSON.stringify({
      status: true,
      loggedtime: Number(new Date()),
    }));
  };

  return (
    <>
      {!cookies && (
        <Box className="box-cookies">
          <Stack spacing={3}>
            <Text p={'0px'} m="0px">
              We use cookies to help us understand how you interact with our
              website.
            </Text>
            <Text p={'0px'} m="0px">
              By clicking "Accept", you consent to our use of cookies. For more
              information, please see our{' '}
              <a style={{textDecoration:"underline"}} href="https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/privacy">
                privacy policy
              </a>
              .
            </Text>
          </Stack>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              padding: '10px',
            }}
          >
            <Box>
              <Button
                colorScheme="Next"
                borderRadius="8px"
                background="#5D5D5D"
                padding="10px 50px"
                onClick={() => rejectCookies()}
              >
                Reject
              </Button>
            </Box>
            <Box>
              <Button
                colorScheme="Next"
                borderRadius="8px"
                background="#5D5D5D"
                padding="10px 50px"
                onClick={() => acceptCookies()}
              >
                Accept
              </Button>
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Cookies;
