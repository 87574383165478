import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ScatterChart,
  XAxis,
  YAxis,
  Scatter,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Box, Text } from "@chakra-ui/react";

import YearSelection from "../YearSelection";
import { COLORS, TYPES_OF_METRICS } from "../../constants/metrics";
import RelativeMarketShareStackedBarChart from "../charts/RelativeMarketShareStackedBarChart";

const ScatterChartComponent = ({
  currentType,
  currentMetric,
  patternData,
  setPatternValue,
}) => {
  const dispatch = useDispatch();
  // const { organisationData } = useSelector(
  //   (state) => state.organisationReducer
  // );
  // const { competitiveMap, client } = organisationData;
  const [selectedMetric, setSelectedMetric] = useState(currentMetric);
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState();
  const [filteredData, setFilteredData] = useState([]);
  let processedData = null;
  const { client } = useSelector((state) => state.clientReducer);
  const { details, competitiveMap } = client;

  useEffect(() => {
    if (details?.data && competitiveMap && competitiveMap.data) {
      setYears(details?.data?.auditUploadYears);
      let year_size = details?.data?.auditUploadYears.length;
      setSelectedYear(details?.data?.auditUploadYears?.[year_size-1]);
    }
  }, [dispatch, details, competitiveMap]);

  useEffect(() => {
    if (details?.data) {
      setYears(details?.data?.auditUploadYears);
    }
  }, [dispatch, details]);

  useEffect(() => {
    if (competitiveMap?.data) {
      const totalRevenueFromOperationOfPeers =
        competitiveMap.data.totalRevenueFromOperationOfPeers;

      processedData = competitiveMap.data.clusterWiseData.map((cluster) => {
        const financialMetrics = cluster.financialMetrics;
        return financialMetrics.map((item) => {
          const year = item.year;
          if (totalRevenueFromOperationOfPeers.hasOwnProperty(year)) {
            return {
              companyName: cluster.companyName,
              year: year,
              roE: item.roE.currNum / item.roE.currDenom,
              ebitda: item.ebitda.currNum / item.ebitda.currDenom,
              overhead: item.overhead.currNum / item.overhead.currDenom,
              intTaxDep: item.intTaxDep.currNum / item.intTaxDep.currDenom,
              roCE: item.roCE.currNum / item.roCE.currDenom,
              roA: item.roA.currNum / item.roA.currDenom,
              timesInterestEarned:
                item.timesInterestEarned.currNum /
                item.timesInterestEarned.currDenom,
              debtRatio: item.debtRatio.currNum / item.debtRatio.currDenom,
              xValue:
                item.revenueFromOperations /
                totalRevenueFromOperationOfPeers[year],
            };
          } else {
            return null;
          }
        });
      });
    }
  }, [competitiveMap]);

  useEffect(() => {
    if (competitiveMap?.data) {
      const years = competitiveMap.data.clusterWiseData[0].financialMetrics.map(
        (item) => item.year
      );
      setYears(years);
    }
  }, [competitiveMap]);

  const metricLabels = {
    revenueGrowth: "Revenue Growth",
    roE: "Return on Equity (ROE)",
    ebitda: "EBITDA",
    materials: "Materials",
    employees: "Employees",
    overhead: "Overhead",
    intTaxDep: "Interest, Tax, and Depreciation",
    roCE: "Return on Capital Employed (ROCE)",
    roA: "Return on Assets (ROA)",
    timesInterestEarned: "Times Interest Earned",
    debtRatio: "Debt Ratio",
    arDays: "AR Days",
    dpo: "DPO",
    dii: "DII",
  };

  useEffect(() => {
    setSelectedMetric(currentMetric);
  }, [currentMetric]);

  const handleYearChange = (value) => {
    setSelectedYear(value);
  };

  const selectedYearFilteredData =
    competitiveMap?.data?.clusterWiseData?.flatMap?.((cluster) => {
      return cluster.financialMetrics
        .filter((item) => item.year === parseInt(selectedYear))
        .map((item) => {
          const val =
            item[selectedMetric].currNum / item[selectedMetric].currDenom;

          const xVal =
            item.revenueFromOperations /
            competitiveMap.data.totalRevenueFromOperationOfPeers[item.year];

          return {
            clientId: cluster.id,
            companyName: cluster.companyName,
            year: item.year,
            xValue: Number(Number(xVal * 100).toFixed(2)),
            [selectedMetric]: TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
              (m) => m?.label === selectedMetric
            )
              ? Number(Number(val * 100).toFixed(2))
              : Number(Number(val).toFixed(2)),
          };
        });
    });

  useEffect(() => {
    if (selectedYearFilteredData && selectedYearFilteredData.length > 0) {
      setFilteredData(selectedYearFilteredData);
      setPatternValue(
        selectedYearFilteredData
          .filter((sYFD) => sYFD.clientId === details.id)
          .map((sYFD) => {
            const metric = sYFD[selectedMetric];
            const obj = { clientId: sYFD.clientId, metricValue: metric };
            return obj;
          })?.[0]
      );
    }
  }, [selectedMetric, selectedYear]);

  return (
    <div style={{ width: '100%' }}>
      {currentType === 'growthAndReturns' &&
        currentMetric === 'revenueGrowth' && (
          <Box>
            <RelativeMarketShareStackedBarChart years={years} />
          </Box>
        )}

      {currentType === 'growthAndReturns' &&
        currentMetric !== 'revenueGrowth' && (
          <>
            <Box
              py={5}
              px={3}
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Text textTransform="uppercase" fontWeight={600} fontSize={18}>
                Peer Metrics
              </Text>

              <YearSelection
                years={years}
                selectedYear={selectedYear}
                onSelectYear={handleYearChange}
              />
            </Box>

            <Box padding={'15px'}>
              <ResponsiveContainer width={'100%'} aspect={1.5}>
                <ScatterChart
                  margin={{ top: 20, right: 30, bottom: 20, left: 10 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    dataKey="xValue"
                    type="number"
                    domain={['auto', 'auto']}
                    label={{
                      value: 'Relative Market Share',
                      position: 'bottom',
                      offset: 0,
                    }}
                    unit={
                      TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === selectedMetric
                      )
                        ? '%'
                        : ''
                    }
                  />
                  <YAxis
                    dataKey={selectedMetric}
                    type="number"
                    domain={['auto', 'auto']}
                    label={{
                      value: `${metricLabels[selectedMetric]}${
                        currentType === 'marginAndCost' && ' %'
                      }`,
                      angle: -90,
                      position: 'center',
                    }}
                    unit={
                      TYPES_OF_METRICS?.marginAndCost?.value?.some?.(
                        (m) => m?.label === selectedMetric
                      )
                        ? '%'
                        : ''
                    }
                  />
                  <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                  {Object.keys(metricLabels).map((metric) => (
                    <Scatter
                      key={metric}
                      name={metricLabels[metric]}
                      data={filteredData?.map?.((data) => ({
                        ...data,
                        fill:
                          data?.companyName === details?.companyName
                            ? COLORS?.[patternData?.interpretation?.color]
                                ?.textColor || '#6E68DE'
                            : '#6E68DE',
                      }))}
                      dataKey={selectedMetric}
                    />
                  ))}
                </ScatterChart>
              </ResponsiveContainer>
            </Box>
          </>
        )}
    </div>
  );
};

export default ScatterChartComponent;
