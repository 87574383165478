import { Tabs, TabList, Tab } from "@chakra-ui/react";
import { AGENCY_TABS } from "../../utils/Stringconstant";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

export const AgencyTabs = ({
  toggleIndustryTab,
  currentClientTab,
  industryId,
  navigation,
}) => {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    let pathname = location.pathname.split("/");
    let page_location = pathname[pathname.length - 1];
    if (industryId || page_location === "industry-activity") {
      setTabIndex(2);
    } else if (clientId || page_location === "risk-assessment") {
      setTabIndex(3);
    }
  }, [location.pathname]);
  useEffect(() => {
    console.log("=======currentClientTab", currentClientTab);
    if (currentClientTab === "SEGMENT_RISK") {
      setTabIndex(2);
    } else if (currentClientTab === "COMPETITIVE_RISK") {
      setTabIndex(3);
    }
  }, [currentClientTab]);
  return (
    <Tabs
      variant="enclosed"
      colorScheme="teal"
      index={tabIndex}
      className="main-agency-tab"
    >
      <TabList>
        <Tab
          mr={5}
          onClick={() => {
            if (clientId) {
              navigate(`/agency/client/${clientId}/client-background`);
            } else {
              toggleIndustryTab(AGENCY_TABS.CLIENT_BACKGROUND);
            }
          }}
        >
          SME Profile
        </Tab>
        <Tab
          onClick={() => {
            if (clientId) {
              navigate(`/agency/client/${clientId}/client-background`);
            } else {
              toggleIndustryTab(AGENCY_TABS.RISK_MODELLING);
            }
          }}
        >
          Objectives Dashboard
        </Tab>
        <Tab
          onClick={() => {
            toggleIndustryTab(AGENCY_TABS.SEGMENT_RISK);
            if (clientId) {
              navigate(`/agency/client/${clientId}/industry-activity`);
            }
          }}
        >
          Segment Risks
        </Tab>
        <Tab
          onClick={() => {
            toggleIndustryTab(AGENCY_TABS.COMPETITIVE_RISK);
            if (clientId) {
              navigate(`/agency/client/${clientId}/risk-assessment`);
            }
          }}
        >
          Competitive Risks
        </Tab>
      </TabList>
    </Tabs>
  );
};
