import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Button,
  VStack,
  IconButton,
  Heading,
  Select,
  Container,
  Text,
} from "@chakra-ui/react";
import { Form, Formik, Field, FieldArray } from "formik";
import { MinusIcon } from "@chakra-ui/icons";
import { TYPES_OF_SYSTEMATIC_METRICS } from "../../constants/metrics";
import { getAllSectorsResult } from "../../actions/home/homeActions";
import { addIndustryLeaderInterviewApi } from "../../api/industryLeaderInterviewApi";
import { toast } from "react-toastify";
import { INDUSTRY_INTERVIEW_AGENCY } from "../../constants/agency";

export default function IndustryLeaderInterviews() {
  const { agencyId } = useParams();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.userReducer);

  const { allSectors } = useSelector((state) => state.homeReducer);

  useEffect(() => {
    dispatch(getAllSectorsResult());
  }, []);

  const initialValues = {
    journalSourceName: "",
    sourceLink: "",
    firstName: "",
    lastName: "",
    designation: "",
    company: "",
    industryName: allSectors?.data?.[0]?.sector || "",
    dateOfPublishing: "",
    qna: [
      {
        qnaLabel:
          TYPES_OF_SYSTEMATIC_METRICS[
            Object.keys(TYPES_OF_SYSTEMATIC_METRICS)[0]
          ].label,
        question: "",
        answer: "",
      },
    ],
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await addIndustryLeaderInterviewApi({ ...values, agencyId: agencyId });
      toast.success("Interview added successfully", {
        position: "bottom-center",
      });
      resetForm();
    } catch (error) {
      console.error(error);
      toast.error(
        error?.response?.data?.message ||
          error?.message ||
          "Failed to add interview",
        {
          position: "bottom-center",
        }
      );
    } finally {
      setSubmitting(false);
    }
  };

  if (
    agencyId !== user?.id &&
    user?.email !== INDUSTRY_INTERVIEW_AGENCY.email
  ) {
    return <Navigate to="/" replace={true} />;
  }

  return (
    <Container py={5}>
      <Heading my={10}>Industry Leader Interviews</Heading>

      <Box>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ isSubmitting, values }) => (
            <Form>
              <Field name="journalSourceName">
                {({ field }) => (
                  <FormControl mb="4">
                    <FormLabel htmlFor="journalSourceName">
                      Journal/Source Name
                    </FormLabel>
                    <Input
                      {...field}
                      id="journalSourceName"
                      placeholder="Times of India"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="sourceLink">
                {({ field }) => (
                  <FormControl mb="4">
                    <FormLabel htmlFor="sourceLink">Source Link</FormLabel>
                    <Input
                      {...field}
                      id="sourceLink"
                      placeholder="http://www.example.com"
                    />
                  </FormControl>
                )}
              </Field>

              <Field name="firstName">
                {({ field }) => (
                  <FormControl mb="4">
                    <FormLabel htmlFor="firstName">First Name</FormLabel>
                    <Input {...field} id="firstName" placeholder="John" />
                  </FormControl>
                )}
              </Field>

              <Field name="lastName">
                {({ field }) => (
                  <FormControl mb="4">
                    <FormLabel htmlFor="lastName">Last Name</FormLabel>
                    <Input {...field} id="lastName" placeholder="Doe" />
                  </FormControl>
                )}
              </Field>

              <Field name="designation">
                {({ field }) => (
                  <FormControl mb="4">
                    <FormLabel htmlFor="designation">Designation</FormLabel>
                    <Input {...field} id="designation" placeholder="Acting" />
                  </FormControl>
                )}
              </Field>

              <Field name="company">
                {({ field }) => (
                  <FormControl mb="4">
                    <FormLabel htmlFor="company">Company</FormLabel>
                    <Input {...field} id="company" placeholder="XYZ Ltd." />
                  </FormControl>
                )}
              </Field>

              <Field name="industryName">
                {({ field }) => (
                  <FormControl mb="4">
                    <FormLabel htmlFor="industryName">Industry Name</FormLabel>
                    <Select
                      {...field}
                      id="industryName"
                      defaultValue={allSectors?.data?.[0]?.sector}
                    >
                      {allSectors?.data?.map?.((sector, index) => (
                        <option key={index} value={sector?.sector}>
                          {sector?.sector}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>

              <Field name="dateOfPublishing">
                {({ field }) => (
                  <FormControl mb="4">
                    <FormLabel htmlFor="dateOfPublishing">
                      Date of publishing
                    </FormLabel>
                    <Input
                      {...field}
                      type="date"
                      id="dateOfPublishing"
                      placeholder=""
                    />
                  </FormControl>
                )}
              </Field>

              <Text mt={10} mb={3} fontSize={24} fontWeight="700">
                Question Answers
              </Text>

              <FieldArray name="qna">
                {({ push, remove }) => (
                  <Box>
                    {values.qna.map((qna, index) => (
                      <Box key={index}>
                        <Box
                          m={3}
                          p={3}
                          borderWidth={1}
                          borderColor="gray.300"
                          borderRadius="lg"
                        >
                          <Field name={`qna[${index}].qnaLabel`}>
                            {({ field }) => (
                              <FormControl mb={4}>
                                <FormLabel htmlFor={`qna[${index}].qnaLabel`}>
                                  Q&A Label
                                </FormLabel>
                                <Select
                                  {...field}
                                  name={`qna[${index}].qnaLabel`}
                                  defaultValue={
                                    TYPES_OF_SYSTEMATIC_METRICS[
                                      Object.keys(
                                        TYPES_OF_SYSTEMATIC_METRICS
                                      )[0]
                                    ].label
                                  }
                                >
                                  {Object.keys(TYPES_OF_SYSTEMATIC_METRICS).map(
                                    (key) => {
                                      const optionValue =
                                        TYPES_OF_SYSTEMATIC_METRICS?.[key]
                                          ?.label;

                                      return (
                                        <option value={optionValue}>
                                          {optionValue}
                                        </option>
                                      );
                                    }
                                  )}
                                </Select>
                              </FormControl>
                            )}
                          </Field>

                          <Field name={`qna[${index}].question`}>
                            {({ field }) => (
                              <FormControl mb={4}>
                                <FormLabel htmlFor={`qna[${index}].question`}>
                                  Question
                                </FormLabel>
                                <Input
                                  {...field}
                                  name={`qna[${index}].question`}
                                />
                              </FormControl>
                            )}
                          </Field>

                          <Field name={`qna[${index}].answer`}>
                            {({ field }) => (
                              <FormControl mb={4}>
                                <FormLabel htmlFor={`qna[${index}].answer`}>
                                  Answer
                                </FormLabel>
                                <Input
                                  {...field}
                                  name={`qna[${index}].answer`}
                                />
                              </FormControl>
                            )}
                          </Field>

                          <Box textAlign="right">
                            <IconButton
                              mb={4}
                              aria-label="Remove Field"
                              icon={<MinusIcon />}
                              onClick={() => remove(index)}
                            />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                    <Box py={3} textAlign="right">
                      <Button
                        variant="link"
                        onClick={() =>
                          push({
                            qnaLabel:
                              TYPES_OF_SYSTEMATIC_METRICS[
                                Object.keys(TYPES_OF_SYSTEMATIC_METRICS)[0]
                              ].label,
                            question: "",
                            answer: "",
                          })
                        }
                      >
                        Add question
                      </Button>
                    </Box>
                  </Box>
                )}
              </FieldArray>

              <Button
                mt="4"
                colorScheme="teal"
                isLoading={isSubmitting}
                type="submit"
              >
                Submit
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Container>
  );
}
