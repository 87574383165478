import {
  Box,
  VStack,
  Center,
  HStack,
  Spacer,
  SimpleGrid,
  Flex,
  Img,
  Heading,
  IconButton,
  Button,
  Stack,
  Collapse,
  useDisclosure,
  useColorModeValue,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
} from '@chakra-ui/react';
import { RxAvatar } from 'react-icons/rx';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import sylvr_logo from '../../assets/images/sylvr-logo.jpg';
import sylvr_register from '../../assets/images/register-login.png';
import UserMenu from '../../components/Header/UserMenu';
import { bgColorDark, bgColorLight } from '../../styles/style';
import { BiHomeAlt } from 'react-icons/bi';
import { TbInfoSquare, TbMessage } from 'react-icons/tb';
import { CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import './Header.scss';
import LeftSideHeader from './LeftSideHeader';
import { useEffect, useState } from 'react';

const Header = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { isOpen, onToggle } = useDisclosure();
  const [expandUserMenu, setExpandUserMenu] = useState(false);
  const backgroundColor = useColorModeValue(bgColorLight, bgColorDark);
  const { isAuthenticatedUser } = useSelector((state) => state.userReducer);
  console.log('====Header==', isAuthenticatedUser);
  useEffect(() => {
    setExpandUserMenu(false);
  }, [dispatch, params.id]);
  return (
    <Box className="top-nav">
      <Box
        w="100%"
        h="100%"
        p="1"
        display={"flex"}
        justifyContent={"space-between"}
      >
        <Box h="100%">
          {/* <Link to={'https://beta.sylvr.co.in'}>
            <Img src={sylvr_logo} width={100} />
          </Link> */}
          <LeftSideHeader />
        </Box>
        {/* <Spacer /> */}
        <Box id={"logo_container"} w="100px" h="100%">
          <Link to={"https://beta.sylvr.co.in"}>
            <Img src={sylvr_logo} width={100} />
          </Link>
        </Box>
        {/* <Spacer /> */}
        <Flex id={"nav_container"}>
          <Flex id={"nav_container_icon"}>
            <IconButton
              size={"md"}
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
              aria-label={"Toggle Navigation"}
              onClick={onToggle}
              color={"#30d5c8 !important;"}
            />
          </Flex>
          <Box id={"nav_container_menu"}>
            <Stack direction={"row"} spacing={6} alignItems={"center"}>
              <Link to={"https://beta.sylvr.co.in/"}>
                <Button variant={"link"}>Home</Button>
              </Link>
              <Link to={"https://beta.sylvr.co.in/about"}>
                <Button variant={"link"}>About</Button>
              </Link>
              <Link
                to={
                  "https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/contact_us"
                }
              >
                <Button variant={"link"}>Contact</Button>
              </Link>
              {isAuthenticatedUser ? (
                <Button
                  borderRadius={"50%"}
                  variant={"outline"}
                  p={0}
                  onClick={() => setExpandUserMenu(!expandUserMenu)}
                >
                  <RxAvatar />
                </Button>
              ) : (
                <Box>
                  <Link to={"/register-new"}>
                    <div className="nav-register-btn">
                      <div>
                        <Img src={sylvr_register} />
                      </div>
                      <div>Register|Login</div>
                    </div>
                  </Link>
                </Box>
              )}
            </Stack>
            {isAuthenticatedUser && expandUserMenu && (
              <Box
                display={["none", "block"]}
                className="desk-user-menu"
                minWidth={"230px"}
                bgColor={backgroundColor}
              >
                <UserMenu />
              </Box>
            )}
          </Box>
        </Flex>
        {isOpen && (
          <Box className="nav-menu-cont" bgColor={backgroundColor}>
            <Collapse in={isOpen} animateOpacity>
              <Box pb={4}>
                <Stack spacing={0}>
                  {isAuthenticatedUser && <UserMenu />}

                  <Link to={"https://beta.sylvr.co.in/"}>
                    <Button
                      w={"full"}
                      leftIcon={<BiHomeAlt size={"20px"} />}
                      size={"lg"}
                      padding={"31px 20px"}
                      display={"flex"}
                      justifyContent={"flex-start"}
                      borderRadius={0}
                      variant={"ghost"}
                    >
                      Home
                    </Button>
                  </Link>

                  <Link to={"https://beta.sylvr.co.in/about"}>
                    <Button
                      w={"full"}
                      leftIcon={<TbInfoSquare size={"20px"} />}
                      size={"lg"}
                      padding={"31px 20px"}
                      display={"flex"}
                      justifyContent={"flex-start"}
                      borderRadius={0}
                      variant={"ghost"}
                    >
                      About Us
                    </Button>
                  </Link>

                  <Link
                    to={
                      "https://merchant.razorpay.com/policy/M9LEThfaHp4VWn/contact_us"
                    }
                  >
                    <Button
                      w={"full"}
                      leftIcon={<TbMessage size={"20px"} />}
                      size={"lg"}
                      padding={"31px 20px"}
                      display={"flex"}
                      justifyContent={"flex-start"}
                      borderRadius={0}
                      variant={"ghost"}
                    >
                      Contact Us
                    </Button>
                  </Link>

                  {!isAuthenticatedUser && (
                    <Flex justifyContent={"space-evenly"} pt={"7px"}>
                      <Link
                        to={"/register-new"}
                        className="nav-menu-login-link"
                      >
                        <div className="nav-register-btn">
                          <div>
                            <Img src={sylvr_register} />
                          </div>
                          <div>Register|Login</div>
                        </div>
                      </Link>
                    </Flex>
                  )}
                </Stack>
              </Box>
            </Collapse>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;
