// const axiosHeader = {
//   "Access-Control-Allow-Origin": process.env.REACT_APP_URI_SERVER_BASE_URL,
//   "Access-Control-Allow-Credentials": "true",
//   "Content-Type": "application/json",
// };

const axiosHeader = {
  "Content-Type": "application/json",
};

export default axiosHeader;
