import { VStack } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Routes, useNavigate } from "react-router-dom";
import "./App.scss";
// import Navbar from "./components/Header/Navbar";
// import Navbar from "./components/HeaderRefactored/Navbar";
import { useEffect } from "react";
import { refreshUser } from "./actions/user/userActions";
import {
  HomeRoutes,
  LoginRoutes,
  AgencyRoutes,
  OrganisationRoutes,
  NotFoundRoute,
  AgencyRoutes2,
  ClientRoutes2,
  IndustryRoutes,
} from "./routes";
import { getToken } from "./utils/helperFunction";
import PaymentModal from "./components/PaymentModal";
import { GoogleOAuthProvider } from '@react-oauth/google';
import Layout from "./shared/Layout/Layout";

const getWindowSize = () => {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
};


function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticatedUser, isFirstLogin, type } = useSelector(
    (state) => state.userReducer,
  );
  console.log('===ser dtaa', isAuthenticatedUser, isFirstLogin, type);
  // useEffect(() => {
  //   if (getToken()) {
  //     dispatch(refreshUser(navigate, isFirstLogin));
  //   }
  // }, [dispatch, navigate, isFirstLogin]);

  useEffect(() => {
    const head = document.querySelector("head");
    const scriptElement = document.createElement("script");
    var scripts =
      '(function(c,l,a,r,i,t,y){ c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)}; t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i; y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y); })(window, document, "clarity", "script", "lce2x3jchi");';
    scriptElement.setAttribute("type", "text/javascript");
    scriptElement.innerText = scripts;
    head?.appendChild(scriptElement);
  }, []);

  useEffect(() => {
    const handleWindowResize = () => {
      dispatch({
        type: "SET_WINDOW_SIZE",
        payload: getWindowSize(),
      });
    };

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, [dispatch]);

  return (
    <>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_URI_GCP_OAUTH_CLIENTID}>
      <PaymentModal />
        {/* <VStack className="app-container" spacing={0}> */}
        {/* <Navbar /> */}

        <div>
      <Routes>
        {HomeRoutes}
              {IndustryRoutes}
        {isAuthenticatedUser ? (
          <>
                  {type === 'agency' && <>{AgencyRoutes2}</>}

            {type === 'client' && <>{ClientRoutes2}</>}

                  {type === 'organisation' && <>{OrganisationRoutes}</>}
            {NotFoundRoute}
          </>
        ) : (
          <>{LoginRoutes}</>
        )}
      </Routes>
      </div>
        {/* </VStack> */}
      </GoogleOAuthProvider>
    </>
  );
}

export default App;
