import {
  Box,
  Center,
  Image,
} from '@chakra-ui/react';
import lock from '../../assets/images/lock.svg';

const UnlockRegistration = (props) => {
  return (
    <Box
      id={'segment_risks_observations_registrations'}
      onClick={() => {
        props.setModelRegistrationPopUp(true);
      }}
    >
      <Center id={'unlock_registration_container'}>
        <Box width={'40px'} height={'40px'} mt={4} mr={'-17px'}>
          <Image src={lock} alt="lock" />
        </Box>
        <Box id={'registration_unlock'}>Register to unlock more insights</Box>
      </Center>
    </Box>
  );
};

export default UnlockRegistration;
