import axios from "axios";
import axiosHeader from "../axiosHeader";
import { toast } from "react-toastify";
import {
  getClusterwiseApi,
  getClusterwiseForIndustryApi,
  getPreviewDataApi,
  getPreviewDataForIndustryApi,
  getProductsApi,
  getSectorsApi,
  loginOrganisationApi,
  logoutOrganisationApi,
  registerOrganisationApi,
} from "../../api/organisationApi";
import { errMsg } from "../../utils/helperFunction";
import { getTrendApi, getTrendForIndustryApi, getZScoreApi, getZScoreForIndustryApi } from "../../api/kpiApi";

const ServerBaseURI = process.env.REACT_APP_URI_SERVER_BASE_URL;

export const getSectorList = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SUBSECTOR_LIST_REQ",
    });

    const data = await getSectorsApi();

    dispatch({
      type: "GET_SUBSECTOR_LIST_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });

    dispatch({
      type: "GET_SUBSECTOR_LIST_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getProductsList = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_PRODUCTS_LIST_REQ",
    });

    const data = await getProductsApi();

    dispatch({
      type: "GET_PRODUCTS_LIST_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });

    dispatch({
      type: "GET_PRODUCTS_LIST_FAIL",
      payload: { error: true, message },
    });
  }
};

export const registerOrganisation =
  (
    {
      name,
      designation,
      organization,
      addressLine1,
      addressLine2,
      pincode,
      mobileno,
      email,
      pswd,
      products,
    },
    navigate
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: 'REGISTER_ORG_REQ',
      });

      const reqBody = {
        name,
        designation,
        organization,
        addressLine1,
        addressLine2,
        pincode,
        mobileno,
        email,
        products,
        password: pswd,
      };

      const data = await registerOrganisationApi(reqBody);

      toast.success('Organisation registered successfully', {
        position: 'bottom-center',
        toastId: data.client_id,
      });

      dispatch({
        type: 'REGISTER_ORG_SUCCESS',
        payload: data,
      });

      navigate('/');
    } catch (err) {
      const message = errMsg(err);

      dispatch({
        type: 'REGISTER_ORG_FAIL',
        payload: { error: true, message },
      });

      toast.error(message, {
        position: 'bottom-center',
        toastId: message,
      });
    }
  };

export const loginOrganisation =
  ({ email, pswd }, navigate) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "LOGIN_ORG_REQ",
      });

      const reqBody = {
        email,
        password: pswd,
      };

      const data = await loginOrganisationApi(reqBody);

      dispatch({
        type: "LOGIN_ORG_SUCCESS",
        payload: data,
      });

      toast.success(`Welcome back ${data.companyName}`, {
        position: "bottom-center",
        toastId: data.id,
      });

      navigate(`/organisation/${data.id}`);
    } catch (err) {
      const message = errMsg(err);

      dispatch({
        type: "LOGIN_ORG_FAIL",
        payload: { success: false, message },
      });

      toast.error(message, {
        position: "bottom-center",
        toastId: message,
      });
    }
  };

export const logoutOrganisation = (navigate) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGOUT_ORG_REQ",
    });

    const { message } = await logoutOrganisationApi();

    dispatch({
      type: "LOGOUT_ORG_SUCCESS",
    });
    navigate(`/`);

    toast.success(message, {
      toastId: "log-out-succ",
      position: "bottom-center",
    });
  } catch (err) {
    const message = errMsg(err);

    dispatch({
      type: "LOGOUT_ORG_FAIL",
      payload: { message },
    });

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};

export const forgotPswdOrganisation = (email, setData) => async (dispatch) => {
  try {
    dispatch({
      type: "FORGOT_PSWD_REQ",
    });

    const { data } = await axios.post(
      `${ServerBaseURI}/api/v1/client/password/forgot`,
      {
        email,
      },
      {
        headers: axiosHeader,
      }
    );

    toast.success(data.message, {
      toastId: data.message,
      position: "bottom-center",
    });

    dispatch({
      type: "FORGOT_PSWD_SUCCESS",
    });

    setData({ email: "" });
  } catch (err) {
    const { success, message } = err.response.data;

    dispatch({
      type: "FORGOT_PSWD_FAIL",
      payload: { success, message },
    });

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};

export const resetPasswordOrganisation =
  (token, { pswd, cnfPswd }, navigate) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "RESET_PSWD_REQ",
      });

      const { data } = await axios.put(
        `${ServerBaseURI}/api/v1/client/password/reset/${token}`,
        {
          pswd,
          cnfPswd,
        },
        {
          headers: axiosHeader,
        }
      );

      dispatch({
        type: "RESET_PSWD_ORG_SUCCESS",
        payload: data.user,
      });

      toast.success("Password reset successfully", {
        position: "bottom-center",
        toastId: "Password reset successfully",
      });

      navigate(`/organisation/${data.user._id}`);
    } catch (err) {
      const { success, message } = err.response.data;

      dispatch({
        type: "RESET_PSWD_FAIL",
        payload: { success, message },
      });

      toast.error(message, {
        toastId: message,
        position: "bottom-center",
      });
    }
  };

export const getZScoreForClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ORG_ZSCORE_REQ",
    });
    const data = await getZScoreApi({ clientId: id });

    dispatch({
      type: "GET_ORG_ZSCORE_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: "GET_ORG_ZSCORE_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getCompetitiveMapDataForClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_COMP_MAP_REQ",
    });
    const data = await getClusterwiseApi({ clientId: id });

    dispatch({
      type: "GET_COMP_MAP_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: "GET_COMP_MAP_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getAuditSheetDataForClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_AUDIT_SHEET_REQ",
    });
    const data = await getPreviewDataApi({ clientId: id });

    dispatch({
      type: "GET_AUDIT_SHEET_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: "GET_AUDIT_SHEET_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getTrendsForClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ORG_TREND_REQ",
    });
    const data = await getTrendApi({ clientId: id });

    dispatch({
      type: "GET_ORG_TREND_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: "GET_ORG_TREND_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getCompetitiveMapData = (subsector) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_COMP_MAP_REQ",
    });

    const { data } = await axios.get(
      `${ServerBaseURI}/api/v1/client/comp-map/${subsector}`,
      {
        headers: axiosHeader,
      }
    );

    dispatch({
      type: "GET_COMP_MAP_SUCCESS",
      payload: data.data,
    });
  } catch (err) {
    const { success, message } = err.response.data;

    dispatch({
      type: "GET_COMP_MAP_FAIL",
      payload: { success, message },
    });

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};


// Industry Wise
export const getZScoreForIndustry = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_IND_ZSCORE_REQ',
    });
    const data = await getZScoreForIndustryApi({ industryId: id });

    dispatch({
      type: 'GET_IND_ZSCORE_SUCCESS',
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: 'GET_IND_ZSCORE_FAIL',
      payload: { error: true, message },
    });
  }
};

export const getTrendsForIndustry = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_IND_TREND_REQ',
    });
    const data = await getTrendForIndustryApi({ industryId: id });

    dispatch({
      type: 'GET_IND_TREND_SUCCESS',
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: 'GET_IND_TREND_FAIL',
      payload: { error: true, message },
    });
  }
};

export const getCompetitiveMapDataForIndustry = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_COMP_IND_MAP_REQ',
    });
    const data = await getClusterwiseForIndustryApi({ industryId: id });

    dispatch({
      type: 'GET_COMP_IND_MAP_SUCCESS',
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: 'GET_COMP_IND_MAP_FAIL',
      payload: { error: true, message },
    });
  }
};

export const getAuditSheetDataForIndustry = (id) => async (dispatch) => {
  try {
    dispatch({
      type: 'GET_AUDIT_IND_SHEET_REQ',
    });
    const data = await getPreviewDataForIndustryApi({ industryId: id });

    dispatch({
      type: 'GET_AUDIT_IND_SHEET_SUCCESS',
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: 'GET_AUDIT_IND_SHEET_FAIL',
      payload: { error: true, message },
    });
  }
};