import { combineReducers } from "@reduxjs/toolkit";
import homeReducer from "./home/homeReducer";
import userReducer from "./user/userReducer";
import organisationReducer from "./organisation/organisationReducer";
import agencyReducer from "./agency/agencyReducer";
import auditUploadReducer from "./audit/auditUploadReducer";
import auditPreviewReducer from "./audit/auditPreviewReducer";
import auditRawSheetsReducer from "./audit/auditRawSheetsReducer";
import clientReducer from "./client/clientReducer";
import uiReducer from "./ui/uiReducer";
import industryReducer from "./industry/industryReducer"

export default combineReducers({
  homeReducer,
  organisationReducer,
  agencyReducer,
  userReducer,
  auditUploadReducer,
  auditPreviewReducer,
  uiReducer,
  auditRawSheetsReducer,
  clientReducer,
  industryReducer
});
