import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Box, Button, Flex, Heading, Stack } from "@chakra-ui/react";
import { getRechartContainerWidth } from "../../../../utils/common";
import './MetricSpectrum.scss';

const MetricsSpectrum = () => {
  const dispatch = useDispatch();

  const { organisationData } = useSelector(
    (state) => state.organisationReducer
  );
  const { zScore,client } = organisationData;
  const { windowSize } = useSelector((state) => state.uiReducer);

  const [data, setData] = useState("");
  const [sortedData, setSortedData] = useState("");
  const [years, setYears] = useState([]);
  const [selectedYear, setSelectedYear] = useState(client?.auditUploadYears[0]);


  useEffect(() => {
    if (client) {
      setYears(client?.auditUploadYears);
    }
  }, [dispatch, client]);

  useEffect(() => {
    if (client?.auditUploadYears?.length>0 && zScore?.data) {
      
      const yearData = zScore?.data?.filter((item) => {
         if(Number(item?.year) === Number(selectedYear)) {
          return true
         }
         return false
      })
      const {
        ebitda,
        materialsAndEmployees,
        overheadPercentage,
        genAndAdmPercentage,
        intTaxDepPercentage,
        roE,
        roCE,
        roA,
        timesInterestEarned,
        debtRatio,
      } = yearData[0].metrics;
      
      setData([
        {
          name: "ebitda",
          value: ebitda?.zscore,
          tag: ebitda?.label,
          mean: ebitda?.mean,
          stdDev: ebitda?.stdDev
        },
        {
          name: "materials And Employees",
          value: materialsAndEmployees?.zscore,
          tag: materialsAndEmployees?.label,
          mean: materialsAndEmployees?.mean,
          stdDev: materialsAndEmployees?.stdDev
        },
        {
          name: "Overhead Percentage",
          value: overheadPercentage?.zscore,
          tag: overheadPercentage?.label,
          mean: overheadPercentage?.mean,
          stdDev: overheadPercentage?.stdDev
        },
        {
          name: "gen & Adm",
          value: genAndAdmPercentage?.zscore,
          tag: genAndAdmPercentage?.label,
          mean: genAndAdmPercentage?.mean,
          stdDev: genAndAdmPercentage?.stdDev
        },
        {
          name: "int. Tax Depreciation",
          value: intTaxDepPercentage?.zscore,
          tag: intTaxDepPercentage?.label,
          mean: intTaxDepPercentage?.mean,
          stdDev: intTaxDepPercentage?.stdDev
          
        },
        {
          name: "roE",
          value: roE?.zscore,
          tag: roE?.label,
          mean: roE?.mean,
          stdDev: roE?.stdDev
        },
        {
          name: "roCE",
          value: roCE?.zscore,
          tag: roCE?.label,
          mean: roCE?.mean,
          stdDev: roCE?.stdDev
        },
        {
          name: "roA",
          value: roA?.zscore,
          tag: roA?.label,
          mean: roA?.mean,
          stdDev: roA?.stdDev
        },
        {
          name: "Times Interest Earned",
          value: timesInterestEarned?.zscore,
          tag: timesInterestEarned?.label,
          mean: timesInterestEarned?.mean,
          stdDev: timesInterestEarned?.stdDev
        },
        {
          name: "Debt Ratio",
          value: debtRatio?.zscore,
          tag: debtRatio?.label,
          mean: debtRatio?.mean,
          stdDev: debtRatio?.stdDev
        },
      ]);  
    }
  }, [dispatch, zScore, selectedYear]);

  useEffect(() => {
    data && setSortedData(data.sort((a, b) => b.value - a.value));
  }, [dispatch, data]);

  const getBarColor = (tag) => {
    switch (tag) {
      case "Very High":
        return "#87CEFA";

      case "High":
        return "#E0FFFF";

      case "Moderate":
        return "orange";

      case "Low":
        return "light-red";

      case "Very Low":
        return "red";

      default:
        return "#8884d8";
    }
  };

  // custom tooltip for metric spectrum chart
  const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
      const tooltip = payload[0].payload;
      return (
        <div className="metric-spectrum custom-tooltip">
        <p className='label' style={{color: "rgb(127, 127, 127)"}}>{`label: ${label}`}</p>
        <p className="value">{`value: ${tooltip.value.toFixed(3)}`}</p>
        <p>{`mean: ${tooltip.mean.toFixed(3)}`}</p>
        <p>{`stdDev: ${tooltip.stdDev.toFixed(3)}`}</p>
        <p>{`situation: ${tooltip.tag}`}</p>
        {/* Add more content here */}
      </div>
      );
    }
  
    return null;
  };
  
 

  return (
    <>
      {/* {console.log("metSpecData: ", data)} */}
      <Stack>
        {!data && zScore?.error ? (
          <ResponsiveContainer
            width={getRechartContainerWidth(windowSize.innerWidth)}
            aspect={1.2}
            textAlign={"center"}
          >
            <Box
              bg="#B3A7FA1A"
              w="100%"
              p={4}
              color="white"
              display="flex"
              alignItems="center"
            >
              <Heading
                color={"primary.500"}
                w={"full"}
                size={"md"}
                textAlign={"center"}
              >
                {zScore?.error?.message}
              </Heading>
            </Box>
          </ResponsiveContainer>
        ) : (
          <>
            <ResponsiveContainer
              width={getRechartContainerWidth(windowSize.innerWidth)}
              aspect={1.2}
            >
              <BarChart
                data={sortedData}
                layout="vertical"
                maxBarSize={10}
                margin={{
                  top: 15,
                  right: 60,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray={"0.1 0.1"} />
                <XAxis type="number" dataKey={"value"} orientation="top">
                  <Label value="Z- Score" offset={25} position="insideBottom" />
                </XAxis>
                <YAxis
                  type="category"
                  dataKey={"name"}
                  orientation="right"
                  label={{
                    value: "Particulars for evaluation",
                    angle: -90,
                    position: "insideLeft",
                    offset: 110,
                    textAnchor: "middle",
                  }}
                  domain={["ebitda", "materials And Employees", "Overhead Percentage","gen & Adm","int. Tax Depreciation", "Times Interest Earned", "Debt Ratio", "roE","roCE", "roA"]}
                />
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                <ReferenceLine x={0} stroke="#000" />
                <Bar dataKey="value">
                  {sortedData &&
                    sortedData.map((entry, index) => {
                      return <Cell key={index} fill={getBarColor(entry.tag)} />;
                    })}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
            <Flex
              justifyContent={"space-evenly"}
              flexWrap={"wrap"}
              p={"18px 0"}
            >
              {years &&
                years.length > 0 &&
                years.map((year, index) => (
                  <Button
                    key={index}
                    m={"4px"}
                    onClick={() => setSelectedYear(year)}
                    size={"xs"}
                    variant={year === selectedYear ? "solid" : "outline"}
                  >
                    {year}
                  </Button>
                ))}
            </Flex>
          </>
        )}
      </Stack>
    </>
  );
};

export default MetricsSpectrum;
