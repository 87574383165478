import { toast } from "react-toastify";
import { getClientDetailsAPI, getClusterwiseApi } from "../../api/organisationApi";
import { errMsg } from "../../utils/helperFunction";
import { getTrendApi, getZScoreApi } from "../../api/kpiApi";

export const setCurrentClient = (clientId) => (dispatch) => {
  dispatch({ type: "SET_CURRENT_CLIENT", payload: clientId });
};

export const removeCurrentClient = () => (dispatch) => {
  dispatch({ type: "REMOVE_CURRENT_CLIENT" });
};

export const getClientDetails = (clientId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CLIENT_DETAILS_REQ_V1",
    });

    const data = await getClientDetailsAPI({ clientId });

    dispatch({
      type: "GET_CLIENT_DETAILS_SUC_V1",
      payload: data.clientDetails,
    });
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      position: "bottom-center",
      toastId: message,
    });

    dispatch({
      type: "GET_CLIENT_DETAILS_FAIL_V1",
      payload: { error: true, message: message },
    });
  }
};

export const getZScoreForClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CLIENT_ZSCORE_REQ",
    });
    const data = await getZScoreApi({ clientId: id });

    dispatch({
      type: "GET_CLIENT_ZSCORE_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: "GET_CLIENT_ZSCORE_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getTrendsForClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CLIENT_TREND_REQ",
    });
    const data = await getTrendApi({ clientId: id });

    dispatch({
      type: "GET_CLIENT_TREND_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: "GET_CLIENT_TREND_FAIL",
      payload: { error: true, message },
    });
  }
};

export const getCompetitiveMapDataForClient = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CLIENT_COMP_MAP_REQ",
    });
    const data = await getClusterwiseApi({ clientId: id });

    dispatch({
      type: "GET_CLIENT_COMP_MAP_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);
    dispatch({
      type: "GET_CLIENT_COMP_MAP_FAIL",
      payload: { error: true, message },
    });
  }
};
