import { sendRequest } from "../libs/axiosConfig";
import { setToken } from "../utils/helperFunction";

export const refreshUserApi = async () => {
  const { data } = await sendRequest({
    url: `/api/v1/user/refresh`,
  });
  return data;
};

export const logoutApi = async () => {
  const res = await sendRequest({ url: `/api/v1/agency/logout` });
  setToken('');
  return res;
};
