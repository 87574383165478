import { LinkIcon, EmailIcon } from "@chakra-ui/icons";
import { FormErrorMessage, Icon, Image } from "@chakra-ui/react";
import { BsPinMap } from "react-icons/bs";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Stack,
  Box,
} from "@chakra-ui/react";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { CgOrganisation } from "react-icons/cg";
import { MdLocationOn } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import {
  getProductsList,
  getSectorList,
} from "../../actions/user/organisationActions";
import Loader from "../../components/Loader/Loader";
import {
  getClientDetails,
  registerNewClient,
} from "../../actions/user/agencyActions";
import { useNavigate } from "react-router-dom";
import TextInput from "../../components/formikElements/TextInput";
import { getLocationApi, getProductsApi } from "../../api/organisationApi";
import ProductsDropdown from "../../components/ProductsDropdown";
import editIcon from "../../assets/images/edit_icon.png"
import { updateProductsClientApi } from "../../api/agencyApi";
import { toast } from 'react-toastify';

const CreateClient = ({ clientData }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [data, setData] = useState({
    companyName: "",
    email: "",
    regdOffice: "",
    website: "",
    logo: "",
    pincode: null,
    products: [],
  });
  const [productData, setProductData] = useState({
    products: [],
  });
  const [showState, setShowState] = useState(false);
  const [location, setLocation] = useState({});
  const [customError, setCustomError] = useState({});
  const [productEdit, setProductEdit] = useState(false);

  const [clientDetail, setClientDetail] = useState({
    companyName: "",
    email: "",
    regdOffice: "",
    website: "",
    logo: "",
    pincode: "",
    products: [],
  });

  const { loading, clientDetails } = useSelector(
    (state) => state.agencyReducer
  );

  useEffect(() => {
    dispatch(getSectorList());
    dispatch(getProductsList());
  }, [dispatch]);

  // useEffect(() => {
  //   if (clientData) {
  //     dispatch(getClientDetails(clientData.id));
  //   }
  // }, [clientData]);

  useEffect(() => {
    if (clientData) {
      setClientDetail(clientData);
      setProductData({
        products: clientData?.products || [],
      });
    }
  }, [clientData]);

  useEffect(() => {
    if (Object.keys(location).length > 0) {
      setShowState(true);
    } else {
      setShowState(false);
    }
  }, [location]);

  const validationSchema = Yup.object({
    companyName: Yup.string().required(),
    email: Yup.string().email(),
    pincode: Yup.number().required(),

    regdOffice: Yup.string(),
    website: Yup.string().url(),
    logo: Yup.string(),
    products: Yup.array()
      .of(Yup.string())
      .min(3, "At least 3 products are required")
      .max(12, "Maximum of 12 products allowed"),
  });

  const validateProducts = (value) => {
    let error;
    if (value.length < 3) {
      error = "At least 3 products are required";
    }
    if (value.length >= 13) {
      error = "Maximum of 12 products allowed";
    }
    return error;
  };

  const validateAndSetLocation = async (e) => {
    const isValidPinCode = /^[0-9]{6}$/.test(e.target.value) && e.target.value;
    try {
      if (isValidPinCode) {
        setCustomError({});
        const data = await getLocationApi({ pincode: e.target.value });
        setLocation(data);
      } else {
        setLocation({});
        setCustomError({ pincode: "pincode is not valid" });
      }
    } catch (err) {
      console.error(err);
      setCustomError({ pincode: "pincode could not be found" });
    }
  };

  const initialValues = {
    companyName: clientDetail?.companyName || "",
    email: clientDetail?.email || "",
    regdOffice: clientDetail?.regdOffice || "",
    website: clientDetail?.website || "",
    // logo: clientDetail?.logo || "",
    pincode: clientDetail?.pincode || "",
    products: clientDetail?.products || [],
  };

  const handleProductChange = (event, setFieldValue) => {
    // option Array<{value:id,label:text}>
    const selectedValues = Array.isArray(event)
      ? event.map((item) => item.value)
      : [];
    setFieldValue("products", selectedValues);
  };

  const handleUpdate = async () => {
    const updateProductsClient = await updateProductsClientApi({
      clientId: clientData?.id,
      ...productData,
    });
    toast.success("Products updated successfully", {
      position: 'top-center',
    });
    setProductEdit(false);
  }


  const handleProductEdit = () => {
    setProductEdit(true)
  };

  return (
    <>
      <Flex
        className="body-container auth-page"
        justifyContent={"space-evenly"}
        height={"395px"}
        overflowY={"scroll"}
      >
        {loading ? (
          <Loader />
        ) : (
          // formik for form validation
          <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, actions) => {
              dispatch(registerNewClient({ ...values }, navigate));
            }}
            validateOnBlur={true}
          >
            {(formik) => (
              <Stack p={{ base: "0", md: "6" }} width={"full"}>
                {/* <Heading textAlign={"center"} margin={"12px 0 48px 0"}>
                  Company Registration
                </Heading> */}
                <Box className="custom-input">
                  <TextInput
                    name="companyName"
                    placeholder="Enter the name of the company"
                    label="Organization"
                    isRequired={true}
                    focusBorderColor="primary.400"
                    disabled={clientData?.id}
                  ></TextInput>
                </Box>
                <Flex
                  justifyContent={"space-between"}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="pincode"
                      placeholder="Enter the pincode of company location"
                      label="Pincode"
                      isRequired={true}
                      error={customError?.pincode || null}
                      onBlurCallback={(e) => {
                        // Call your function to validate the Pin Code and set showState
                        validateAndSetLocation(e);
                      }}
                      focusBorderColor="primary.400"
                      disabled={clientData?.id}
                    ></TextInput>
                  </Box>
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="email"
                      placeholder="Enter the email of your organisation"
                      label="Email"
                      focusBorderColor="primary.400"
                      disabled={clientData?.id}
                    ></TextInput>
                  </Box>
                </Flex>
                {showState && (
                  <Flex
                    justifyContent={"space-between"}
                    flexDirection={{ base: "column", md: "row" }}
                  >
                    {showState && (
                      <Box
                        width={{ base: "100%", md: "48%" }}
                        mt="5"
                        className="custom-input"
                      >
                        <FormControl maxWidth={"md"} margin={"auto"}>
                          <FormLabel>District</FormLabel>
                          <Input
                            value={location.district}
                            readOnly
                            focusBorderColor="primary.400"
                            // color="primary.600"
                            disabled={clientData?.id}
                          ></Input>
                        </FormControl>
                      </Box>
                    )}

                    {showState && (
                      <Box
                        width={{ base: "100%", md: "48%" }}
                        mt="5"
                        className="custom-input"
                      >
                        <FormControl maxWidth={"md"} margin={"auto"}>
                          <FormLabel>State</FormLabel>
                          <Input
                            value={location.state}
                            readOnly
                            focusBorderColor="primary.400"
                            // color="primary.600"
                            disabled={clientData?.id}
                          ></Input>
                        </FormControl>
                      </Box>
                    )}
                  </Flex>
                )}

                <Flex
                  justifyContent={"space-between"}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Flex
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <Box width="80%">
                      <Field name="products" validate={validateProducts}>
                        {({ field, form }) => (
                          <FormControl
                            maxWidth={"md"}
                            margin={"auto"}
                            isInvalid={
                              form.errors.products && form.touched.products
                            }
                          >
                            <FormLabel>
                              Products
                              <span style={{ color: "#E53E3E" }}>*</span>
                            </FormLabel>
                            <Box>
                              {!productEdit ? (
                                <ProductsDropdown
                                  defaultProductValues={field.value}
                                  fetchDataFunction={getProductsApi}
                                  placeholder="Search Products..."
                                  isMulti
                                  handleOptionChange={(selectedOptions) => {
                                    handleProductChange(
                                      selectedOptions,
                                      form.setFieldValue
                                    );
                                  }}
                                  clientData={clientData}
                                />
                              ) : (
                                <ProductsDropdown
                                  defaultProductValues={field.value}
                                  isEditable={true}
                                  selectedSectorCategory={""}
                                  fetchDataFunction={getProductsApi}
                                  placeholder="Search Products..."
                                  isMulti
                                  handleOptionChange={(selectedOptions) => {
                                    const products = selectedOptions.map(
                                      (val) => val.value
                                    );
                                    if (productData.products.length < 20) {
                                      setProductData((prev) => ({
                                        ...prev,
                                        products,
                                      }));
                                    }
                                  }}
                                  _focus={{
                                    height: "3rem",
                                    borderLeft: "5px solid #30d5c8",
                                  }}
                                />
                              )}
                              {form.errors.products &&
                                form.touched.products && (
                                  <FormErrorMessage name="products">
                                    {form.errors.products}
                                  </FormErrorMessage>
                                )}
                              {/* <div
                                onClick={handleProductEdit}
                                style={{
                                  position: "relative",
                                  left: "-30px",
                                  height: "30px",
                                  // backgroundColor: 'aqua',
                                  cursor: "pointer",
                                }}
                              >
                                <img
                                  src={editIcon}
                                  style={{ width: "150px" }}
                                />
                              </div> */}
                            </Box>
                          </FormControl>
                        )}
                      </Field>
                    </Box>
                    <Box
                      onClick={handleProductEdit}
                      style={{
                        position: "relative",
                        right: "-12px",
                        height: "30px",
                        width: "30px",
                        // backgroundColor: 'aqua',
                        cursor: "pointer",
                      }}
                    >
                      <Image alt="imade-edit" src={editIcon} height="30px" width="30px"/>
                    </Box>
                  </Flex>

                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="regdOffice"
                      placeholder="Enter the registered address of the company"
                      label="Address"
                      focusBorderColor="primary.400"
                      disabled={clientData?.id}
                    ></TextInput>
                  </Box>
                </Flex>

                <Flex
                  justifyContent={"space-between"}
                  flexDirection={{ base: "column", md: "row" }}
                >
                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="website"
                      placeholder="Enter the URL of the company's website"
                      label="URL"
                      focusBorderColor="primary.400"
                      disabled={clientData?.id}
                    ></TextInput>
                  </Box>

                  <Box
                    width={{ base: "100%", md: "48%" }}
                    mt="5"
                    className="custom-input"
                  >
                    <TextInput
                      name="logo"
                      placeholder="Upload file"
                      type="file"
                      label="Logo"
                      focusBorderColor="primary.400"
                      disabled={clientData?.id}
                    ></TextInput>
                  </Box>
                </Flex>

                <Flex justifyContent={"space-evenly"} p={"36px 0 0 0"}>
                  <Flex>
                    {productEdit ? (
                      <Button
                        variant={"solid"}
                        m={"0 12px"}
                        onClick={handleUpdate}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        variant={"solid"}
                        m={"0 12px"}
                        onClick={formik.handleSubmit}
                        isDisabled={clientData?.id}
                      >
                        Submit
                      </Button>
                    )}
                    {/* <Link to={`/agency/clients`}>
                      <Button variant={"ghost"} m={"0 12px"}>
                        Go Back
                      </Button>
                    </Link> */}
                  </Flex>
                </Flex>
              </Stack>
            )}
          </Formik>
        )}
      </Flex>
    </>
  );
};

export default CreateClient;
