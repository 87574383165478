import axios from "axios";
import axiosHeader from "../axiosHeader";
import { toast } from "react-toastify";
import {
  getClientDetailsAPI,
  getClientsApi,
  loginApi,
  logoutApi,
  registerAgencyApi,
  registerClientApi,
} from "../../api/agencyApi";
import { errMsg } from "../../utils/helperFunction";
import { refreshUserWithoutNavigate } from "./userActions";
import { addAdvisoryApi } from "../../api/userApi";

const ServerBaseURI = process.env.REACT_APP_URI_SERVER_BASE_URL;

export const registerAgency =
  ({ designation, name, email, pswd, udin, avatar, is_ca, mrn }, navigate) =>
    async (dispatch) => {
      try {
        dispatch({
          type: "REGISTER_AGENCY_REQ",
        });
        const reqBody = {
          name,
          email,
          password: pswd,
          designation,
          udin,
          avatar,
          is_ca,
          mrn,
        };
        const data = await registerAgencyApi(reqBody);

        dispatch({
          type: "REGISTER_AGENCY_SUCCESS",
          payload: data,
        });
        // navigate the user to ,login page after registration
        navigate("/");
        toast.success(`Thanks for registering ${data?.name}`, {
          toastId: data?.agency_id,
          position: "bottom-center",
        });
      } catch (err) {
        const message = errMsg(err);

        dispatch({
          type: "REGISTER_AGENCY_FAIL",
          payload: {
            error: true,
            message,
          },
        });

        toast.error(message, {
          toastId: message,
          position: "bottom-center",
        });
      }
    };

export const loginAgency =
  ({ email, pswd }, navigate) =>
    async (dispatch) => {
      try {
        dispatch({
          type: "LOGIN_AGENCY_REQ",
        });

        const reqBody = {
          email,
          password: pswd,
        };

        const data = await loginApi(reqBody);

        dispatch({
          type: "LOGIN_AGENCY_SUCCESS",
          payload: data,
        });
        navigate(`/agency`);
        toast.success(`Welcome back ${data.name}`, {
          toastId: data.id,
          position: "bottom-center",
        });
      } catch (err) {
        const message = errMsg(err);

        toast.error(message, {
          toastId: message,
          position: "bottom-center",
        });

        dispatch({
          type: "LOGIN_AGENCY_FAIL",
          payload: { error: true, message },
        });
      }
    };

export const logoutAgency = (navigate) => async (dispatch) => {
  try {
    dispatch({
      type: "LOGOUT_AGENCY_REQ",
    });

    const { message } = await logoutApi();

    dispatch({
      type: "LOGOUT_AGENCY_SUCCESS",
    });

    dispatch(resetRegisteredClientsData());

    navigate(`/`);
    toast.success(message, {
      toastId: "log-out-succ",
      position: "bottom-center",
    });
  } catch (err) {
    const message = errMsg(err);

    dispatch({
      type: "LOGOUT_AGENCY_FAIL",
      payload: { error: true, message },
    });

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};

export const forgotPasswordAgency = (email, setData) => async (dispatch) => {
  try {
    dispatch({
      type: "FORGOT_PSWD_REQ",
    });

    const { data } = await axios.post(
      `${ServerBaseURI}/api/v1/agency/forgotpassword`,
      {
        email,
      },
      {
        headers: axiosHeader,
      }
    );

    toast.success(data.message, {
      position: "bottom-center",
      toastId: data.message,
    });

    dispatch({
      type: "FORGOT_PSWD_SUCCESS",
    });

    setData({ email: "" });
  } catch (err) {
    const { error, message } = err;

    dispatch({
      type: "FORGOT_PSWD_FAIL",
      payload: { error, message },
    });

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};

export const resetPasswordAgency =
  (token, { pswd, cnfPswd }, navigate) =>
    async (dispatch) => {
      try {
        dispatch({
          type: "RESET_PSWD_REQ",
        });

        const { data } = await axios.put(
          `${ServerBaseURI}/api/v1/agency/password/reset/${token}`,
          {
            password: pswd,
            cnfpassword: cnfPswd,
          },
          {
            headers: axiosHeader,
          }
        );

        dispatch({
          type: "RESET_PSWD_AGENCY_SUCCESS",
          payload: data.user,
        });

        toast.success("Password reset successfully", {
          position: "bottom-center",
          toastId: "Password reset successfully",
        });

        navigate(`/agency/${data.user._id}`);
      } catch (err) {
        const { error, message } = err;

        dispatch({
          type: "RESET_PSWD_FAIL",
          payload: { error, message },
        });

        toast.error(message, {
          toastId: message,
          position: "bottom-center",
        });
      }
    };

export const registerNewClient =
  (
    { companyName, email, regdOffice, website, logo, pincode, products },
    navigate
  ) =>
    async (dispatch) => {
      try {
        dispatch({
          type: "CREATE_AGENCY_REQ",
        });
        const reqBody = {
          companyName,
          email,
          regdOffice,
          website,
          logo,
          pincode,
          products,
        };
        const data = await registerClientApi(reqBody);

        toast.success("Client registered successfully", {
          position: "bottom-center",
          toastId: "client-reg-suc",
        });

        // dispatch
        dispatch({
          type: "LOGIN_AGENCY_SUCCESS",
          payload: data,
        });

        dispatch(refreshUserWithoutNavigate())

        // navigate
        navigate(`/agency`);
      } catch (err) {
        const message = errMsg(err);

        toast.error(message, {
          position: "bottom-center",
          toastId: message,
        });

        dispatch({
          type: "CREATE_AGENCY_FAIL",
          payload: { error: true, message },
        });
      }
    };

export const resetRegisteredClientsData = () => (dispatch) => {
  dispatch({
    type: "GET_REGISTERED_CLIENTS_DATA_SUC",
    payload: [],
  });
};

export const getRegisteredClientsData = () => async (dispatch) => {
  try {
    dispatch({
      type: "GET_REGISTERED_CLIENTS_DATA_REQ",
    });

    const data = await getClientsApi();

    dispatch({
      type: "GET_REGISTERED_CLIENTS_DATA_SUC",
      payload: data,
    });
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      position: "bottom-center",
      toastId: message,
    });

    dispatch({
      type: "GET_REGISTERED_CLIENTS_DATA_FAIL",
      payload: { error: true, message: message },
    });
  }
};

export const getClientDetails = (clientId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CLIENT_DETAILS_REQ",
    });

    const data = await getClientDetailsAPI({ clientId });

    dispatch({
      type: "GET_CLIENT_DETAILS_SUC",
      payload: data.clientData,
    });
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      position: "bottom-center",
      toastId: message,
    });

    dispatch({
      type: "GET_CLIENT_DETAILS_FAIL",
      payload: { error: true, message: message },
    });
  }
};

export const addAdvisory =
  (
    { clientId, description}
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "CREATE_ADDADVISORY_REQ",
      });
      const reqBody = {
        clientId,
        description,
      };
      const data = await addAdvisoryApi(reqBody);

      toast.success("Add advisory successfully", {
        position: "bottom-center",
        toastId: "client-reg-suc",
      });

      // dispatch
      dispatch({
        type: "CREATE_ADDADVISORY_SUCCESS",
        payload: data,
      });
    } catch (err) {
      const message = errMsg(err);

      toast.error(message, {
        position: "bottom-center",
        toastId: message,
      });

      dispatch({
        type: "CREATE_ADDADVISORY_FAIL",
        payload: { error: true, message },
      });
    }
  };
