import {
  Box,
} from "@chakra-ui/react";



const ObjectivesDashboard = () => {
  return (
    <Box>
      <iframe
        width="100%"
        height="580"
        src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FWz4yZrT8AJfWewNt3Jbw7t%2F%255BEMBED%255D-Objectives-Dashboard%3Fnode-id%3D124-2%26t%3DzBZrcgZi6hWoX5MR-1%26scaling%3Dmin-zoom%26content-scaling%3Dfixed%26page-id%3D0%253A1"
        title="Objectives Dashboard"
      ></iframe>
    </Box>
  );
};

export default ObjectivesDashboard;
