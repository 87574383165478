import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  organisationData: {
    client: {
      id: "",
      companyName: "",
      homestate: "",
      auditUploadYears: [],
      email: "",
      sector: "",
    },
    zScore: {
      loading: false,
      error: null,
      data: null,
    },
    trend: {
      loading: false,
      error: null,
      data: null,
    },
    competitiveMap: {
      loading: false,
      error: null,
      data: null,
    },
    auditsheet :{
      loading: false,
      error: null,
      data: null,
    }
  },

  productsList: {
    loading: false,
    error: null,
    products: [],
  },

  // competitive map
  competitiveMap: {
    loading: false,
    error: null,
    data: null,
  },
};

const organisationReducer = createReducer(initialState, {
  GET_SUBSECTOR_LIST_REQ: (state, actions) => {
    state.loading = true;
  },
  GET_SUBSECTOR_LIST_SUCCESS: (state, actions) => {
    state.loading = false;
    state.sectors = actions.payload;
    state.error = null;
  },
  GET_SUBSECTOR_LIST_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },

  GET_PRODUCTS_LIST_REQ: (state, actions) => {
    state.productsList.loading = true;
  },
  GET_PRODUCTS_LIST_SUCCESS: (state, actions) => {
    state.productsList.loading = false;
    state.productsList.products = actions.payload;
    state.productsList.error = null;
  },
  GET_PRODUCTS_LIST_FAIL: (state, actions) => {
    state.productsList.loading = false;
    state.productsList.error = actions.payload;
  },

  GET_ORG_DETAILS_REQ: (state, actions) => {
    state.loading = true;
  },
  GET_ORG_DETAILS_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.organisationData = actions.payload;
  },
  GET_ORG_DETAILS_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },
  GET_COMP_MAP_REQ: (state, actions) => {
    state.organisationData.competitiveMap = {
      ...initialState.organisationData.competitiveMap,
      loading: true,
    };
  },
  GET_COMP_MAP_SUCCESS: (state, actions) => {
    const loading = false;
    const error = null;
    const data = actions.payload;
    state.organisationData.competitiveMap = { loading, error, data };
  },
  GET_COMP_MAP_FAIL: (state, actions) => {
    state.organisationData.competitiveMap = {
      ...initialState.organisationData.competitiveMap,
      loading: false,
      error: actions.payload,
    };
  },
  GET_AUDIT_SHEET_REQ: (state, actions) => {
    state.organisationData.auditsheet = {
      ...initialState.organisationData.auditsheet,
      loading: true,
    };
  },
  GET_AUDIT_SHEET_SUCCESS: (state, actions) => {
    const loading = false;
    const error = null;
    const data = actions.payload;
    state.organisationData.auditsheet = { loading, error, data };
  },
  GET_AUDIT_SHEET_FAIL: (state, actions) => {
    state.organisationData.auditsheet = {
      ...initialState.organisationData.auditsheet,
      loading: false,
      error: actions.payload,
    };
  },

  GET_ORG_ZSCORE_REQ: (state, actions) => {
    state.organisationData.zScore = {
      ...initialState.organisationData.zScore,
      loading: true,
    };
  },
  GET_ORG_ZSCORE_SUCCESS: (state, actions) => {
    const loading = false;
    const error = null;
    const data = actions.payload;
    state.organisationData.zScore = { loading, error, data };
  },
  GET_ORG_ZSCORE_FAIL: (state, actions) => {
    state.organisationData.zScore = {
      ...initialState.organisationData.zScore,
      loading: false,
      error: actions.payload,
    };
  },
  GET_ORG_TREND_REQ: (state, actions) => {
    state.organisationData.trend = {
      ...initialState.organisationData.trend,
      loading: true,
    };
  },
  GET_ORG_TREND_SUCCESS: (state, actions) => {
    const loading = false;
    const error = null;
    const data = actions.payload;
    state.organisationData.trend = { loading, error, data };
  },
  GET_ORG_TREND_FAIL: (state, actions) => {
    state.organisationData.trend = {
      ...initialState.organisationData.trend,
      loading: false,
      error: actions.payload,
    };
  },
});

export default organisationReducer;
