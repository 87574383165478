import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  audit: {
    loading: false,
    error: null,
    data: null,
    clientId: null
  },
};

const auditUploadReducer = createReducer(initialState, {

  UPLOAD_AUD_SHEET_REQ: (state, actions) => {
    state.audit.loading = true;
  },
  UPLOAD_AUD_SHEET_SUC: (state, actions) => {
    state.audit.loading = false;
    state.audit.error = null;
    state.audit.data = actions.payload;
  },
  UPLOAD_AUD_SHEET_FAIL: (state, actions) => {
    state.audit.loading = false;
    state.audit.error = actions.payload;
  },
  GET_CLIENT_ID_FOR_AUDTI_SHEET: (state, actions) =>{
    state.audit.clientId = actions.payload.clientId;
  }
});

export default auditUploadReducer;
