import {
  Tabs,
  TabList,
  Tab,
  Box,
  Flex
} from "@chakra-ui/react";
import { TYPES_OF_SEGMENT_METRICS } from "../../constants/metrics";
import downarrowmenu from "../../assets/images/arrow-down.png";
import uparrowmenu from "../../assets/images/arrow-up.png";
import sales from "../../assets/images/sales.png";
import distribution from "../../assets/images/distribution.png";
import hr from "../../assets/images/hr.png";
import production from "../../assets/images/production.png";
import operation from "../../assets/images/operation.png";
import financing from "../../assets/images/financing.png";

export const SegmentTabs = ({
  setCurrentSegmentMetric,
  currentSegmentMetric,
  navigation,
}) => {
  const imageObject = {
    Sales: sales,
    Distribution: distribution,
    HR: hr,
    Production: production,
    Operations: operation,
    Finance: financing,
  };
  return (
    <Box id={"segment_risks_type"} className="leftNavOptions">
      {TYPES_OF_SEGMENT_METRICS?.map((data) => (
        <Box
          m={"0px"}
          w={"100%"}
          style={{
            border: "1.3px solid var(--sylvr-text-color-selected)",
            backgroundColor: "#F2F7F7",
            borderRadius: "4px",
            padding: "8px",
            marginBottom: "15px",
          }}
        >
          <Box>
            <Flex
              style={{
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                paddingBottom: "5px",
              }}
              onClick={() => {
                setCurrentSegmentMetric(data?.label);
              }}
            >
              <Box className="sylvr-title-menu">{data?.label}</Box>
              <Box className="menu-icon">
                <img
                  src={
                    currentSegmentMetric === data?.label
                      ? uparrowmenu
                      : downarrowmenu
                  }
                  alt="menu-arrow"
                  className="metric-arrow"
                />
              </Box>
            </Flex>
          </Box>
          {currentSegmentMetric === data?.label &&
            data?.value?.map((value) => (
              <Box
                m={"0px"}
                w={"100%"}
                style={{
                  backgroundColor: "var(--sylvr-text-color-selected)",
                  borderRadius: "4px",
                  marginBottom: "10px",
                }}
              >
                <Flex justifyContent={"flex-start"} alignItems={"center"}>
                  <Box style={{ marginLeft: "9px" }}>
                    <img src={imageObject[value?.label]} />
                  </Box>
                  <Box className="sylvr-subtitle-menu">{value?.label}</Box>
                </Flex>
                {value?.value?.map((row, index) => (
                  <>
                    <Box
                      className={
                        index === value?.value?.length - 1
                          ? "sylvr-subtitle-menu-card metric-bottomBorderRadius"
                          : "sylvr-subtitle-menu-card"
                      }
                    >
                      <Box className="sylvr-subtitle-label">{row?.label}</Box>
                    </Box>
                    {index != value?.value?.length - 1 && (
                      <Box
                        style={{
                          // margin: "0px 5px",
                          backgroundColor: "white",
                        }}
                      >
                        <Box
                          style={{
                            border: "1.5px solid #E6E6E6",
                            margin: "0px 5px",
                          }}
                        ></Box>
                      </Box>
                    )}
                  </>
                ))}
              </Box>
            ))}
        </Box>
      ))}
      {/* {false &&
        Object.keys(TYPES_OF_SYSTEMATIC_METRICS)?.map?.((key, index) => (
          <Box m={"0px"} w={"100%"}>
            <Text
              key={key}
              className={
                currentSegmentRiskTab ===
                TYPES_OF_SYSTEMATIC_METRICS?.[key]?.label
                  ? "riskSubTabs selected"
                  : "riskSubTabs"
              }
              _hover={{}}
              onClick={() => {
                setCurrentSegmentRiskTab(
                  TYPES_OF_SYSTEMATIC_METRICS?.[key]?.label
                );
              }}
              fontSize="var(--sub-nav)"
            >
              {TYPES_OF_SYSTEMATIC_METRICS?.[key]?.label}====
            </Text>
          </Box>
        ))} */}
    </Box>
  );
};
