import { toast } from "react-toastify";
import { sendRequest } from "../libs/axiosConfig";
import { errMsg, setToken } from "../utils/helperFunction";

export const initiatePayment = async (reqBody) => {
  try {
    const {
      subscriptionName,
      paymentFrequency,
      noOfDashboards,
      amount,
      agencyId,
      paymentMethod,
    } = reqBody;

    const response = await sendRequest({
      url: `/api/v1/agency/buynewdashboard?paymentMethod=${paymentMethod}`,
      method: "POST",
      body: {
        subscriptionName,
        paymentFrequency,
        noOfDashboards,
        amount,
        agencyId,
      },
    });

    return response.data;
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};

export const verifyPayment = async (reqBody) => {
  try {
    const {
      razorpay_payment_id,
      razorpay_order_id,
      razorpay_signature,
      paymentMethod,
    } = reqBody;

    const response = await sendRequest({
      url: `/api/v1/agency/verifyDashboardPayment?paymentMethod=${paymentMethod}`,
      method: "POST",
      body: {
        razorpay_payment_id,
        razorpay_order_id,
        razorpay_signature,
      },
    });

    return response.data;
  } catch (err) {
    const message = errMsg(err);

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};

export const getClientDetailsAPI = async (reqBody) => {
  const { clientId } = reqBody;

  const { data } = await sendRequest({
    url: `/api/v1/client/${clientId}`,
    method: "POST",
    body: { clientId },
  });
  return data;
};

// register agency
export const registerAgencyApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: "/api/v1/agency/register",
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

export const loginApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/agency/login`,
    method: "POST",
    body: { ...reqBody },
  });

  setToken(data?.token);
  return data;
};

export const logoutApi = async () => {
  const res = await sendRequest({ url: `/api/v1/agency/logout` });
  // setToken("");
  localStorage.removeItem("token");
  localStorage.removeItem("isAuthenticatedUser");
  localStorage.removeItem("type");
  return res;
};

export const registerClientApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/agency/createclient`,
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

export const updateProductsClientApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: `/api/v1/agency/updateproductsclient`,
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

export const getClientsApi = async () => {
  const { data } = await sendRequest({
    url: `/api/v1/agency/clients`,
  });
  return data;
};

export const getAllClientMetricFromCacheAPI = async () => {
  const { data } = await sendRequest({
    url: `/api/v1/kpi/cache/metric`,
  });
  return data;
};

export const refreshClientMetricCacheAPI = async (clientId) => {
  const { data } = await sendRequest({
    url: `/api/v1/kpi/${clientId}/cache/metric/refresh`,
  });
  return data;
};

export const registerEarlyBirdApi = async (reqBody) => {
  const { message, data } = await sendRequest({
    url: "/api/v1/agency/earlybird",
    method: "POST",
    body: { ...reqBody },
  });
  return { message, data };
};

export const getEarlyBirdApi = async () => {
  const { data } = await sendRequest({
    url: `/api/v1/agency/earlybirdDetail`,
  });
  return data;
};
