/* import React from "react";
import AreaChart from "./AreaChart";
import { calculateUnitsForValues } from "../../../utils/helperFunction";

const TotalInterest = ({ data, chartDesc, xAxisLabel, yAxisLabel }) => {
  if (!xAxisLabel) {
    xAxisLabel = "Period";
  }

  const valuesWithUnits = calculateUnitsForValues(data);
  yAxisLabel = `Total debt [units in ${valuesWithUnits}]`;
  return (
    <>
      <AreaChart
        data={data}
        heading="Total Interest"
        chartDesc={chartDesc}
        xAxisLabel={xAxisLabel}
        yAxisLabel={yAxisLabel}
      />
    </>
  );
};

export default TotalInterest;
 */

import React from "react";
import {
  calculateUnit,
  calculateUnitsForValues,
  formatCurrencyValue,
} from "../../../utils/helperFunction";
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Heading, Stack } from "@chakra-ui/react";

const TotalInterest = ({ data, xAxisLabel, yAxisLabel }) => {
  if (!xAxisLabel) {
    xAxisLabel = "Period";
  }

  const valuesWithUnits = calculateUnitsForValues(
    data.map((d) => ({ value: d.total }))
  );
  yAxisLabel = `TS [units in ${valuesWithUnits}]`;

  const colors = ["#C3C0ED", "#C0FFB2", "#ADECFF"];

  const keys = data.map((d) => Object.keys(d.value))[0];

  data = data.map((d, i) => ({
    ...d.value,
    year: d.year,
  }));

  return (
    <Stack className="home-page-card" alignItems="flex-start">
      <Heading
        size={"md"}
        color={"primary.500"}
        bg="#fff"
        mb="2"
        p="5px 10px 8px"
        display="inline-block"
        textColor="#1E1E1E"
        borderRadius={4}
      >
        Total Interest
      </Heading>
      <ResponsiveContainer width={500} className="chart-container" height={300}>
        <LineChart
          data={data}
          width="100%"
          height="100%"
          margin={{ top: 20, right: 20, bottom: 10, left: 60 }}
        >
          <XAxis
            dataKey="year"
            label={{ value: "Period", position: "insideBottom", dy: 10 }}
          />
          <YAxis
            tickFormatter={formatCurrencyValue}
            label={{
              value: yAxisLabel,
              angle: -90,
              position: "Start",
              dx: -45,
            }}
          />
          <Tooltip itemStyle={{ color: "#000" }} />
          {keys?.map?.((k, i) => (
            <Line
              key={i}
              type="monotone"
              dataKey={k}
              stroke={colors[i % colors.length]}
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default TotalInterest;
