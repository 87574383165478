import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Text,
  Heading,
  Flex,
} from "@chakra-ui/react";
import {
  getAuditSheetDataForClient,
  getCompetitiveMapData
} from "../../actions/user/organisationActions";

import {
  TYPES_OF_METRICS,
  TYPES_OF_SEGMENT_METRICS,
} from "../../constants/metrics";
import { useNavigate, useParams } from "react-router-dom";
import {
  getClientDetails,
  getZScoreForClient,
  getTrendsForClient,
  getCompetitiveMapDataForClient,
} from "../../actions/client/clientActions";
import { AgencyTabs } from "../../shared/AgencyTabs/AgencyTabs";
import { AGENCY_TABS } from "../../utils/Stringconstant";
import { getClientDetailsAPI } from "../../api/agencyApi";
import CompetitiveRisk from "../Industry/CompetitiveRisk/CompetitiveRisk";

const RiskAssessment = () => {
  const { clientId } = useParams();
  const navigation = useNavigate();
  const [clientData, setclientData] = useState({});
  const [currentClientTab, setCurrentClientTab] = useState(
    AGENCY_TABS.SEGMENT_RISK
  );
  const toggleIndustryTab = (clientTabValue) => {
    setCurrentClientTab(AGENCY_TABS[clientTabValue]);
  };
  const { industryId } = useSelector((state) => state.industryReducer);
  const dispatch = useDispatch();
  const RIGHT_INTERPRETATIONS = [
    "align/maintain/manage/ensure/control",
    "focus/develop/invest/implement/conduct",
    "streamline/optimize/support",
    "enhance/improve/increase/srengthen/expand",
    "reduce/minimize/eliminate",
    "negotiate/restructure",
  ];

  const METRIC_BG_COLORS = ["#E4F0DD", "#FFF1E1", "#FDE7E7"];
  const METRIC_COLORS = ["#2D6C08", "#BE4400", "#B31B1B"];
  const riskSideMenu = [
    "growthAndReturns",
    "efficiencyAndLeverage",
    "marginAndCost",
  ];
  const [currentType, setCurrentType] = useState(riskSideMenu[0]);

  const [metricColors, setMetricColors] = useState([]);

  const [currentMetric, setCurrentMetric] = useState(
    TYPES_OF_METRICS[riskSideMenu[0]]?.value?.[0]?.label
  );
  const [currentCompetativeMetric, setCurrentCompetativeMetric] = useState(
    TYPES_OF_SEGMENT_METRICS[0].label
  );
  const [patternData, setPatternData] = useState(null);

  const [patternValue, setPatternValue] = useState([]);

  const applyColorsToMetrics = (dataList) => {
    const coloredList = dataList.map((_, index) => {
      const colorIndex = index % METRIC_BG_COLORS.length;
      return {
        bgColor: METRIC_BG_COLORS[colorIndex],
        textColor: METRIC_COLORS[colorIndex],
      };
    });

    return coloredList;
  };

  const coloredDataList = applyColorsToMetrics(
    TYPES_OF_METRICS?.[currentType]?.value
  );

  const { organisationData } = useSelector(
    (state) => state.organisationReducer
  );

  useEffect(() => {
    if (organisationData?.client?.subsector) {
      dispatch(getCompetitiveMapData(organisationData.client.subsector));
    }
  }, [dispatch, organisationData?.client?.subsector]);

  useEffect(() => {
    clientId && dispatch(getClientDetails(clientId));
    clientId && dispatch(getZScoreForClient(clientId));
    clientId && dispatch(getTrendsForClient(clientId));
    clientId && dispatch(getCompetitiveMapDataForClient(clientId));
    clientId && dispatch(getAuditSheetDataForClient(clientId));
  }, [dispatch, clientId]);

  useEffect(() => {
    if (clientId) {
      getClientDetailsAPI({ clientId }).then((results) => {
        setclientData(results?.clientDetails);
      });
    }
  }, [clientId]);

  return (
    <>
      <Flex id={"industry_name"}>
        <Flex>
          <Heading
            fontSize="var(--sylvr-h1-fs)"
            fontWeight="var(--sylvr-h1-fw)"
            color="var(--sylvr-text-color-selected)"
            pr={6}
          >
            {clientData?.companyName}
          </Heading>
          <Text
            fontStyle="italic"
            borderLeft="3px solid #E6E6E6"
            borderRight="3px solid #E6E6E6"
            px={6}
            fontSize="var(--sylvr-h2-fs)"
            fontWeight="var(--sylvr-h2-fw)"
            color="var(--sylvr-text-color-selected)"
          >
            {clientData?.sector}
          </Text>
        </Flex>
      </Flex>
      <Box id={"industry_tab"}>
        <AgencyTabs
          toggleIndustryTab={toggleIndustryTab}
          currentClientTab={currentClientTab}
          industryId={industryId}
          navigation={navigation}
        />
      </Box>
      <Box id={"industry_container"}>
        <CompetitiveRisk
          metricColors={metricColors}
          setMetricColors={setMetricColors}
        />
      </Box>
    </>
  );
};

export default RiskAssessment;
