import { Box, Stack } from "@chakra-ui/react";
import LineChart from "../LineChart";

export default function SystematicFactorsLineChart({ data, heading, dimension }) {
  return (
    <Stack className="sylvr-graph-card">
      <Box className="sylvr-graph-card-heading">
        <Box className="sylvr-graph-card-heading-label">{heading}</Box>
      </Box>
      <Box>
        <LineChart data={data} dimension={dimension} />
      </Box>
    </Stack>
  );
}
