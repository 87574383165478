import {
  ArrowBackIcon,
  LockIcon,
  UnlockIcon,
  ViewIcon,
  ViewOffIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { resetPasswordOrganisation } from "../../../actions/user/organisationActions";
import Loader from "../../../components/Loader/Loader";

const OrgResetPassword = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state.userReducer);

  const [pswdShow, setPswdShow] = useState(false);
  const [cnfPswdShow, setCnfPswdShow] = useState(false);

  const [data, setData] = useState({
    pswd: "",
    cnfPswd: "",
  });

  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };

  const submitHandler = (e) => {
    if (data.pswd !== data.cnfPswd) {
      toast.error("Password and confirm password must be same", {
        position: "bottom-center",
        toastId: "pswd-cnfPswd-mismatch",
      });
    } else {
      dispatch(resetPasswordOrganisation(params.token, { ...data }, navigate));
    }
  };

  return (
    <>
      <Box className="body-container" overflowY={"auto"}>
        {loading ? (
          <Loader />
        ) : (
          <Stack
            className="auth-page"
            justifyContent={"space-evenly"}
            alignItems={"center"}
            minH={"720px"}
            h={"full"}
          >
            <Stack
              w={"fit-content"}
              p={[3, 6, 8]}
              spacing={6}
              borderRadius={"9px"}
              alignItems={"center"}
            >
              <Flex justifyContent={"space-between"}>
                <Link to={"/organisation/login"}>
                  <Button variant={"ghost"}>
                    <ArrowBackIcon fontSize={"30px"} />
                  </Button>
                </Link>
                <Heading
                  width={"full"}
                  textAlign={"center"}
                  margin={"0 0 30px 0"}
                >
                  Reset Your Password
                </Heading>
              </Flex>

              <FormControl width={["xs", "sm", "md"]} margin={"auto"}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<UnlockIcon />}
                  />
                  <Input
                    type={pswdShow ? "text" : "password"}
                    name="pswd"
                    onChange={changeHandler}
                    value={data.pswd}
                    placeholder="New Password"
                    focusBorderColor={"primary.400"}
                    color={"primary.600"}
                  />
                  <InputRightElement>
                    <Button
                      variant={"ghost"}
                      onClick={() => setPswdShow(!pswdShow)}
                    >
                      {pswdShow ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <FormControl width={["xs", "sm", "md"]} margin={"auto"}>
                <InputGroup>
                  <InputLeftElement
                    pointerEvents="none"
                    children={<LockIcon />}
                  />
                  <Input
                    type={cnfPswdShow ? "text" : "password"}
                    name="cnfPswd"
                    onChange={changeHandler}
                    value={data.cnfPswd}
                    placeholder="Confirm New Password"
                    focusBorderColor={"primary.400"}
                    color={"primary.600"}
                  />
                  <InputRightElement>
                    <Button
                      variant={"ghost"}
                      onClick={() => setCnfPswdShow(!cnfPswdShow)}
                    >
                      {cnfPswdShow ? <ViewOffIcon /> : <ViewIcon />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </FormControl>

              <Flex justifyContent={"space-evenly"} p={"18px 0 40px 0"}>
                <Button variant={"solid"} onClick={submitHandler}>
                  Submit
                </Button>
              </Flex>
            </Stack>
          </Stack>
        )}
      </Box>
    </>
  );
};

export default OrgResetPassword;
