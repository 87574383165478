import axios from "axios";
import { toast } from "react-toastify";

const ServerBaseURI = process.env.REACT_APP_URI_SERVER_BASE_URL;

export const getAuditPreviewData = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_AUD_PREV_REQ",
    });

    const { data } = await axios.get(
      `${ServerBaseURI}/api/v1/audit/preview/${id}`,
    );

    dispatch({
      type: "GET_AUD_PREV_SUCCESS",
      payload: data,
    });
  } catch (err) {
    const { success, message } = err.response.data;

    dispatch({
      type: "GET_AUD_PREV_FAIL",
      payload: { success, message },
    });

    toast.error(message, {
      toastId: message,
      position: "bottom-center",
    });
  }
};
