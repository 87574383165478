import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  error: null,
  rawAuditSheetsData: {
    unit: null,
    rawBalanceSheet: [],
    rawProfitLossSheet: []
  },
};

const rawAuditSheetsReducer = createReducer(initialState, {
  GET_RAW_AUDIT_SHEET_REQ: (state) => {
    state.loading = true;
  },
  GET_RAW_AUDIT_SHEET_SUC: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.rawAuditSheetsData = actions.payload;
  },
  GET_RAW_AUDIT_SHEET_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },
});

export default rawAuditSheetsReducer;
