import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Flex,
  Heading,
  Img,
  Button,
} from "@chakra-ui/react";
import "./IndustryDashboard.scss";
import SegmentRisks from "./SegmentRisks/SegmentRisks";
// import lock from "../../assets/images/lock.svg";
import RegistrationPopUp from "./RegistrationPopUp";
import { getsectorWiseResult } from "../../actions/home/homeActions";
import { getSectorByIdApi } from "../../api/organisationApi";
import ClientProfile from "./ClientProfile/ClientProfile";
import CompetitiveRisk from "./CompetitiveRisk/CompetitiveRisk";
import ObjectivesDashboard from "./ObjectivesDashboard/ObjectivesDashboard";
import { useNavigate, useParams } from "react-router-dom";
import bulb_on from "../../assets/images/bulb-on.png";
import cross_icon from "../../assets/images/cross-icon.png";
import { useTourContext } from "../../customHooks/useTourContext";
import { AgencyTabs } from "../../shared/AgencyTabs/AgencyTabs";
import { AGENCY_TABS } from "../../utils/Stringconstant";

const IndustryDashboard = () => {
  const { industryId } = useParams();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { is_tour, setIsTour } = useTourContext();
  const { isAuthenticatedUser } = useSelector((state) => state.userReducer);
  const { sectorName } = useSelector((state) => state.industryReducer);
  const [sector, setSector] = useState({});
  const [loading, setLoading] = useState(true);
  const [welcome_industry, setWelcomeIndustry] = useState(true);
  const [start_tour, setStartTour] = useState(false);
  const [observe_trends, setObserveTrends] = useState(false);
  const [financial_growth, setFinancialGrowth] = useState(false);
  const [benchmark_now, setBenchmarkNow] = useState(false);
  const [currentClientTab, setCurrentClientTab] = useState(
    AGENCY_TABS.SEGMENT_RISK
  );
  const [modelRegistrationPopUp, setModelRegistrationPopUp] = useState(false);
  const toggleIndustryTab = (clientTabValue) => {
    console.log("======toggleIndustryTab=clientTabValue", clientTabValue);
    setCurrentClientTab(AGENCY_TABS[clientTabValue]);
  };
  useEffect(() => {
    dispatch(getsectorWiseResult());
  }, [dispatch]);

  useEffect(() => {
    if (!isAuthenticatedUser) {
      setIsTour(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    if (industryId) {
      getSectorByIdApi(industryId).then((results) => {
        setSector(results);
        setLoading(false);
      });
    }
  }, [industryId]);
  useEffect(() => {console.log("=======currentClientTab", currentClientTab);}, [currentClientTab]);

  const addClientButtonHandler = () => {
    navigation("/agency/client-new");
  };
console.log("=======currentClientTab", currentClientTab);
  return (
    <>
      {is_tour && welcome_industry && (
        <Box className="industry-welcome">
          <Box className="welcome-sylvr">Welcome to Sylvr</Box>
          <Box className="single-dashboard">
            Take a quick product tour to advance your Advisory practice
          </Box>
          <Box className="btn-block">
            <Box
              className="btn-later"
              onClick={() => {
                setWelcomeIndustry(false);
                setStartTour(false);
                setIsTour(false);
                localStorage.setItem("is_tour", false);
              }}
            >
              Not now, later
            </Box>
            <Box
              className="btn-start-tour"
              onClick={() => {
                setWelcomeIndustry(false);
                setStartTour(true);
              }}
            >
              Start tour
            </Box>
          </Box>
        </Box>
      )}
      <Flex id={"industry_name"}>
        {start_tour && (
          <Box className="heading-tooplip add-big-picture">
            <Flex p="15px">
              <Flex w="100%" justifyContent={"space-between"}>
                <Flex>
                  <Box>
                    <Img src={bulb_on} alt="alert" />
                  </Box>
                  <Box className="add-client">From details to big picture</Box>
                </Flex>
                <Box
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setStartTour(false);
                    setIsTour(false);
                  }}
                >
                  <Img src={cross_icon} alt="cross_icon" />
                </Box>
              </Flex>
            </Flex>
            <Box className="single-dashboard">
              Practice dashboard keeps you on top of registered clients' needs.
            </Box>
            <Box className="next-block">
              <Box
                className="next-btn"
                onClick={() => {
                  setStartTour(false);
                  setObserveTrends(true);
                }}
              >
                Next
              </Box>
            </Box>
          </Box>
        )}
        <Heading
          fontSize="var(--sylvr-h2-fs)"
          fontWeight="var(--sylvr-h2-fw)"
          color="var(--sylvr-text-color-selected)"
          width={"100%"}
        >
          {sectorName}
        </Heading>
        <Box>
          {benchmark_now && (
            <Box className="benchmark_now-tooplip add-segments-picture benchmark_now-position">
              <Flex padding="15px 15px 0px">
                <Flex w="100%" justifyContent={"space-between"}>
                  <Flex>
                    <Box>
                      <Img src={bulb_on} alt="alert" />
                    </Box>
                    <Box className="add-client">
                      Unlock client insights in minutes
                    </Box>
                  </Flex>
                  <Box
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setBenchmarkNow(false);
                      setIsTour(false);
                    }}
                  >
                    <Img src={cross_icon} alt="cross_icon" />
                  </Box>
                </Flex>
              </Flex>
              <Box className="single-dashboard">
                Add client details, offerings, location, etc. to map comparative
                companies. Get started for valuable insights.
              </Box>

              <Box className="trends-bar">
                <Box className="benchmark_now-bar-fill"></Box>
              </Box>
              <Box className="next-block">
                <Box
                  className="next-btn"
                  onClick={() => {
                    setFinancialGrowth(true);
                    setBenchmarkNow(false);
                  }}
                >
                  Benchmark Now
                </Box>
              </Box>
            </Box>
          )}
          <Button
            bg={"var(--sylvr-text-color-selected)"}
            color={"#162421"}
            m={5}
            onClick={addClientButtonHandler}
          >
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="#162421"
              xmlns="http://www.w3.org/2000/svg"
              style={{ marginRight: "8px", color: "#162421" }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10.2857 3.85714C10.2857 4.88012 9.87934 5.8612 9.15598 6.58455C8.43263 7.30791 7.45155 7.71429 6.42857 7.71429C5.40559 7.71429 4.42451 7.30791 3.70116 6.58455C2.9778 5.8612 2.57143 4.88012 2.57143 3.85714C2.57143 2.83416 2.9778 1.85309 3.70116 1.12973C4.42451 0.406376 5.40559 0 6.42857 0C7.45155 0 8.43263 0.406376 9.15598 1.12973C9.87934 1.85309 10.2857 2.83416 10.2857 3.85714ZM13.8214 9.64286C13.9481 9.64286 14.0735 9.6678 14.1904 9.71626C14.3074 9.76472 14.4137 9.83575 14.5033 9.92529C14.5928 10.0148 14.6639 10.1211 14.7123 10.2381C14.7608 10.3551 14.7857 10.4805 14.7857 10.6071V12.8571H17.0357C17.2915 12.8571 17.5367 12.9587 17.7176 13.1396C17.8984 13.3204 18 13.5657 18 13.8214C18 14.0772 17.8984 14.3224 17.7176 14.5033C17.5367 14.6841 17.2915 14.7857 17.0357 14.7857H14.7857V17.0357C14.7857 17.2915 14.6841 17.5367 14.5033 17.7176C14.3224 17.8984 14.0772 18 13.8214 18C13.5657 18 13.3204 17.8984 13.1396 17.7176C12.9587 17.5367 12.8571 17.2915 12.8571 17.0357V14.7857H10.6071C10.3514 14.7857 10.1061 14.6841 9.92529 14.5033C9.74445 14.3224 9.64286 14.0772 9.64286 13.8214C9.64286 13.5657 9.74445 13.3204 9.92529 13.1396C10.1061 12.9587 10.3514 12.8571 10.6071 12.8571H12.8571V10.6071C12.8571 10.3514 12.9587 10.1061 13.1396 9.92529C13.3204 9.74445 13.5657 9.64286 13.8214 9.64286ZM6.42857 9C8.34814 9 10.0723 9.84214 11.25 11.1767V11.25H10.6071C10.0369 11.2495 9.48262 11.4386 9.03155 11.7875C8.58049 12.1364 8.2582 12.6254 8.11539 13.1775C7.97258 13.7296 8.01735 14.3135 8.24265 14.8374C8.46796 15.3612 8.86101 15.7953 9.36 16.0714H0.642857C0.472361 16.0714 0.308848 16.0037 0.188289 15.8831C0.0677294 15.7626 0 15.5991 0 15.4286C0 13.7236 0.677294 12.0885 1.88288 10.8829C3.08848 9.67729 4.72361 9 6.42857 9Z"
                fill="#162421"
              />
            </svg>
            Add Clients
          </Button>
        </Box>
      </Flex>
      <Box id={"industry_tab"}>
        <AgencyTabs
          toggleIndustryTab={toggleIndustryTab}
          currentClientTab={currentClientTab}
          industryId={industryId}
          navigation={navigation}
        />
      </Box>

      <Box id={"industry_container"}>
        {currentClientTab === AGENCY_TABS.CLIENT_BACKGROUND && (
          <ClientProfile
            setModelRegistrationPopUp={setModelRegistrationPopUp}
            modelRegistrationPopUp={modelRegistrationPopUp}
          />
        )}
        {currentClientTab === AGENCY_TABS.SEGMENT_RISK && (
          <SegmentRisks
            setObserveTrends={setObserveTrends}
            observe_trends={observe_trends}
            financial_growth={financial_growth}
            setFinancialGrowth={setFinancialGrowth}
            benchmark_now={benchmark_now}
            setBenchmarkNow={setBenchmarkNow}
          />
        )}
        {currentClientTab === AGENCY_TABS.COMPETITIVE_RISK && (
          <CompetitiveRisk
            industryId={industryId}
            setModelRegistrationPopUp={setModelRegistrationPopUp}
            modelRegistrationPopUp={modelRegistrationPopUp}
          />
        )}
        {currentClientTab === AGENCY_TABS.RISK_MODELLING && (
          <ObjectivesDashboard />
        )}
      </Box>
      <RegistrationPopUp
        setModelRegistrationPopUp={setModelRegistrationPopUp}
        modelRegistrationPopUp={modelRegistrationPopUp}
      />
    </>
  );
};

export default IndustryDashboard;
