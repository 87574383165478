import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  clientId: null,
  client: {
    details: {
      loading: false,
      error: null,
      data: null,
    },
    zScore: {
      loading: false,
      error: null,
      data: null,
    },
    trend: {
      loading: false,
      error: null,
      data: null,
    },
    competitiveMap: {
      loading: false,
      error: null,
      data: null,
    },
  },
};

const clientReducer = createReducer(initialState, {
  SET_CURRENT_CLIENT: (state, actions) => {
    state.clientId = actions.payload;
  },
  REMOVE_CURRENT_CLIENT: (state) => {
    state.clientId = null;
  },
  GET_CLIENT_DETAILS_REQ_V1: (state) => {
    state.client.details.loading = true;
  },
  GET_CLIENT_DETAILS_SUC_V1: (state, action) => {
    state.client.details.loading = false;
    state.client.details.data = action.payload;
  },
  GET_CLIENT_DETAILS_FAIL_V1: (state, payload) => {
    state.client.details.loading = false;
    state.client.details.error = payload;
  },

  GET_CLIENT_ZSCORE_REQ: (state, actions) => {
    state.client.zScore = {
      ...initialState.client.zScore,
      loading: true,
    };
  },
  GET_CLIENT_ZSCORE_SUCCESS: (state, actions) => {
    const loading = false;
    const error = null;
    const data = actions.payload;
    state.client.zScore = { loading, error, data };
  },
  GET_CLIENT_ZSCORE_FAIL: (state, actions) => {
    state.client.zScore = {
      ...initialState.client.zScore,
      loading: false,
      error: actions.payload,
    };
  },
  GET_CLIENT_TREND_REQ: (state, actions) => {
    state.client.trend = {
      ...initialState.client.trend,
      loading: true,
    };
  },
  GET_CLIENT_TREND_SUCCESS: (state, actions) => {
    const loading = false;
    const error = null;
    const data = actions.payload;
    state.client.trend = { loading, error, data };
  },
  GET_CLIENT_TREND_FAIL: (state, actions) => {
    state.client.trend = {
      ...initialState.client.trend,
      loading: false,
      error: actions.payload,
    };
  },
  GET_CLIENT_COMP_MAP_REQ: (state, actions) => {
    state.client.competitiveMap = {
      ...initialState.client.competitiveMap,
      loading: true,
    };
  },
  GET_CLIENT_COMP_MAP_SUCCESS: (state, actions) => {
    const loading = false;
    const error = null;
    const data = actions.payload;
    state.client.competitiveMap = { loading, error, data };
  },
  GET_CLIENT_COMP_MAP_FAIL: (state, actions) => {
    state.client.competitiveMap = {
      ...initialState.client.competitiveMap,
      loading: false,
      error: actions.payload,
    };
  },
});

export default clientReducer;
