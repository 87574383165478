import { createContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getPopularSectorsResult } from "../actions/home/homeActions";
import { setCurrentIndustry } from '../actions/industry/industryActions';

const SectorsContext = createContext({});
export const SectorsContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { popularSectors } = useSelector((state) => state.homeReducer);
  const [sectorsData, setSectorsData] = useState([]);

  useEffect(() => {
    dispatch(getPopularSectorsResult());
  }, [dispatch]);

  useEffect(() => {
    if (popularSectors?.data && popularSectors.data.length > 0) {
      var sortedSectors = popularSectors.data
        ?.slice()
        .sort((a, b) => b.totalClients - a.totalClients);
      setSectorsData(sortedSectors);
      dispatch(
        setCurrentIndustry({
          industryId: sortedSectors[0].id,
          sectorName: sortedSectors[0].sector,
          totalClients: sortedSectors[0].totalClients,
        })
      );
    }
  }, [popularSectors.data, dispatch]);

  return (
    <SectorsContext.Provider
      value={{
        sectorsData,
      }}
    >
      {children}
    </SectorsContext.Provider>
  );
};

export default SectorsContext;
