import {
  ArrowBackIcon,
  LockIcon,
  UnlockIcon,
  ViewIcon,
  ViewOffIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Stack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const OrgUpdatePassword = () => {
  const [currPswdShow, setCurrPswdShow] = useState(false);
  const [pswdShow, setPswdShow] = useState(false);
  const [cnfPswdShow, setCnfPswdShow] = useState(false);

  const [data, setData] = useState({
    currPswd: "",
    pswd: "",
    cnfPswd: "",
  });

  const changeHandler = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setData({ ...data, [name]: value });
  };

  const submitHandler = (e) => {
    console.log(data);
  };

  return (
    <>
      <Box className="body-container" overflowY={"auto"}>
        <Stack
          className="auth-page"
          justifyContent={"space-evenly"}
          alignItems={"center"}
          minH={"720px"}
          h={"full"}
        >
          <Stack
            w={"fit-content"}
            p={[3, 6, 8]}
            spacing={6}
            borderRadius={"9px"}
            alignItems={"center"}
          >
            <Flex justifyContent={"space-between"}>
              <Link to={"/organisation/me"}>
                <Button variant={"ghost"}>
                  <ArrowBackIcon fontSize={"30px"} />
                </Button>
              </Link>
              <Heading
                width={"full"}
                textAlign={"center"}
                margin={"0 0 30px 0"}
              >
                Update Your Password
              </Heading>
            </Flex>

            <FormControl width={["xs", "sm", "md"]} margin={"auto"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<UnlockIcon />}
                />
                <Input
                  type={currPswdShow ? "text" : "password"}
                  name="currPswd"
                  onChange={changeHandler}
                  value={data.currPswd}
                  placeholder="Current Password"
                  focusBorderColor={"primary.400"}
                  color={"primary.600"}
                />
                <InputRightElement>
                  <Button
                    variant={"ghost"}
                    onClick={() => setCurrPswdShow(!currPswdShow)}
                  >
                    {currPswdShow ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl width={["xs", "sm", "md"]} margin={"auto"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<UnlockIcon />}
                />
                <Input
                  type={pswdShow ? "text" : "password"}
                  name="pswd"
                  onChange={changeHandler}
                  value={data.pswd}
                  placeholder="New Password"
                  focusBorderColor={"primary.400"}
                  color={"primary.600"}
                />
                <InputRightElement>
                  <Button
                    variant={"ghost"}
                    onClick={() => setPswdShow(!pswdShow)}
                  >
                    {pswdShow ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <FormControl width={["xs", "sm", "md"]} margin={"auto"}>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<LockIcon />}
                />
                <Input
                  type={cnfPswdShow ? "text" : "password"}
                  name="cnfPswd"
                  onChange={changeHandler}
                  value={data.cnfPswd}
                  placeholder="Confirm New Password"
                  focusBorderColor={"primary.400"}
                  color={"primary.600"}
                />
                <InputRightElement>
                  <Button
                    variant={"ghost"}
                    onClick={() => setCnfPswdShow(!cnfPswdShow)}
                  >
                    {cnfPswdShow ? <ViewOffIcon /> : <ViewIcon />}
                  </Button>
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <Flex justifyContent={"space-evenly"} p={"18px 0 40px 0"}>
              <Button variant={"solid"} onClick={submitHandler}>
                Submit
              </Button>
            </Flex>
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default OrgUpdatePassword;
