import { sendRequest } from "../libs/axiosConfig";

export const getRegionWiseResultApi = async () => {
    const { data } = await sendRequest({
      url: `/api/v1/kpi/regionwise`,
    });
    return data;
};

export const getSectorWiseResultApi = async () => {
  const { data } = await sendRequest({
    url: `/api/v1/kpi/sectorwise`,
  });
  return data;
}

export const getPopularSectorsResultApi = async () => {
  const { data } = await sendRequest({
    url: `/api/v1/kpi/homesectors`,
  });
  return data;
}

export const getZScoreApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: "/api/v1/kpi/zScore",
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

export const getTrendApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: "/api/v1/kpi/trend",
    method: "POST",
    body: { ...reqBody },
  });
  return data;
};

export const getZScoreForIndustryApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: '/api/v1/kpi/industryzScore',
    method: 'POST',
    body: { ...reqBody },
  });
  return data;
};

export const getTrendForIndustryApi = async (reqBody) => {
  const { data } = await sendRequest({
    url: '/api/v1/kpi/industrytrend',
    method: 'POST',
    body: { ...reqBody },
  });
  return data;
};
