import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Flex,
  HStack,
  Text,
  Grid,
  GridItem,
  Center,
  Image,
  VStack,
  Img,
  Switch,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
// import Select from "react-select";
import {
  TYPES_OF_SEGMENT_METRICS,
  TYPES_OF_SYSTEMATIC_METRICS,
  // TYPES_OF_METRICS,
} from "../../../constants/metrics";
import SystematicFactorsStackedAreaChart from "../../../components/charts/SystematicFactorsCharts/SystematicFactorsStackedAreaChart";
import SystematicFactorsStackedBarChart from "../../../components/charts/SystematicFactorsCharts/SystematicFactorsStackedBarChart";
import SystematicFactorsLineChart from "../../../components/charts/SystematicFactorsCharts/SystematicFactorsLineChart";
import TotalWorkingCapital from "../../Home/aggregateChartsSection/TotalWorkingCapital";
import Loader from "../../../components/Loader/Loader";
// import info from "../../../assets/images/info.svg"
import lock from "../../../assets/images/lock.svg";
import RegistrationPopUp from "../RegistrationPopUp";
import IndustryObservations from "../../../components/IndustryObservations/IndustryObservations";
import UnlockRegistration from "../../../shared/UnlockRegistration/UnlockRegistration";
import bulb_on from "../../../assets/images/bulb-on.png";
import cross_icon from "../../../assets/images/cross-icon.png";
import AdvisoryNotes from "../../../components/AdvisoryNotes/AdvisoryNotes";
import { useTourContext } from "../../../customHooks/useTourContext";
import { useNavigate } from "react-router-dom";
import downarrowmenu from "../../../assets/images/arrow-down.png";
import uparrowmenu from "../../../assets/images/arrow-up.png";
import sales from "../../../assets/images/sales.png";
import distribution from "../../../assets/images/distribution.png";
import hr from "../../../assets/images/hr.png";
import production from "../../../assets/images/production.png";
import operation from "../../../assets/images/operation.png";
import financing from "../../../assets/images/financing.png";
import { SegmentTabs } from "../../../shared/AgencyTabs/SegmentTabs";

const FinancingAndGrowth = ({ graphData, isAuthenticatedUser }) => {
  return (
    <>
      {/* area */}
      <Box w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalShareholdersEquity}
          heading="Total Shareholders Equity"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      {/* bar */}
      <Box w="100%">
        <SystematicFactorsStackedBarChart
          data={graphData.totalDebt}
          heading="Total Debt"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      {/* line */}
      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        <SystematicFactorsLineChart
          data={graphData.totalSales}
          heading="Total Sales"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      {/* area */}
      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        <SystematicFactorsStackedAreaChart
          data={graphData.totalEbitda}
          heading="Total EBITDA"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>
    </>
  );
};
const Assets = ({ graphData, isAuthenticatedUser }) => {
  return (
    <>
      {/* area */}
      <GridItem>
        <SystematicFactorsStackedAreaChart
          data={graphData.totalTangibleAssets}
          heading="Total Tangible Assets"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>

      {/* bar */}
      <GridItem>
        <SystematicFactorsStackedBarChart
          data={graphData.totalIntangibleAssets}
          heading="Total Intangible Assets"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>

      {/* area */}
      <GridItem filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        <SystematicFactorsStackedAreaChart
          data={graphData.totalFinancialAssets}
          heading="Total Financial Assets"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>

      {/* bar */}
      <GridItem filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        <SystematicFactorsStackedBarChart
          data={graphData.totalLongTermAssetsUnderDevelopment}
          heading="Total Long Term Assets under development"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>
    </>
  );
};

const CostHeads = ({ graphData, isAuthenticatedUser }) => {
  return (
    <>
      {/* area */}
      <GridItem>
        <SystematicFactorsStackedAreaChart
          data={graphData.totalMaterialsCost}
          heading="Total Materials Cost"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>

      {/* line */}
      <GridItem>
        <SystematicFactorsLineChart
          data={graphData.totalInterest}
          heading="Total Interest"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>

      {/* line */}
      <GridItem filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        <SystematicFactorsLineChart
          data={graphData.totalEmployeeCost}
          heading="Total Employee Cost"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>

      {/* bar */}
      <GridItem filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        <SystematicFactorsStackedBarChart
          data={graphData.totalTax}
          heading="Total Tax"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>
    </>
  );
};

const Trade = ({ graphData, isAuthenticatedUser }) => {
  const averageInventorySorted = [...graphData.averageInventory]
    .sort((a, b) => a - b)
    .splice(1);

  const averageInventory = averageInventorySorted.map((d, i) => ({
    value: {
      averageInventory:
        (d.value.currentInventory + d.value.previousInventory) / 2,
    },
    year: d.year,
  }));

  return (
    <>
      {/* composite */}
      <GridItem>
        <TotalWorkingCapital
          data={graphData.totalWorkingCapital}
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>

      {/* line */}
      <GridItem>
        <SystematicFactorsLineChart
          data={averageInventory}
          heading="Average Inventory"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>

      {/* area */}
      <GridItem filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        <SystematicFactorsStackedAreaChart
          data={graphData.totalTradeReceivables}
          heading="Total Trade Receivables"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>

      {/* bar */}
      <GridItem filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        <SystematicFactorsStackedBarChart
          data={graphData.totalTradePayables}
          heading="Total Trade Payables"
          dimension={{ width: "100%", height: "230px" }}
        />
      </GridItem>
    </>
  );
};

const sectorWiseMetricKeys = [
  "totalShareholdersEquity",
  "totalDebt",
  "totalSales",
  "totalEbitda",
  "totalTangibleAssets",
  "totalIntangibleAssets",
  "totalFinancialAssets",
  "totalLongTermAssetsUnderDevelopment",
  "totalMaterialsCost",
  "totalInterest",
  "totalEmployeeCost",
  "totalTax",
  "averageInventory",
  "totalWorkingCapital",
  "totalTradeReceivables",
  "totalTradePayables",
];

const sectorWiseMetricKeysMapper = [
  {
    key: "totalShareholdersEquity",
    label: "Total Shareholders Equity",
    holder: "Financing & Growth",
    sublevel: [
      {
        skey: "equityShareCapital",
        slabel: "Equity Share Capital",
      },
      {
        skey: "otherEquity",
        slabel: "Other Equity",
      },
    ],
  },
  {
    key: "totalDebt",
    label: "Total Debt",
    holder: "Financing & Growth",
    sublevel: [
      {
        skey: "ltBorrowings",
        slabel: "Long Term Borrowings",
      },
      {
        skey: "stBorrowings",
        slabel: "Short Term Borrowings",
      },
    ],
  },
  {
    key: "totalSales",
    label: "Total Sales",
    holder: "Financing & Growth",
    sublevel: [
      {
        skey: "income",
        slabel: "Income",
      },
    ],
  },
  {
    key: "totalEbitda",
    label: "Total EBITDA",
    holder: "Financing & Growth",
    sublevel: [
      {
        skey: "financeCosts",
        slabel: "Finance Costs",
      },
      {
        skey: "profitOrLossBeforeEIT",
        slabel: "Profit Or Loss BeforeEIT",
      },
      {
        skey: "depreciationAndAmortisationExpense",
        slabel: "Depreciation And Amortisation Expense",
      },
    ],
  },
  {
    key: "totalTangibleAssets",
    label: "Total Tangible Assets",
    holder: "Assets",
    sublevel: [
      {
        skey: "investmentProperties",
        slabel: "Investment Properties",
      },
      {
        skey: "propertyPlantAndEquipment",
        slabel: "Property Plant And Equipment",
      },
      {
        skey: "rightOfUseAssets",
        slabel: "Right Of UseAssets",
      },
    ],
  },
  {
    key: "totalIntangibleAssets",
    label: "Total Intangible Assets",
    holder: "Assets",
    sublevel: [
      {
        skey: "goodwill",
        slabel: "Goodwill",
      },
    ],
  },
  {
    key: "totalFinancialAssets",
    label: "Total Financial Assets",
    holder: "Assets",
    sublevel: [
      {
        skey: "bankBalancesOtherThan32",
        slabel: "Bank Balances Other Than 32",
      },
      {
        skey: "cashAndCashEquivalents",
        slabel: "Cash And Cash Equivalents",
      },
      {
        skey: "currInvestments",
        slabel: "Current Investments",
      },
      {
        skey: "currLoans",
        slabel: "Current Loans",
      },
    ],
  },
  {
    key: "totalLongTermAssetsUnderDevelopment",
    label: "Total Long Term Assets under development",
    holder: "Assets",
    sublevel: [
      {
        skey: "capitalWorkInProgress",
        slabel: "Capital Work In Progress",
      },
      {
        skey: "intangibleAssetsUnderDevelopment",
        slabel: "Intangible Assets Under Development",
      },
    ],
  },
  {
    key: "totalMaterialsCost",
    label: "Total Materials Cost",
    holder: "Cost Heads",
    sublevel: [
      {
        skey: "changesInStockOfFinishedGoodsWIPAndSIT",
        slabel: "Changes In Stock Of Finished Goods WIP And SIT",
      },
      {
        skey: "costOfMaterialsConsumed",
        slabel: "Cost Of Materials Consumed",
      },
      {
        skey: "purchasesOfStockInTrade",
        slabel: "Purchases Of Stock InTrade",
      },
    ],
  },
  {
    key: "totalInterest",
    label: "Total Interest",
    holder: "Cost Heads",
    sublevel: [
      {
        skey: "financeCosts",
        slabel: "Finance Costs",
      },
    ],
  },
  {
    key: "totalEmployeeCost",
    label: "Total Employee Cost",
    holder: "Cost Heads",
    sublevel: [
      {
        skey: "employeeBenefitExpense",
        slabel: "Employee Benefit Expense",
      },
    ],
  },
  {
    key: "totalTax",
    label: "Total Tax",
    holder: "Cost Heads",
    sublevel: [
      {
        skey: "currentTax",
        slabel: "Current Tax",
      },
      {
        skey: "deferredTax",
        slabel: "Deferred Tax",
      },
    ],
  },
  {
    key: "averageInventory",
    label: "Average Inventory",
    holder: "Cost Heads",
    sublevel: [
      {
        skey: "currentInventory",
        slabel: "Current Inventory",
      },
      {
        skey: "previousInventory",
        slabel: "Previous Inventory",
      },
    ],
  },
  {
    key: "totalWorkingCapital",
    label: "Total Working Capital",
    holder: "Trade",
    sublevel: [
      {
        skey: "totalCurrentAssets",
        slabel: "Total Current Assets",
      },
      {
        skey: "totalCurrentLiabilities",
        slabel: "Total Current Liabilities",
      },
    ],
  },
  {
    key: "totalTradeReceivables",
    label: "Total Trade Receivables",
    holder: "Trade",
    sublevel: [
      {
        skey: "currTradeReceivables",
        slabel: "Current Trade Receivables",
      },
      {
        skey: "nonCurrTradeReceivables",
        slabel: "Non-Current Trade Receivables",
      },
    ],
  },
  {
    key: "totalTradePayables",
    label: "Total Trade Payables",
    holder: "Trade",
    sublevel: [
      {
        skey: "currTradePayable",
        slabel: "Current Trade Payable",
      },
      {
        skey: "nonCurrTradePayable",
        slabel: "Non-Current Trade Payable",
      },
    ],
  },
];

const TopLine = ({ graphData, isAuthenticatedUser }) => {
  const averageInventorySorted = [...graphData.averageInventory]
    .sort((a, b) => a - b)
    .splice(1);

  const averageInventory = averageInventorySorted.map((d, i) => ({
    value: {
      averageInventory:
        (d.value.currentInventory + d.value.previousInventory) / 2,
    },
    year: d.year,
  }));
  return (
    <>
      {/* line */}
      <Box w="100%">
        <SystematicFactorsLineChart
          data={graphData.totalSales}
          heading="Total Sales"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      {/* area */}
      <Box w="100%">
        <SystematicFactorsStackedAreaChart
          data={graphData.totalEbitda}
          heading="Total EBITDA"
          dimension={{ width: "100%", height: "230px" }}
        />

        {/* area */}
        <GridItem filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
          <SystematicFactorsStackedAreaChart
            data={graphData.totalTradeReceivables}
            heading="Total Trade Receivables"
            dimension={{ width: "100%", height: "230px" }}
          />
        </GridItem>

        {/* line */}
        <GridItem filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
          <SystematicFactorsLineChart
            data={averageInventory}
            heading="Average Inventory"
            dimension={{ width: "100%", height: "230px" }}
          />
        </GridItem>
      </Box>
    </>
  );
};

const BottomLine = ({ graphData, isAuthenticatedUser }) => {
  return (
    <>
      <Box>
        {/* line */}
        <SystematicFactorsLineChart
          data={graphData.totalEmployeeCost}
          heading="Total Employee Cost"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>
      <Box>
        {/* area */}
        <SystematicFactorsStackedAreaChart
          data={graphData.totalMaterialsCost}
          heading="Total Materials Cost"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        {/* bar */}
        <SystematicFactorsStackedBarChart
          data={graphData.totalTradePayables}
          heading="Total Trade Payables"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        {/* area */}
        <SystematicFactorsStackedAreaChart
          data={graphData.totalTangibleAssets}
          heading="Total Tangible Assets"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        {/* bar */}
        <SystematicFactorsStackedBarChart
          data={graphData.totalIntangibleAssets}
          heading="Total Intangible Assets"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        {/* bar */}
        <SystematicFactorsStackedBarChart
          data={graphData.totalLongTermAssetsUnderDevelopment}
          heading="Total Long Term Assets under development"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>
    </>
  );
};

const Financing = ({ graphData, isAuthenticatedUser }) => {
  return (
    <>
      <Box w="100%">
        {/* composite */}
        <TotalWorkingCapital
          data={graphData.totalWorkingCapital}
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>
      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        {/* area */}
        <SystematicFactorsStackedAreaChart
          data={graphData.totalFinancialAssets}
          heading="Total Financial Assets"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        {/* line */}
        <SystematicFactorsLineChart
          data={graphData.totalInterest}
          heading="Total Interest"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        {/* bar */}
        <SystematicFactorsStackedBarChart
          data={graphData.totalTax}
          heading="Total Tax"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        {/* bar */}
        <SystematicFactorsStackedBarChart
          data={graphData.totalDebt}
          heading="Total Debt"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>

      <Box w="100%" filter="auto" blur={isAuthenticatedUser ? "0px" : "2px"}>
        {/* area */}
        <SystematicFactorsStackedAreaChart
          data={graphData.totalShareholdersEquity}
          heading="Total Shareholders Equity"
          dimension={{ width: "100%", height: "230px" }}
        />
      </Box>
    </>
  );
};

const segmentMetricKeysMapper = [
  {
    key: "totalSales",
    label: "Total Sales",
    holder: "Topline",
    sublevel: [
      {
        skey: "income",
        slabel: "Income",
      },
    ],
  },
  {
    key: "totalEbitda",
    label: "Total EBITDA",
    holder: "Topline",
    sublevel: [
      {
        skey: "financeCosts",
        slabel: "Finance Costs",
      },
      {
        skey: "profitOrLossBeforeEIT",
        slabel: "Profit Or Loss BeforeEIT",
      },
      {
        skey: "depreciationAndAmortisationExpense",
        slabel: "Depreciation And Amortisation Expense",
      },
    ],
  },
  {
    key: "totalTradeReceivables",
    label: "Total Trade Receivables",
    holder: "Topline",
    sublevel: [
      {
        skey: "currTradeReceivables",
        slabel: "Current Trade Receivables",
      },
      {
        skey: "nonCurrTradeReceivables",
        slabel: "Non-Current Trade Receivables",
      },
    ],
  },
  {
    key: "averageInventory",
    label: "Average Inventory",
    holder: "Topline",
    sublevel: [
      {
        skey: "currentInventory",
        slabel: "Current Inventory",
      },
      {
        skey: "previousInventory",
        slabel: "Previous Inventory",
      },
    ],
  },

  {
    key: "totalEmployeeCost",
    label: "Total Employee Cost",
    holder: "Bottomline",
    sublevel: [
      {
        skey: "employeeBenefitExpense",
        slabel: "Employee Benefit Expense",
      },
    ],
  },
  {
    key: "totalMaterialsCost",
    label: "Total Materials Cost",
    holder: "Bottomline",
    sublevel: [
      {
        skey: "changesInStockOfFinishedGoodsWIPAndSIT",
        slabel: "Changes In Stock Of Finished Goods WIP And SIT",
      },
      {
        skey: "costOfMaterialsConsumed",
        slabel: "Cost Of Materials Consumed",
      },
      {
        skey: "purchasesOfStockInTrade",
        slabel: "Purchases Of Stock InTrade",
      },
    ],
  },
  {
    key: "totalTradePayables",
    label: "Total Trade Payables",
    holder: "Bottomline",
    sublevel: [
      {
        skey: "currTradePayable",
        slabel: "Current Trade Payable",
      },
      {
        skey: "nonCurrTradePayable",
        slabel: "Non-Current Trade Payable",
      },
    ],
  },
  {
    key: "totalTangibleAssets",
    label: "Total Tangible Assets",
    holder: "Bottomline",
    sublevel: [
      {
        skey: "investmentProperties",
        slabel: "Investment Properties",
      },
      {
        skey: "propertyPlantAndEquipment",
        slabel: "Property Plant And Equipment",
      },
      {
        skey: "rightOfUseAssets",
        slabel: "Right Of UseAssets",
      },
    ],
  },
  {
    key: "totalIntangibleAssets",
    label: "Total Intangible Assets",
    holder: "Bottomline",
    sublevel: [
      {
        skey: "goodwill",
        slabel: "Goodwill",
      },
    ],
  },
  {
    key: "totalLongTermAssetsUnderDevelopment",
    label: "Total Long Term Assets under development",
    holder: "Bottomline",
    sublevel: [
      {
        skey: "capitalWorkInProgress",
        slabel: "Capital Work In Progress",
      },
      {
        skey: "intangibleAssetsUnderDevelopment",
        slabel: "Intangible Assets Under Development",
      },
    ],
  },

  {
    key: "totalWorkingCapital",
    label: "Total Working Capital",
    holder: "Financing",
    sublevel: [
      {
        skey: "totalCurrentAssets",
        slabel: "Total Current Assets",
      },
      {
        skey: "totalCurrentLiabilities",
        slabel: "Total Current Liabilities",
      },
    ],
  },
  {
    key: "totalFinancialAssets",
    label: "Total Financial Assets",
    holder: "Financing",
    sublevel: [
      {
        skey: "bankBalancesOtherThan32",
        slabel: "Bank Balances Other Than 32",
      },
      {
        skey: "cashAndCashEquivalents",
        slabel: "Cash And Cash Equivalents",
      },
      {
        skey: "currInvestments",
        slabel: "Current Investments",
      },
      {
        skey: "currLoans",
        slabel: "Current Loans",
      },
    ],
  },
  {
    key: "totalInterest",
    label: "Total Interest",
    holder: "Financing",
    sublevel: [
      {
        skey: "financeCosts",
        slabel: "Finance Costs",
      },
    ],
  },

  {
    key: "totalTax",
    label: "Total Tax",
    holder: "Financing",
    sublevel: [
      {
        skey: "currentTax",
        slabel: "Current Tax",
      },
      {
        skey: "deferredTax",
        slabel: "Deferred Tax",
      },
    ],
  },
  {
    key: "totalDebt",
    label: "Total Debt",
    holder: "Financing",
    sublevel: [
      {
        skey: "ltBorrowings",
        slabel: "Long Term Borrowings",
      },
      {
        skey: "stBorrowings",
        slabel: "Short Term Borrowings",
      },
    ],
  },

  {
    key: "totalShareholdersEquity",
    label: "Total Shareholders Equity",
    holder: "Financing",
    sublevel: [
      {
        skey: "equityShareCapital",
        slabel: "Equity Share Capital",
      },
      {
        skey: "otherEquity",
        slabel: "Other Equity",
      },
    ],
  },
];
const SegmentRisks = (props) => {
  const { industryId, sectorName, totalClients } = useSelector(
    (state) => state.industryReducer
  );
  const navigation = useNavigate();
  const { setIsTour } = useTourContext();
  const { isAuthenticatedUser } = useSelector((state) => state.userReducer);
  const { data } = useSelector((state) => state.homeReducer.sectorWiseSection);
  const [showCurrentFY, setShowCurrentFY] = useState(false);
  const [ploading, setPloading] = useState(true);
  const [observation_tour, setObservationTour] = useState(false);
  const [advisory_tour, setAdvisoryTour] = useState(false);
  const [selectedSector, setSelectedSector] = useState("");
  const [sectorWiseData, setSectorWiseData] = useState([]);
  const [currentSegmentRiskTab, setCurrentSegmentRiskTab] =
    useState("Financing & Growth");
  // const [benchMarkClient, setBenchMarkClient] = useState(
  //   localStorage.getItem("benchmark") || ""
  // );
  const [currentSegmentMetric, setCurrentSegmentMetric] = useState(
    TYPES_OF_SEGMENT_METRICS[0].label
  );
  const [modelRegistrationPopUp, setModelRegistrationPopUp] = useState(false);

  const [industryPayloadApi, setIndustryPayloadApi] = useState(null);
  const [isObservationContent, setIsObservationContent] = useState(false);
  const [graphData, setGraphData] = useState({
    totalShareholdersEquity: [],
    totalDebt: [],
    totalSales: [],
    totalEbitda: [],
    totalTangibleAssets: [],
    totalIntangibleAssets: [],
    totalFinancialAssets: [],
    totalLongTermAssetsUnderDevelopment: [],
    totalMaterialsCost: [],
    totalInterest: [],
    totalEmployeeCost: [],
    totalTax: [],
    averageInventory: [],
    totalWorkingCapital: [],
    totalTradeReceivables: [],
    totalTradePayables: [],
  });

  // useEffect(() => {
  //   dispatch(getsectorWiseResult());
  // }, [dispatch]);

  useEffect(() => {
    setPloading(true);
    if (selectedSector) {
      setSectorWiseData(
        data?.filter((swr) => swr.sector === selectedSector)[0]
      );
      setPloading(false);
    }
  }, [selectedSector, data]);

  useEffect(() => {
    setPloading(true);
    setSelectedSector(sectorName);
  }, [sectorName]);

  useEffect(() => {
    const yearsWiseData = sectorWiseData?.yearWiseData;
    const years = yearsWiseData && Object.keys(yearsWiseData);
    var industryObservationPayload = {};
    setPloading(true);
    setIsObservationContent(true);
    if (yearsWiseData && years.length > 0) {
      sectorWiseMetricKeys.forEach((key) => {
        const obj = years.map((year) => {
          const d = Object.keys(yearsWiseData[year][key]);

          let total = 0;
          d.forEach((dd) => {
            total += yearsWiseData[year][key][dd];
          });

          return {
            year,
            value: { ...yearsWiseData[year][key] },
            total,
          };
        });
        const objFilter = obj.filter(
          (data) => showCurrentFY || (!showCurrentFY && data.year !== "2024")
        );
        setGraphData((currState) => {
          return {
            ...currState,
            [key]: objFilter,
          };
        });
        let particulars = [];
        let values = {};
        objFilter.forEach((data) => {
          // console.log(data);
          particulars.push(data.year);
          var value = data.value;
          var vkeys = Object.keys(value);
          vkeys.forEach((vkey) => {
            if (!values[vkey]) {
              values[vkey] = [];
            }
            values[vkey].push(value[vkey]);
          });
        });
        industryObservationPayload[key] = {
          particulars,
          ...values,
        };
      });
      setIndustryPayloadApi(industryObservationPayload);
      setIsObservationContent(false);
      setPloading(false);
    }
  }, [showCurrentFY, sectorWiseData]);
  const imageObject = {
    Sales: sales,
    Distribution: distribution,
    HR: hr,
    Production: production,
    Operations: operation,
    Finance: financing,
  };
  return (
    <>
      <Flex id={"segment_risk_container"}>
        <SegmentTabs
          setCurrentSegmentMetric={setCurrentSegmentMetric}
          currentSegmentMetric={currentSegmentMetric}
        />
        <Box
          display={"none"}
          id={"segment_risks_type"}
          className="leftNavOptions"
        >
          {TYPES_OF_SEGMENT_METRICS?.map((data) => (
            <Box
              m={"0px"}
              w={"100%"}
              style={{
                border: "1.3px solid var(--sylvr-text-color-selected)",
                backgroundColor: "#F2F7F7",
                borderRadius: "4px",
                padding: "8px",
                marginBottom: "15px",
              }}
            >
              <Box>
                <Flex
                  style={{
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                    paddingBottom: "5px",
                  }}
                  onClick={() => {
                    setCurrentSegmentMetric(data?.label);
                  }}
                >
                  <Box className="sylvr-title-menu">{data?.label}</Box>
                  <Box className="menu-icon">
                    <img
                      src={
                        currentSegmentMetric === data?.label
                          ? uparrowmenu
                          : downarrowmenu
                      }
                      alt="menu-arrow"
                      className="metric-arrow"
                    />
                  </Box>
                </Flex>
              </Box>
              {currentSegmentMetric === data?.label &&
                data?.value?.map((value) => (
                  <Box
                    m={"0px"}
                    w={"100%"}
                    style={{
                      backgroundColor: "var(--sylvr-text-color-selected)",
                      borderRadius: "4px",
                      marginBottom: "10px",
                    }}
                  >
                    <Flex justifyContent={"flex-start"} alignItems={"center"}>
                      <Box style={{ marginLeft: "9px" }}>
                        <img src={imageObject[value?.label]} />
                      </Box>
                      <Box className="sylvr-subtitle-menu">{value?.label}</Box>
                    </Flex>
                    {value?.value?.map((row, index) => (
                      <>
                        <Box
                          className={
                            index === value?.value?.length - 1
                              ? "sylvr-subtitle-menu-card metric-bottomBorderRadius"
                              : "sylvr-subtitle-menu-card"
                          }
                        >
                          <Box className="sylvr-subtitle-label">
                            {row?.label}
                          </Box>
                        </Box>
                        {index != value?.value?.length - 1 && (
                          <Box
                            style={{
                              // margin: "0px 5px",
                              backgroundColor: "white",
                            }}
                          >
                            <Box
                              style={{
                                border: "1.5px solid #E6E6E6",
                                margin: "0px 5px",
                              }}
                            ></Box>
                          </Box>
                        )}
                      </>
                    ))}
                  </Box>
                ))}
            </Box>
          ))}
          {false &&
            Object.keys(TYPES_OF_SYSTEMATIC_METRICS)?.map?.((key, index) => (
              <Box m={"0px"} w={"100%"}>
                <Text
                  key={key}
                  className={
                    currentSegmentRiskTab ===
                    TYPES_OF_SYSTEMATIC_METRICS?.[key]?.label
                      ? "riskSubTabs selected"
                      : "riskSubTabs"
                  }
                  _hover={{}}
                  onClick={() => {
                    setCurrentSegmentRiskTab(
                      TYPES_OF_SYSTEMATIC_METRICS?.[key]?.label
                    );
                  }}
                  fontSize="var(--sub-nav)"
                >
                  {TYPES_OF_SYSTEMATIC_METRICS?.[key]?.label}====
                </Text>
              </Box>
            ))}
        </Box>
        <Box className={"segment_risks_block"}>
          <Box id="activityLabel">
            {totalClients && <>ACTIVITY OF {totalClients} SME’S</>}
          </Box>
          <Box id={"segment_risks_content"}>
            <Box
              id={"segment_risks_graph"}
              className={props.observe_trends ? "show-zindex" : ""}
            >
              {/* <Flex justifyContent={"space-between"} mb="10px">
                <Tooltip
                label={
                  "Yes: SMEs with pending 2023-24 results are excluded, No: SMEs till 2022-23 data only"
                }
                fontSize="11px"
                hasArrow
                placement="top"
                bg={"#F8F8F8"}
                color={"#8F8F8F"}
              >
                <Box id="activityLabel">
                  <label style={{ margin: "0px 5px" }}>Show 2023-24</label>

                  <Switch
                    className="yearToggle"
                    onChange={() => setShowCurrentFY(!showCurrentFY)}
                  />
                </Box>
              </Tooltip>
              </Flex> */}
              {props.financial_growth && (
                <Box className="financial_growth-tooplip add-segments-picture financial_growth-position">
                  <Flex padding="15px 15px 0px">
                    <Flex w="100%" justifyContent={"space-between"}>
                      <Flex>
                        <Box>
                          <Img src={bulb_on} alt="alert" />
                        </Box>
                        <Box className="add-client">
                          Complete the industry picture
                        </Box>
                      </Flex>
                      <Box
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          props.setFinancialGrowth(false);
                          setIsTour(false);
                        }}
                      >
                        <Img src={cross_icon} alt="cross_icon" />
                      </Box>
                    </Flex>
                  </Flex>
                  <Box className="single-dashboard">
                    By observing, decoding and connecting the dots of various
                    dynamics.
                  </Box>
                  <Box className="next-block">
                    <Box
                      className="next-btn"
                      onClick={() => {
                        props.setFinancialGrowth(false);
                        props.setBenchmarkNow(true);
                        // localStorage.setItem("is_tour", false);
                        // setIsTour(false);
                        navigation(`/agency/practice-dashboard`);
                      }}
                    >
                      Take the tour for Practice Dashboard
                    </Box>
                  </Box>
                </Box>
              )}
              {props.observe_trends && (
                <Box className="trends-tooplip add-segments-picture trends-position">
                  <Flex padding="15px 15px 0px">
                    <Flex w="100%" justifyContent={"space-between"}>
                      <Flex>
                        <Box>
                          <Img src={bulb_on} alt="alert" />
                        </Box>
                        <Box className="add-client">Observe trends</Box>
                      </Flex>
                      <Box
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          props.setObserveTrends(false);
                          setIsTour(false);
                        }}
                      >
                        <Img src={cross_icon} alt="cross_icon" />
                      </Box>
                    </Flex>
                  </Flex>
                  <Box className="single-dashboard">
                    Note the aggregate activity of SMEs across a full business
                    cycle. Dig deeper in next step.
                  </Box>
                  <Box className="trends-bar">
                    <Box className="trends-bar-fill"></Box>
                  </Box>
                  <Box className="next-block">
                    <Box
                      className="next-btn"
                      onClick={() => {
                        props.setObserveTrends(false);
                        setObservationTour(true);
                      }}
                    >
                      Next
                    </Box>
                  </Box>
                </Box>
              )}
              {ploading ? (
                <>
                  <Box h={"200px"}>
                    <Loader />
                  </Box>
                </>
              ) : (
                <>
                  {!isAuthenticatedUser && (
                    <Box id={"segment_activity_unlock"}>
                      <UnlockRegistration
                        setModelRegistrationPopUp={setModelRegistrationPopUp}
                      />
                    </Box>
                  )}
                  <Box
                    className="activity-content-graph"
                    data-isautherised={isAuthenticatedUser ? "true" : "false"}
                  >
                    {sectorWiseData?.yearWiseData &&
                      Object.keys(sectorWiseData?.yearWiseData).length > 0 && (
                        <>
                          <Box
                            p={"0px 0px 5px 0px"}
                            className="activity-content-graph-data"
                          >
                            {currentSegmentMetric === "Topline" && (
                              <TopLine
                                graphData={graphData}
                                isAuthenticatedUser={isAuthenticatedUser}
                              />
                            )}
                            {currentSegmentMetric === "Bottomline" && (
                              <BottomLine
                                graphData={graphData}
                                isAuthenticatedUser={isAuthenticatedUser}
                              />
                            )}
                            {currentSegmentMetric === "Financing" && (
                              <Financing
                                graphData={graphData}
                                isAuthenticatedUser={isAuthenticatedUser}
                              />
                            )}
                            {false &&
                              currentSegmentMetric === "Financing & Growth" && (
                                <FinancingAndGrowth
                                  graphData={graphData}
                                  isAuthenticatedUser={isAuthenticatedUser}
                                />
                              )}

                            {false && currentSegmentMetric === "Assets" && (
                              <Assets
                                graphData={graphData}
                                isAuthenticatedUser={isAuthenticatedUser}
                              />
                            )}

                            {false && currentSegmentMetric === "Cost Heads" && (
                              <CostHeads
                                graphData={graphData}
                                isAuthenticatedUser={isAuthenticatedUser}
                              />
                            )}

                            {false && currentSegmentMetric === "Trade" && (
                              <Trade
                                graphData={graphData}
                                isAuthenticatedUser={isAuthenticatedUser}
                              />
                            )}
                          </Box>
                        </>
                      )}
                  </Box>
                </>
              )}
            </Box>
            <Box id={"segment_risks_observations"} height={"100%"}>
              {observation_tour && (
                <Box className="observations-tooplip add-segments-picture observations-position">
                  <Flex p="15px">
                    <Flex w="100%" justifyContent={"space-between"}>
                      <Flex>
                        <Box>
                          <Img src={bulb_on} alt="alert" />
                        </Box>
                        <Box className="add-client">Read through patterns</Box>
                      </Flex>
                      <Box
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setObservationTour(false);
                          setIsTour(false);
                        }}
                      >
                        <Img src={cross_icon} alt="cross_icon" />
                      </Box>
                    </Flex>
                  </Flex>
                  <Box className="single-dashboard">
                    Decode correlations, causations and contradictions across
                    variables. Connect the dots in next step.
                  </Box>
                  <Box className="trends-bar">
                    <Box className="observations-bar-fill"></Box>
                  </Box>
                  <Box className="next-block">
                    <Box
                      className="next-btn"
                      onClick={() => {
                        setObservationTour(false);
                        setAdvisoryTour(true);
                      }}
                    >
                      Next
                    </Box>
                  </Box>
                </Box>
              )}
              {advisory_tour && (
                <Box className="advisory-tooplip add-segments-picture advisory-position">
                  <Flex p="15px">
                    <Flex w="100%" justifyContent={"space-between"}>
                      <Flex>
                        <Box>
                          <Img src={bulb_on} alt="alert" />
                        </Box>
                        <Box className="add-client">Save & share notes</Box>
                      </Flex>
                      <Box
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setAdvisoryTour(false);
                          setIsTour(false);
                        }}
                      >
                        <Img src={cross_icon} alt="cross_icon" />
                      </Box>
                    </Flex>
                  </Flex>
                  <Box className="single-dashboard">Grow trust today</Box>
                  <Box className="trends-bar">
                    <Box className="advisory-bar-fill"></Box>
                  </Box>
                  <Box className="next-block">
                    <Box
                      className="next-btn"
                      onClick={() => {
                        setAdvisoryTour(false);
                        props.setBenchmarkNow(true);
                      }}
                    >
                      Next
                    </Box>
                  </Box>
                </Box>
              )}
              <Box
                className={
                  observation_tour
                    ? "observations-block show-zindex"
                    : "observations-block"
                }
                // data-isautherised={isAuthenticatedUser ? "true" : "false"}
              >
                {isObservationContent ? (
                  <>
                    <Box h={"200px"}>
                      <Loader />
                    </Box>
                  </>
                ) : (
                  <>
                    {!isAuthenticatedUser && (
                      <Box id={"observations_unlock"}>
                        <UnlockRegistration
                          setModelRegistrationPopUp={setModelRegistrationPopUp}
                        />
                      </Box>
                    )}
                    <Box
                      id={"segment_risks_observations_content"}
                      data-isautherised={isAuthenticatedUser ? "true" : "false"}
                    >
                      <IndustryObservations
                        currentSegmentRiskTab={currentSegmentMetric}
                        industryPayload={industryPayloadApi}
                        industrySeqLoad={segmentMetricKeysMapper}
                        isblur={isAuthenticatedUser ? false : true}
                      />
                    </Box>
                  </>
                )}
              </Box>
              <Box
                className={
                  observation_tour
                    ? "advisory-note-block show-zindex"
                    : "advisory-note-block"
                }
              >
                {isObservationContent ? (
                  <>
                    <Box h={"200px"}>
                      <Loader />
                    </Box>
                  </>
                ) : (
                  <>
                    {!isAuthenticatedUser && (
                      <Box id={"observations_unlock"}>
                        <UnlockRegistration
                          setModelRegistrationPopUp={setModelRegistrationPopUp}
                        />
                      </Box>
                    )}
                    <Box
                      id={"segment_risks_advisory_content"}
                      data-isautherised={isAuthenticatedUser ? "true" : "false"}
                      style={{ width: "100%" }}
                    >
                      <AdvisoryNotes industryId={industryId} />
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
      <RegistrationPopUp
        setModelRegistrationPopUp={setModelRegistrationPopUp}
        modelRegistrationPopUp={modelRegistrationPopUp}
      />
    </>
  );
};

export default SegmentRisks;
