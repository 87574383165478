import {
  Box,
  Flex,
  Image,
  Input,
  Tab,
  Table,
  TableContainer,
  TabList,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { userTransactionHistoryApi } from "../../../api/userApi";
import "./styles/UserProfile.scss";
import fake_company_logo from "../../../assets/images/fake-company-logo.png"
import camera from "../../../assets/images/camera.png";
import fake_profile_pic from "../../../assets/images/fake-profile-pic.png";

const UserProfile = () => {
  const [userTransactionHistory, setUserTransactionHistory] = useState([]);
  const [currentTab, setCurrentTab] = useState("viewprofile");
  const [userRole, setUserRole] = useState("client");
  const toggleProfileTab = (tabValue) => {
    setCurrentTab(tabValue);
  };
  const ProfileTabs = ({ toggleProfileTab }) => {
    return (
      <Tabs
        variant="enclosed"
        colorScheme="teal"
        defaultIndex={2}
        className="main-agency-tab"
      >
        <TabList>
          <Tab mr={5} onClick={() => toggleProfileTab("viewprofile")}>
            View Profile
          </Tab>
          <Tab onClick={() => toggleProfileTab("transactionhistory")}>
            Transaction History
          </Tab>
        </TabList>
      </Tabs>
    );
  };
  const ProfileView = () => {
    const { user } = useSelector((state) => state.userReducer);
    return (
      <>
        <Flex>
          <Box w={"60%"}>
            <Flex>
              <Box className="profile-settings" w={"60%"}>
                <Box className="profile-settings-label">Profile Settings</Box>
                <Box className="profile-settings-content">
                  <Box className="input-box">
                    <label className="label">Name</label>
                    <Box>
                      <Input
                        className="input"
                        placeholder="Enter Name"
                        value={user.name}
                        disabled={user.name}
                      />
                    </Box>
                  </Box>
                  <Box className="input-box">
                    <label className="label">Designation</label>
                    <Box>
                      <Input
                        className="input"
                        placeholder="Enter Designation"
                        disabled={user.name}
                      />
                    </Box>
                  </Box>
                  {user.role === "agency" ? (
                    <Box className="input-box">
                      <label className="label">MRN</label>
                      <Box>
                        <Input
                          className="input"
                          placeholder="Enter MRN"
                          disabled={user.name}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box className="input-box">
                      <label className="label">CIN</label>
                      <Box>
                        <Input
                          className="input"
                          placeholder="Enter CIN"
                          disabled={user.name}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <Box padding={"30px 20px"}>
                  <Image src={fake_profile_pic} alrt="Company Logo" />
                </Box>
              </Box>
            </Flex>

            <Box w={"60%"}>
              <Box className="profile-settings">
                <Box className="profile-settings-label">Account Settings</Box>
                <Box className="profile-settings-content">
                  <Box className="input-box">
                    <label className="label">Email</label>
                    <Box>
                      <Input
                        className="input"
                        placeholder="Enter Email"
                        value={user.email}
                        disabled={user.name}
                      />
                    </Box>
                  </Box>
                  <Box className="input-box">
                    <label className="label">Password</label>
                    <Box>
                      <Input className="input" placeholder="Enter Password" />
                    </Box>
                  </Box>
                  <Box className="input-box">
                    <label className="label">Confirm Password</label>
                    <Box>
                      <Input
                        className="input"
                        placeholder="Enter Confirm Password"
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box>
            <Box className="profile-company">
              <Box className="profile-company-label"></Box>
              <Box className="profile-company-content">
                {user.role === "agency" ? (
                  <Box className="input-box">
                    <label className="label">Company Name</label>
                    <Box>
                      <Input
                        className="input"
                        placeholder="Enter Name"
                        disabled={user.name}
                      />
                    </Box>
                  </Box>
                ) : (
                  <Box className="input-box">
                    <label className="label">Practice Name</label>
                    <Box>
                      <Input className="input" placeholder="Enter Name" />
                    </Box>
                  </Box>
                )}
                <Flex className="input-box">
                  <Box className="input-box">Upload practice/Company logo</Box>
                  <Box className="input-box">
                    <Image src={fake_company_logo} alrt="Company Logo" />
                  </Box>
                </Flex>
                <Box className="input-box">
                  Referrals (other CA) & invitations (partner & SME)
                </Box>
              </Box>
            </Box>
          </Box>
        </Flex>
      </>
    );
  };
  const callTransactionHistory = async () => {
    try {
      const data = await userTransactionHistoryApi();
      setUserTransactionHistory(data);
    } catch {}
  };
  useEffect(() => {
    callTransactionHistory();
  }, []);

  const TransactionHistory = () => {
    return (
      <>
        <Box padding={"10px 20px"}>Transaction History</Box>
        <Box padding={"10px 20px"}>
          <Flex m={5}>
            <TableContainer overflowY={"auto"} height={"400px"}>
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th color={"white"}>Transaction ID</Th>
                    <Th color={"white"}>Transaction Method</Th>
                    <Th color={"white"} isNumeric>
                      Amount
                    </Th>
                    <Th color={"white"}>Transaction Date</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {userTransactionHistory.length > 0 &&
                    userTransactionHistory.map((txn) => (
                      <Tr>
                        <Td>{txn._id}</Td>
                        <Td>{txn.paymentMethod}</Td>
                        <Td>{txn.amount}</Td>
                        <Td>{txn.createdAt}</Td>
                      </Tr>
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Flex>
        </Box>
      </>
    );
  };
  return (
    <>
      <Box id={"industry_tab"}>
        <ProfileTabs toggleProfileTab={toggleProfileTab} />
      </Box>

      <Box id={"industry_container"}>
        {currentTab === "viewprofile" && <ProfileView />}
        {currentTab === "transactionhistory" && <TransactionHistory />}
      </Box>
    </>
  );
};

export default UserProfile;
