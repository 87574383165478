import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isAuthenticatedUser:
    JSON.parse(localStorage.getItem("isAuthenticatedUser") || "false") || false,
  isFirstLogin: true,
  loading: false,
  error: null,

  // type: agency or organisation
  // type: null,
  type: localStorage.getItem("type") || null,
  user: null,
  organisation: null,
};

const userReducer = createReducer(initialState, {
  USER_LOGIN_REQ: (state) => {
    state.loading = true;
  },
  USER_LOGIN_SUC: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = true;
    state.isFirstLogin = false;
    // state.isFirstLogin = true;
    state.type = actions.payload.role;
    state.user = actions.payload;
  },
  USER_LOGIN_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
    state.isAuthenticatedUser = false;
    state.type = null;
    state.user = null;
  },
  REFRESH_LOGIN_REQ: (state, actions) => {
    state.loading = true;
  },
  REFRESH_LOGIN_SUC: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = true;
    state.isFirstLogin = false;
    // state.isFirstLogin = true;
    state.type = actions.payload.role;
    state.user = actions.payload;
  },
  REFRESH_LOGIN_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
    state.isAuthenticatedUser = false;
    state.type = null;
    state.user = null;
  },
  LOGOUT_USER_REQ: (state) => {
    state.loading = true;
  },
  LOGOUT_USER_SUCCESS: (state) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = false;
    state.isFirstLogin = false;
    state.user = null;
    state.type = null;
    localStorage.clear();
  },
  LOGOUT_USER_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },

  REGISTER_AGENCY_REQ: (state, actions) => {
    state.loading = true;
  },
  REGISTER_AGENCY_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = false;
    state.isFirstLogin = false;
    state.type = "agency";
    state.user = actions.payload;
  },
  REGISTER_AGENCY_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
    state.isAuthenticatedUser = false;
    state.type = null;
    state.user = null;
  },

  LOGIN_AGENCY_REQ: (state, actions) => {
    state.loading = true;
  },
  LOGIN_AGENCY_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = true;
    state.isFirstLogin = false;
    state.type = "agency";
    state.user = actions.payload;
  },
  LOGIN_AGENCY_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
    state.isAuthenticatedUser = false;
    state.type = null;
    state.user = null;
  },

  LOGOUT_AGENCY_REQ: (state, actions) => {
    state.loading = true;
  },
  LOGOUT_AGENCY_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = false;
    state.isFirstLogin = false;
    state.user = null;
    state.type = null;
  },
  LOGOUT_AGENCY_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },

  // Organisation
  REGISTER_ORG_REQ: (state, actions) => {
    state.loading = true;
  },
  REGISTER_ORG_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = false;
    state.isFirstLogin = false;
    state.type = "organisation";
    state.user = actions.payload;
  },
  REGISTER_ORG_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
    state.isAuthenticatedUser = false;
    state.type = null;
    state.user = null;
  },

  LOGIN_ORG_REQ: (state, actions) => {
    state.loading = true;
  },
  LOGIN_ORG_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = true;
    state.isFirstLogin = false;
    state.type = "organisation";
    state.user = actions.payload;
  },
  LOGIN_ORG_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
    state.isAuthenticatedUser = false;
    state.type = null;
    state.user = null;
  },

  LOGOUT_ORG_REQ: (state, actions) => {
    state.loading = true;
  },
  LOGOUT_ORG_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = false;
    state.isFirstLogin = false;
    state.user = null;
    state.type = null;
  },
  LOGOUT_ORG_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },

  FORGOT_PSWD_REQ: (state, actions) => {
    state.loading = true;
  },
  FORGOT_PSWD_SUCCESS: (state, actions) => {
    state.loading = false;
  },
  FORGOT_PSWD_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },
  RESET_PSWD_REQ: (state, actions) => {
    state.loading = true;
  },
  RESET_PSWD_ORG_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = true;
    state.type = "organisation";
    state.user = actions.payload;
  },
  RESET_PSWD_AGENCY_SUCCESS: (state, actions) => {
    state.loading = false;
    state.error = null;
    state.isAuthenticatedUser = true;
    state.type = "agency";
    state.user = actions.payload;
  },
  RESET_PSWD_FAIL: (state, actions) => {
    state.loading = false;
    state.error = actions.payload;
  },
});

export default userReducer;
