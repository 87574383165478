import React from "react";
import { useSelector } from "react-redux";
import Loader from "../../components/Loader/Loader";
import { Flex } from "@chakra-ui/react";
import PerformanceMatrix from "../../components/PerformanceMatrix/PerformanceMatrix";

export const PerformanceMatrixComponent = ({
  setCurrentType,
  setPatternData,
  currentMetric,
  metricColors,
  setMetricColors,
  setCurrentMetric,
}) => {
  
  const { client } = useSelector((state) => state.clientReducer);
  const { details, zScore, trend } = client;
  
  return (
    <>
      {details?.loading || zScore?.loading || trend?.loading ? (
        <Loader />
      ) : (
        <>
          <Flex justifyContent={"space-around"} alignItems={"center"}>
            <PerformanceMatrix
              setCurrentType={setCurrentType}
              currentMetric={currentMetric}
              setPatternData={setPatternData}
              metricColors={metricColors}
              setMetricColors={setMetricColors}
              setCurrentMetric={setCurrentMetric}
            />
          </Flex>
        </>
      )}
    </>
  );
};
