import React from "react";
import AreaChart from "./AreaChart";
import { calculateUnitsForValues } from "../../../utils/helperFunction";

const ShareholdersEquity = ({ data, xAxisLabel, yAxisLabel }) => {
  if (!xAxisLabel) {
    xAxisLabel = "Period";
  }

  const valuesWithUnits = calculateUnitsForValues(data);
  yAxisLabel = `Total Shareholders Equity [units in ${valuesWithUnits}]`;
  return (
    <>
      <AreaChart
        data={data}
        heading="Total Shareholders Equity"
        xAxisLabel={xAxisLabel}
        yAxisLabel={yAxisLabel}
      />
    </>
  );
};

export default ShareholdersEquity;
