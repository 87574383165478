import { Box, Heading, Stack } from "@chakra-ui/react";
import ReactApexChart from "react-apexcharts";

function TotalWorkingCapital({ data, dimension }) {
  data = data.map((val) => ({
    ...val,
    value: {
      ...val?.value,
      totalWorkingCapital:
        val?.value?.totalCurrentAssets - val?.value?.totalCurrentLiabilities,
    },
  }));
  data = data.map((val) => ({
    ...val,
    value: {
      ...val?.value,
      totalCurrentLiabilities: -val?.value?.totalCurrentLiabilities,
    },
  }));

  const series = [];

  const keys = Object.keys(data?.[0]?.value || {});
  const years = data.map((item) => item?.year);

  keys?.forEach?.((key) => {
    const newData = data.map((item) => item?.value?.[key]);
    let chartType = "";
    if (["totalCurrentAssets", "totalCurrentLiabilities"].includes(key)) {
      chartType = "area";
    } else if (key === "totalWorkingCapital") {
      chartType = "line";
    }
    series.push({
      name: key,
      data: newData,
      type: chartType,
    });
  });

  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: false,
      },
      background: "#fff",
    },
    legend: {
      position: "top", // You can also use 'bottom', 'left', or 'right'
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "solid",
      opacity: [0.35, 1],
    },
    labels: years,
    /* [ */
    yaxis: {
      title: {
        text: "Series A",
      },
      labels: {
        formatter: function (value) {
          return (value / 10000000).toFixed(1) + " Cr";
        },
      },
    },
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (value) {
          return (value / 10000000).toFixed(1) + " Cr";
        },
      },
    },
  };

  return (
    <Stack w="100%" h="100%">
      <Heading
        fontWeight={600}
        fontSize={16}
        mb="2"
        p="5px 10px 8px"
        display="inline-block"
        textColor="#0C543C"
        borderRadius={4}
      >
        Total Working Capital
      </Heading>
      <Box borderColor={"#DBE8E8"} borderRadius={4} borderWidth={2}>
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={dimension?.height || 380}
          width={dimension?.width || "100%"}
        />
      </Box>
    </Stack>
  );
}

export default TotalWorkingCapital;
