import React from "react";
import {
  Area,
  AreaChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomAreaChart = ({
  data,
  xKey,
  yKey,
  color,
  tickFormatter,
  width,
  height,
  xAxisLabel,
  yAxisLabel,
}) => {
  const colors = ["#C3C0ED", "#C0FFB2", "#ADECFF"];

  const keys = data.map((d) => Object.keys(d.value))[0];

  data = data.map((d, i) => ({
    ...d.value,
    year: d.year,
  }));

  const yDomain = [0, 5000];
  return (
    <ResponsiveContainer
      width={width || 500}
      className="chart-container"
      height={height || 300}
    >
      <AreaChart
        data={data}
        width="100%"
        height="100%"
        margin={{ top: 20, right: 20, bottom: 10, left: 60 }}
      >
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor={color} stopOpacity={0.8} />
            <stop offset="95%" stopColor={color} stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey={xKey}
          label={{ value: xAxisLabel, position: "insideBottom", dy: 10 }}
        />
        <YAxis
          tickFormatter={tickFormatter}
          domain={[0, "dataMax"]}
          allowDataOverflow={false}
          label={{ value: yAxisLabel, angle: -90, position: "Start", dx: -45 }}
        />
        <Tooltip itemStyle={{ color: "#000" }} />
        {/* <Area type="monotone" dataKey={yKey} fill="url(#colorUv)" /> */}
        {keys?.map?.((k, i) => (
          <Area
            key={i}
            type="monotone"
            stackId="1"
            dataKey={k}
            fill={colors[i % colors.length]}
            stroke={colors[i % colors.length]}
          />
        ))}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default CustomAreaChart;
