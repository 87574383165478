import { Heading, Stack } from "@chakra-ui/react";
import React from "react";
import CustomAreaChart from "../../../components/AreaChart/CustomAreaChart";
import { formatCurrencyValue } from "../../../utils/helperFunction";

const AreaChart = ({ data, heading, xAxisLabel, yAxisLabel }) => {
  return (
    <>
      <Stack className="home-page-card" alignItems="flex-start">
        <Heading
          size={"md"}
          color={"primary.500"}
          bg="#fff"
          mb="2"
          p="5px 10px 8px"
          display="inline-block"
          textColor="#1E1E1E"
          borderRadius={4}
        >
          {heading}
        </Heading>
        <CustomAreaChart
          data={data}
          xKey="year"
          yKey="value"
          color="#8884d8"
          tickFormatter={formatCurrencyValue}
          xAxisLabel={xAxisLabel}
          yAxisLabel={yAxisLabel}
        ></CustomAreaChart>
      </Stack>
    </>
  );
};

export default AreaChart;
