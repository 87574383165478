import { Box, Stack } from "@chakra-ui/react";
import StackedAreaChart from "../StackedAreaChart";

export default function SystematicFactorsStackedAreaChart({
  data,
  heading,
  dimension,
}) {
  return (
    <Stack className="sylvr-graph-card">
      <Box className="sylvr-graph-card-heading">
        <Box className="sylvr-graph-card-heading-label">{heading}</Box>
      </Box>
      <Box>
        <StackedAreaChart data={data} dimension={dimension} />
      </Box>
    </Stack>
  );
}
